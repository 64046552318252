import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { //English
        translations: {
          'Add New Agent':'Add New Agent',
          'Commission List':'Commission List',
          'Enter your credentials to access your account.':'Enter your credentials to access your account.',
          'username':'username',
          'password':'password',
          'Login':'Login',
          'Not yet registered?':'Not yet registered?',
          'Create an Account':'Create an Account',
          'Earn 3% of commission in every bets!':'Earn 3% of commission in every bets!',
          'REGISTER NOW':'REGISTER NOW',
          'BECOME A':'BECOME A',
          'SUPER MASTER AGENT':'SUPER MASTER AGENT',
          // 'English':'English',
          // 'Indonesian':'Indonesian',
          // 'Korean':'Korean',
          // 'Malaysian':'Malaysian',
          // 'Vietnamese':'Vietnamese',
          'The username field is required.':'The username field is required.',
          'The password field is required.':'The password field is required.',
          'Sorry unable to serve your request. Please contact administrator.':'Sorry unable to serve your request. Please contact administrator.',
          'User Name*':'User Name*',
          'Username*':'Username*',
          'Password*':'Password*',
          'First Name*':'First Name*',
          'Firstname*':'Firstname*',
          'Last Name*':'Last Name*',
          'Lastname*':'Lastname*',
          'Currency*':'Currency*',
          'Email*':'Email*',
          'Mobile*':'Mobile*',
          'Telegram':'Telegram',
          'Skype':'Skype',
          'Whatsapp':'Whatsapp',
          'Register':'Register',
          'Sabong Network recreational hobby':'Sabong Network recreational hobby',
          'Sign Up':'Sign Up',
          'Create your account and become a master agent now.':'Create your account and become a master agent now.',
          // 'English':'English',
          'Already have an account?':'Already have an account?',
          'Registered successfully! We will contact you for your account activation.':'Registered successfully! We will contact you for your account activation.',
          'Welcome':'Welcome',
          'Wallet Points':'Wallet Points',
          'Commission':'Commission',
          'Commissions':'Commissions',
          'Pending Deposits':'Pending Deposits',
          'Pending Withdrawals':'Pending Withdrawals',
          'Active Players':'Active Players',
          'Pending Players':'Pending Players',
          'Blocked Players':'Blocked Players',
          'Active Agent':'Active Agent',
          'Pending Agent':'Pending Agent',
          'Blocked Agent':'Blocked Agent',
          'Profile':'Profile',
          'Change Password':'Change Password',
          'Logout':'Logout',
          'Dashboard':'Dashboard',
          'Players':'Players',
          'Manage':'Manage',
          'Deposits':'Deposits',
          'Deposit':'Deposit',
          'Withdrawals':'Withdrawals',
          'Agent':'Agent',
          'Tree View':'Tree View',
          'Cap View':'Cap View',
          'Funds Request':'Funds Request',
          'Funds for Approval':'Funds for Approval',
          'Ledgers':'Ledgers',
          'Commission Points':'Commission Points',
          'Payment Channels':'Payment Channels',
          'Commission List':'Commission List',
          'Filter':'Filter',
          'Clear':'Clear',
          'Status':'Status',
          'Period':'Period',
          'Agent Code':'Agent Code',
          'Amount':'Amount',
          'Approved Amount':'Approved Amount',
          'Start Date':'Start Date',
          'End Date':'End Date',
          'Showing':'Showing',
          'Limit':'Limit',
          'export':'export',
          'Pending':'Pending',
          'Approved':'Approved',
          'Adjusted':'Adjusted',
          'Game URL':'Game URL',
          'Open this link':'Open this link',
          'Player Username':'Player Username',
          'Player Number':'Player Number',
          'Player No.':'Player No.',
          'Created Date':'Created Date',
          'Action':'Action',
          'Players List':'Players List',
          'Players List':'Players List',
          'Players Deposit':'Players Deposit',
          'Player Deposits':'Player Deposits',
          'Player Deposit':'Player Deposit',
          'Disapproved':'Disapproved',
          'Transaction No.':'Transaction No.',
          'Players Withdrawals':'Players Withdrawals',
          'Player Withdrawals':'Player Withdrawals',
          'Active':'Active',
          'Inactive':'Inactive',
          'Hold':'Hold',
          'Blocked':'Blocked',
          'Credit Limit':'Credit Limit',
          'Commission Rate':'Commission Rate',
          'Agent Type':'Agent Type',
          'Parent Agent Code':'Parent Agent Code',
          'Date Registered':'Date Registered',
          'Add New Agent':'Add New Agent',
          'Super Agent':'Super Agent',
          'Request Fund Transfer':'Request Fund Transfer',
          'Fund Request List':'Fund Request List',
          'Payee Code':'Payee Code',
          'Payor Code':'Payor Code',
          'Type':'Type',
          'Withdraw':'Withdraw',
          'Payor Type':'Payor Type',
          'Fund Approval List':'Fund Approval List',
          'Wallet Ledger':'Wallet Ledger',
          'Memo Type':'Memo Type',
          'Debit':'Debit',
          'Credit':'Credit',
          'New Balance':'New Balance',
          'Prev. Balance':'Prev. Balance',
          'Player Withdraw':'Player Withdraw',
          'Player Withdrawal':'Player Withdrawal',
          'Player Credit Memo':'Player Credit Memo',
          'Player Debit Memo':'Player Debit Memo',
          'Agent Deposit':'Agent Deposit',
          'Agent Withdraw':'Agent Withdraw',
          'Agent Withdrawal':'Agent Withdrawal',
          'Agent Credit Memo':'Agent Credit Memo',
          'Agent Debit Memo':'Agent Debit Memo',
          'Admin Credit Memo':'Admin Credit Memo',
          'Admin Debit Memo':'Admin Debit Memo',
          'Commission Income':'Commission Income',
          'Commission Approval':'Commission Approval',
          'Credit Memo':'Credit Memo',
          'Debit Memo':'Debit Memo',
          'Commission Ledger List':'Commission Ledger List',
          'Address 1':'Address 1',
          'Address 2':'Address 2',
          'Credit Limit':'Credit Limit',
          'Commission Rate':'Commission Rate',
          'Cancel':'Cancel',
          'Save':'Save',
          'Remarks':'Remarks',
          'Add Payment Channel':'Add Payment Channel',
          'Bank Name or Payment Channel Name (e.g HSBC WeChat Pay etc)':'Bank Name or Payment Channel Name (e.g HSBC WeChat Pay etc)',
          'Account Name':'Account Name',
          'Account Number':'Account Number',
          'Channel Name':'Channel Name',
          'Date Created':'Date Created',
          'Welcome Back':'Welcome Back',
          'System Information' : 'System Information',
          'Register Now': 'Register Now',
          'Pending Withdraws': 'Pending Withdraws',
          'Please wait...': 'Please wait...',
          'Approved': 'Approved',
          'APPROVED': 'APPROVED',
          'Account Information':'Account Information',
          'Middle Name':'Middle Name',
          'Email':'Email',
          'Currency':'Currency',
          'Account Expired':'Account Expired',
          'Credential Expired':'Credential Expired',
          'Enabled':'Enabled',
          'Account Locked':'Account Locked',
          'First Name':'First Name',
          'Last Name':'Last Name',
          'Mobile':'Mobile',
          'Created By':'Created By',
          'Modified By':'Modified By',
          'Modified Date':'Modified Date',
          'Lock':'Lock',
          'Agent':'Agent',
          'Code':'Code',
          'Mobile':'Mobile',
          'First Name':'First Name',
          'Last Name':'Last Name',
          'Deactivate':'Deactivate',
          'Activate':'Activate',
          'Inactive':'Inactive',
          'Active':'Active',
          'Commission Ledger':'Commission Ledger',
          'Downlines':'Downlines',
          'Back to List':'Back to List',
          'Reset Password':'Reset Password',
          'Edit':'Edit',
          'Username':'Username',
          'Reset':'Reset',
          'Cancel':'Cancel',
          'Submit':'Submit',
          'Payee Type':'Payee Type',
          'Trx Type.':'Trx Type.',
          'Trx No.':'Trx No.',
          'Created':'Created',
          'Verified CS':'Verified CS',
          'Locked':'Locked',
          'Verifiedcs':'Verifiedcs',
          'New Password':'New Password',
          'out of':'out of',
          'to':'to',
          'Change Paswword':'Change Paswword',
          'Logout':'Logout',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'Use this facility when your agent had forgotten his / her password. Please save the generated password.',
          'Delete Record':'Delete Record',
          'Are you sure you want to delete this record?':'Are you sure you want to delete this record?',
          'Yes':'Yes',
          'No':'No',
          'Select Country Code':'Select Country Code',
          'this Agent?':'this Agent?',
          'Confirm':'Confirm',
          'Are you sure you want to':'Are you sure you want to',
          'Approve':'Approve',
          'this Commission? This action cannot be undone.':'this Commission? This action cannot be undone.',
          'this Player? This action cannot be undone.':'this Player? This action cannot be undone.',
          'Verify':'Verify',
          'this deposit? This action cannot be undone.':'this deposit? This action cannot be undone.',
          'this withdrawal? This action cannot be undone.':'this withdrawal? This action cannot be undone.',
          'Network':'Network',
          // 'English':'English',
          // 'Korean':'Korean',
          // 'Indonesian':'Indonesian',
          // 'Malaysian':'Malaysian',
          // 'Vietnamese':'Vietnamese',
          'Fill in the form below':'Fill in the form below',
          'Convert': 'Convert',
          'your commission? Once you submit this transaction it cannot be undone.' : 'your commission? Once you submit this transaction it cannot be undone.',
          'Convert Commission' : 'Convert Commission',
          'Agent Information':'Agent Information',
          'Player Deposit Information':'Player Deposit Information',
          'Fund Transaction Information':'Fund Transaction Information',
          'Transaction Number':'Transaction Number',
          'Transaction Date':'Transaction Date',
          'Transaction Type':'Transaction Type',
          'Transaction Amount':'Transaction Amount',
          'Reference Transaction Number':'Reference Transaction Number',
          'Name':'Name',
          'Number':'Number',
          'Agent':'Agent',
          'Currency':'Currency',
          'New Wallet Balance':'New Wallet Balance',
          'Previous balance':'Previous balance',
          'Memo Type':'Memo Type',
          'Remarks':'Remarks',
          'Account Name':'Account Name',
          'Channel Name':'Channel Name',
          'Account Number':'Account Number',
          'Date Created':'Date Created',
          'Add New Payment Channel':'Add New Payment Channel',
          'Commission Ledger List':'Commission Ledger List',
          'Previous Balance':'Previous Balance',
          'Delete':'Delete',
          'Bank Name or Payment Channel Name':'Bank Name or Payment Channel Name',
          'Memo':'Memo',
          'Txn No.':'Txn No.',
          'Txn Type':'Txn Type',
          'Txn Date':'Txn Date',
          'Credit':'Credit',
          'CREDIT':'CREDIT',
          'DEBIT':'DEBIT',
          'Admin':'Admin',
          'PENDING':'PENDING',
          'DEPOSIT':'DEPOSIT',
          'ADMIN':'ADMIN',
          'Note':'Note',
          'Fund type':'Fund type',
          'Fund Type':'Fund Type',
          'Fund Transaction':'Fund Transaction',
          'ACTIVE':'ACTIVE',
          'INACTIVE':'INACTIVE',
          'Minimum Commission Conversion':'Minimum Commission Conversion',
          'Payment Channel Account Name':'Payment Channel Account Name',
          'Player Information':'Player Information',
          'Edit Agent':'Previous Balance',
          'Shareable Link':'Shareable Link',
          'Super Agen':'Bank Name or Payment Channel Name',
          'Wallet Point Ledger Information':'Wallet Point Ledger Information',
          'Commission Point Ledger Information':'Wallet Point Ledger Information',
          'Account Expired':'Account Expired',
          'Account Information':'Account Information',
          'Account is Inactive, please contact the administrator at':'Account is Inactive, please contact the administrator at',
          'Account is locked due to multiple invalid attempts, please contact the administrator at':'Account is locked due to multiple invalid attempts, please contact the administrator at',
          'Account is locked, please contact the administrator at':'Account is locked, please contact the administrator at',
          'Account Locked':'Account Locked',
          'Account Name':'Account Name',
          'Account Non Expired':'Account Non Expired',
          'Account Non Locked':'Account Non Locked',
          'Account Number':'Account Number',
          'Action':'Action',
          'Activate':'Activate',
          'ACTIVE':'ACTIVE',
          'Active Agent':'Active Agent',
          'Active Players':'Active Players',
          'Add Agent':'Add Agent',
          'Add New Agent':'Add New Agent',
          'Add New Operator':'Add New Operator',
          'Add New Payment Channel':'Add New Payment Channel',
          'Add New User':'Add New User',
          'Add Payment Channel':'Add Payment Channel',
          'Address':'Address',
          'Address 1':'Address 1',
          'Address 2':'Address 2',
          'Adjusted':'Adjusted',
          'Admin':'Admin',
          'Admin Credit Memo':'Admin Credit Memo',
          'Admin Debit Memo':'Admin Debit Memo',
          'ADMIN_CM':'ADMIN_CM',
          'ADMIN_DM':'ADMIN_DM',
          'Agent':'Agent',
          'Agent Add':'Agent Add',
          'Agent addition success!':'Agent addition success!',
          'Agent Code':'Agent Code',
          'Agent Code already taken':'Agent Code already taken',
          'Agent Comm. Rate':'Agent Comm. Rate',
          'Agent Credit Memo':'Agent Credit Memo',
          'Agent Debit Memo':'Agent Debit Memo',
          'Agent Deposit':'Agent Deposit',
          'Agent edit success!':'Agent edit success!',
          'Agent Information':'Agent Information',
          'Agent List':'Agent List',
          'Agent Name':'Agent Name',
          'Agent Type':'Agent Type',
          'Agent View':'Agent View',
          'Agent Withdraw':'Agent Withdraw',
          'AGENT_CM':'AGENT_CM',
          'AGENT_DEPOSIT':'AGENT_DEPOSIT',
          'AGENT_DM':'AGENT_DM',
          'AGENT_WITHDRAW':'AGENT_WITHDRAW',
          'Agents Add':'Agents Add',
          'Agents Edit':'Agents Edit',
          'Agents List':'Agents List',
          'Agents Tree':'Agents Tree',
          'Agents View':'Agents View',
          'Already have an account?':'Already have an account?',
          'Amount':'Amount',
          'API Key':'API Key',
          'Application Error':'Application Error',
          'Approve':'Approve',
          'Approved':'Approved',
          'Approved Amount':'Approved Amount',
          'Approved Commission Amount':'Approved Commission Amount',
          'Are you sure you want to':'Are you sure you want to',
          'Are you sure you want to <b>Approve</b> this Commission? This action cannot be undone.':'Are you sure you want to <b>Approve</b> this Commission? This action cannot be undone.',
          'Are you sure you want to delete this record?':'Are you sure you want to delete this record?',
          'Back to List':'Back to List',
          'Bank Name or Payment Channel Name':'Bank Name or Payment Channel Name',
          'Base API Url':'Base API Url',
          'BECOME A':'BECOME A',
          'Bet Amount':'Bet Amount',
          'Bet Corner':'Bet Corner',
          'Bet Result':'Bet Result',
          'BLOCKED':'BLOCKED',
          'Blocked Agent':'Blocked Agent',
          'Blocked Players':'Blocked Players',
          'Cancel':'Cancel',
          'Cap View':'Cap View',
          'Change Password':'Change Password',
          'Change password success! Use your new password on your next login':'Change password success! Use your new password on your next login',
          'Channel Name':'Channel Name',
          'Channel Name (eg. GCash, Paymaya, ect)':'Channel Name (eg. GCash, Paymaya, ect)',
          'Clear':'Clear',
          'Client Key':'Client Key',
          'CNY':'CNY',
          'Code':'Code',
          'Commission':'Commission',
          'Commission Approval':'Commission Approval',
          'Commission Income':'Commission Income',
          'Commission Information':'Commission Information',
          'Commission Ledger':'Commission Ledger',
          'Commission Ledger List':'Commission Ledger List',
          'Commission Ledger View':'Commission Ledger View',
          'Commission List':'Commission List',
          'Commission Point Ledger Information':'Commission Point Ledger Information',
          'Commission Points':'Commission Points',
          'Commission Rate':'Commission Rate',
          'Commission Rate must not be zero':'Commission Rate must not be zero',
          'Commission Rate Setup':'Commission Rate Setup',
          'Commission Rate Setup edit success!':'Commission Rate Setup edit success!',
          'Commission Rate View':'Commission Rate View',
          'Commission Rates':'Commission Rates',
          'Commission View':'Commission View',
          'COMMISSION_APPROVAL':'COMMISSION_APPROVAL',
          'COMMISSION_INCOME':'COMMISSION_INCOME',
          'Commissions':'Commissions',
          'Commissions List':'Commissions List',
          'Commissions View':'Commissions View',
          'Confirm':'Confirm',
          'Convert':'Convert',
          'Convert Commission':'Convert Commission',
          'Convert commission success!':'Convert commission success!',
          'Create Agent':'Create Agent',
          'Create an Account':'Create an Account',
          'Create your account and become a master agent now.':'Create your account and become a master agent now.',
          'Created':'Created',
          'Created By':'Created By',
          'Created Date':'Created Date',
          'Credential Expired':'Credential Expired',
          'Credential Non Expired':'Credential Non Expired',
          'Credit':'Credit',
          'Credit Limit must not be zero':'Credit Limit must not be zero',
          'Credit Memo':'Credit Memo',
          'Credit Memo List':'Credit Memo List',
          'CREDIT_MEMO':'CREDIT_MEMO',
          'Currency':'Currency',
          'Currency*':'Currency*',
          'Dashboard':'Dashboard',
          'Date':'Date',
          'Date Created':'Date Created',
          'Date Modified':'Date Modified',
          'Date Registered':'Date Registered',
          'Deactivate':'Deactivate',
          'Debit':'Debit',
          'Debit Memo':'Debit Memo',
          'Debit Memo List':'Debit Memo List',
          'DEBIT_MEMO':'DEBIT_MEMO',
          'Delete':'Delete',
          'Deposit':'Deposit',
          'Deposits':'Deposits',
          'Disapprove':'Disapprove',
          'Disapproved':'Disapproved',
          'Downlines':'Downlines',
          'download':'download',
          'e.g HSBC, WeChat Pay, etc':'e.g HSBC, WeChat Pay, etc',
          'Earn 3% of commission in every bets!':'Earn 3% of commission in every bets!',
          'Edit':'Edit',
          'Edit Agent':'Edit Agent',
          'Edit Payment Channel':'Edit Payment Channel',
          'Email':'Email',
          'Email already taken':'Email already taken',
          'Email cannot be empty':'Email cannot be empty',
          'Email is invalid':'Email is invalid',
          'Email not found':'Email not found',
          'Email*':'Email*',
          'Enabled':'Enabled',
          'End Date':'End Date',
          // 'English':'English',
          'Enter your credentials to access your account.':'Enter your credentials to access your account.',
          'Expired':'Expired',
          'export':'export',
          'Fill in the form below':'Fill in the form below',
          'Filter':'Filter',
          'First Name':'First Name',
          'First Name*':'First Name*',
          'Fund Approval List':'Fund Approval List',
          'Fund In':'Fund In',
          'Fund List Approval':'Fund List Approval',
          'Fund List Request':'Fund List Request',
          'Fund Request List':'Fund Request List',
          'Fund Transaction':'Fund Transaction',
          'Fund Transaction has been sent! please wait for the approval.':'Fund Transaction has been sent! please wait for the approval.',
          'Fund Transaction Information':'Fund Transaction Information',
          'Fund type':'Fund type',
          'Fund View Approval':'Fund View Approval',
          'Fund View Request':'Fund View Request',
          'Funds For Approval':'Funds For Approval',
          'Funds List':'Funds List',
          'Funds Request':'Funds Request',
          'Funds View':'Funds View',
          'Game URL':'Game URL',
          'Go Back':'Go Back',
          'HOLD':'HOLD',
          'Home':'Home',
          'IDR':'IDR',
          'INACTIVE':'INACTIVE',
          'Indonesian':'Indonesian',
          'Insufficient Balance':'Insufficient Balance',
          'Insufficient Wallet Commission Balance':'Insufficient Wallet Commission Balance',
          'Insufficient withdrawal amount':'Insufficient withdrawal amount',
          'Invalid Amount':'Invalid Amount',
          'Invalid credential':'Invalid credential',
          'Invalid credential on fourth attempt, account will be locked on 5th try':'Invalid credential on fourth attempt, account will be locked on 5th try',
          'Invalid credential on third attempt, account will be locked on 5th try':'Invalid credential on third attempt, account will be locked on 5th try',
          'Invalid credential, please recheck your inputs':'Invalid credential, please recheck your inputs',
          'Invalid credentials, Your account has been locked, please contact the administrator at':'Invalid credentials, Your account has been locked, please contact the administrator at',
          'Invalid OTP':'Invalid OTP',
          'Invalid OTP, please check your input or you may regenerate again':'Invalid OTP, please check your input or you may regenerate again',
          'Invalid Username / Password':'Invalid Username / Password',
          'Invalid username or password, please check your input':'Invalid username or password, please check your input',
          'Korean':'Korean',
          'KRW':'KRW',
          'Last Name':'Last Name',
          'Last Name*':'Last Name*',
          'Ledgers':'Ledgers',
          'Limit':'Limit',
          'Lock':'Lock',
          'Locked':'Locked',
          'Login':'Login',
          'Malaysian':'Malaysian',
          'Manage':'Manage',
          'Match Code':'Match Code',
          'Memo':'Memo',
          'Memo Type':'Memo Type',
          'Middle Name':'Middle Name',
          'Minimum Commission Conversion':'Minimum Commission Conversion',
          'Mobile':'Mobile',
          'Mobile number already taken':'Mobile number already taken',
          'Mobile Number not found':'Mobile Number not found',
          'Mobile*':'Mobile*',
          'Modified By':'Modified By',
          'Modified Date':'Modified Date',
          'MYR':'MYR',
          'Name':'Name',
          'New Balance':'New Balance',
          'New Password':'New Password',
          'New Wallet Balance':'New Wallet Balance',
          'No':'No',
          'Not Found':'Not Found',
          'Not yet registered?':'Not yet registered?',
          'Note':'Note',
          'Note must not be empty':'Note must not be empty',
          'Note*':'Note*',
          'Note: Each tier total should not be greater than total allowable commission rate (2%) as of December 4, 2020':'Note: Each tier total should not be greater than total allowable commission rate (2%) as of December 4, 2020',
          'Notes':'Notes',
          'NTD':'NTD',
          'Old Password':'Old Password',
          'Open this link':'Open this link',
          'Operator':'Operator',
          'Operator Add':'Operator Add',
          'Operator addition success!':'Operator addition success!',
          'Operator Code':'Operator Code',
          'Operator Information':'Operator Information',
          'Operator List':'Operator List',
          'Operator modification success!':'Operator modification success!',
          'Operator Name':'Operator Name',
          'Operator View':'Operator View',
          'Operators':'Operators',
          'out of':'out of',
          'Page not found':'Page not found',
          'Parent Agent Code':'Parent Agent Code',
          'Password':'Password',
          'Password*':'Password*',
          'Payee Code':'Payee Code',
          'Payee Type':'Payee Type',
          'Payment Channel Account Name':'Payment Channel Account Name',
          'Payment Channel Account Number':'Payment Channel Account Number',
          'Payment Channel Add':'Payment Channel Add',
          'Payment Channel Edit':'Payment Channel Edit',
          'Payment Channel edit success!':'Payment Channel edit success!',
          'Payment Channel Information':'Payment Channel Information',
          'Payment Channel List':'Payment Channel List',
          'Payment Channel View':'Payment Channel View',
          'Payment Channels':'Payment Channels',
          'Payor Code':'Payor Code',
          'Payor Type':'Payor Type',
          'PENDING':'PENDING',
          'Pending Agent':'Pending Agent',
          'Pending Deposits':'Pending Deposits',
          'Pending Players':'Pending Players',
          'Pending Withdraws':'Pending Withdraws',
          'Period':'Period',
          'Plasada Amount':'Plasada Amount',
          'Player Bets':'Player Bets',
          'Player Credit Memo':'Player Credit Memo',
          'Player Debit Memo':'Player Debit Memo',
          'Player Deposit':'Player Deposit',
          'Player Deposit Information':'Player Deposit Information',
          'Player Deposit List':'Player Deposit List',
          'Player Deposit View':'Player Deposit View',
          'Player Information':'Player Information',
          'Player List':'Player List',
          'Player No.':'Player No.',
          'Player Number':'Player Number',
          'Player Username':'Player Username',
          'Player View':'Player View',
          'Player Withdraw':'Player Withdraw',
          'Player Withdrawal Information':'Player Withdrawal Information',
          'Player Withdrawal List':'Player Withdrawal List',
          'Player Withdrawal View':'Player Withdrawal View',
          'PLAYER_CM':'PLAYER_CM',
          'PLAYER_DEPOSIT':'PLAYER_DEPOSIT',
          'PLAYER_DM':'PLAYER_DM',
          'PLAYER_WITHDRAW':'PLAYER_WITHDRAW',
          'Players':'Players',
          'Players Deposit':'Players Deposit',
          'Players List':'Players List',
          'Players Withdrawals':'Players Withdrawals',
          'Please wait…':'Please wait…',
          'Prev. Balance':'Prev. Balance',
          'Previous Balance':'Previous Balance',
          'Profile':'Profile',
          'records':'records',
          'Reference Transaction Number':'Reference Transaction Number',
          'Register':'Register',
          'Register Now':'Register Now',
          'Registered successfully! We will contact you for your account activation.':'Registered successfully! We will contact you for your account activation.',
          'Registration':'Registration',
          'Remarks':'Remarks',
          'Request Fund Transfer':'Request Fund Transfer',
          'Requested Amount Must Be Greater Than Commission Balance':'Requested Amount Must Be Greater Than Commission Balance',
          'Requested amount must be greater than minimum transfer amount':'Requested amount must be greater than minimum transfer amount',
          'Reset':'Reset',
          'Reset Password':'Reset Password',
          'Reset Password success!':'Reset Password success!',
          'Role':'Role',
          's password?':'s password?',
          'Sabong Network Login':'Sabong Network Login',
          'Save':'Save',
          'Secret Key':'Secret Key',
          'Select Country Code':'Select Country Code',
          'Setup':'Setup',
          'Share Code Link has been copied to your clipboard!':'Share Code Link has been copied to your clipboard!',
          'Shareable Link':'Shareable Link',
          'Shop Name is already taken':'Shop Name is already taken',
          'Showing':'Showing',
          'Sign Up':'Sign Up',
          'Skype':'Skype',
          'Something went wrong, please contact the administrator at':'Something went wrong, please contact the administrator at',
          'Start Date':'Start Date',
          'Status':'Status',
          'Sub Agent':'Sub Agent',
          'Sub Agent Comm. Rate':'Sub Agent Comm. Rate',
          'SUB_AGENT':'SUB_AGENT',
          'Submit':'Submit',
          'Super Admin':'Super Admin',
          'Super Agent':'Super Agent',
          'Super Agent Comm. Rate':'Super Agent Comm. Rate',
          'SUPER MASTER AGENT':'SUPER MASTER AGENT',
          'SUPER_ADMIN':'SUPER_ADMIN',
          'SUPER_AGENT':'SUPER_AGENT',
          'System Information':'System Information',
          'Telegram':'Telegram',
          'The :attribute is not valid format and you can only use numeric and (+) plus sign':'The :attribute is not valid format and you can only use numeric and (+) plus sign',
          'The Commission Rate must be less than the rate assigned to you.':'The Commission Rate must be less than the rate assigned to you.',
          'this Agent?':'this Agent?',
          'this Commission? This action cannot be undone.':'this Commission? This action cannot be undone.',
          'this deposit? This action cannot be undone.':'this deposit? This action cannot be undone.',
          'this Player? This action cannot be undone.':'this Player? This action cannot be undone.',
          'this withdrawal? This action cannot be undone.':'this withdrawal? This action cannot be undone.',
          'Tier 1 (Single Layer commissioning)':'Tier 1 (Single Layer commissioning)',
          'Tier 2 (Double Layer commissioning)':'Tier 2 (Double Layer commissioning)',
          'Tier 3 (Tri Layer commissioning)':'Tier 3 (Tri Layer commissioning)',
          'to':'to',
          'toggle password visibility':'toggle password visibility',
          'Total commission from player will be divided to Super Agent and Agent. Sum should equate to allowable commission of sabong.':'Total commission from player will be divided to Super Agent and Agent. Sum should equate to allowable commission of sabong.',
          'Total commission from player will be divided to Super Agent, Agent and Sub Agent. Sum should equate to allowable commission of sabong.':'Total commission from player will be divided to Super Agent, Agent and Sub Agent. Sum should equate to allowable commission of sabong.',
          'Total Commission from player will directly go to Super Agent alone.':'Total Commission from player will directly go to Super Agent alone.',
          'Total Sum':'Total Sum',
          'Transaction Amount':'Transaction Amount',
          'Transaction Date':'Transaction Date',
          'Transaction No.':'Transaction No.',
          'Transaction Number':'Transaction Number',
          'Transaction Type':'Transaction Type',
          'Transfer From Commission':'Transfer From Commission',
          'Transfer To Wallet':'Transfer To Wallet',
          'TRANSFER_FROM_COMMISSION':'TRANSFER_FROM_COMMISSION',
          'TRANSFER_TO_WALLET':'TRANSFER_TO_WALLET',
          'Trx No.':'Trx No.',
          'Trx Type.':'Trx Type.',
          'Txn Date':'Txn Date',
          'Txn No.':'Txn No.',
          'Txn Type':'Txn Type',
          'Type':'Type',
          'Unable to process your request':'Unable to process your request',
          'Unlock':'Unlock',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'Use this facility when your agent had forgotten his / her password. Please save the generated password.',
          'User':'User',
          'User Add':'User Add',
          'User Agent':'User Agent',
          'User Change Password':'User Change Password',
          'User Information':'User Information',
          'User List':'User List',
          'User Name':'User Name',
          'User Name*':'User Name*',
          'User View':'User View',
          'Username':'Username',
          'Username already taken':'Username already taken',
          'Username already used':'Username already used',
          'Username cannot be empty':'Username cannot be empty',
          'Username is already taken':'Username is already taken',
          'Username not found':'Username not found',
          'Users':'Users',
          'Verified CS':'Verified CS',
          'VerifiedCS':'VerifiedCS',
          'Verify':'Verify',
          'Vietnamese':'Vietnamese',
          'VND':'VND',
          'Wallet Ledger':'Wallet Ledger',
          'Wallet Ledger View':'Wallet Ledger View',
          'Wallet Point Ledger Information':'Wallet Point Ledger Information',
          'Wallet Points':'Wallet Points',
          'Welcome Back':'Welcome Back',
          'Whatsapp':'Whatsapp',
          'Withdraw':'Withdraw',
          'Withdrawals':'Withdrawals',
          'Yes':'Yes',
          'You have insuficient fund, please request for top up first.':'You have insuficient fund, please request for top up first.',
          'your commission? Once you submit this transaction it cannot be undone.':'your commission? Once you submit this transaction it cannot be undone.',
        }
      },
      id: { //Indonesian
        translations: {
          'Add New Agent':'Tambahkan Agen Baru',
          'Commission List':'Daftar Komisi',
          'Enter your credentials to access your account.':'Masukkan kredensial Anda untuk mengakses akun Anda.',
          'username':'nama pengguna',
          'password':'kata sandi',
          'Login':'Gabung',
          'Not yet registered?':'Belum terdaftar?',
          'Create an Account':'Buat sebuah akun',
          'Earn 3% of commission in every bets!':'Hasilkan komisi 3% di setiap taruhan!',
          'REGISTER NOW':'DAFTAR SEKARANG',
          'BECOME A':'MENJADI SEBUAH',
          'SUPER MASTER AGENT':'AGEN SUPER MASTER',
          // 'English':'Bahasa Inggris',
          // 'Indonesian':'Bahasa Indonesia',
          // 'Korean':'Korea',
          // 'Malaysian':'Malaysia',
          // 'Vietnamese':'Orang Vietnam',
          'The username field is required.':'Bidang nama pengguna wajib diisi.',
          'The password field is required.':'Bidang kata sandi wajib diisi.',
          'Sorry unable to serve your request. Please contact administrator.':'Maaf tidak dapat melayani permintaan Anda. Silahkan hubungi admin.',
          'User Name*':'Nama pengguna*',
          'Username*':'Nama pengguna*',
          'Password*':'Kata sandi*',
          'First Name*':'Nama depan*',
          'Firstname*':'Nama depan*',
          'Last Name*':'Nama keluarga*',
          'Lastname*':'Nama keluarga*',
          'Currency*':'Mata uang*',
          'Email*':'Surel*',
          'Mobile*':'Seluler*',
          'Telegram':'Telegram',
          'Skype':'Skype',
          'Whatsapp':'Ada apa',
          'Register':'Daftar',
          'Sabong Network recreational hobby':'Hobi rekreasi Jaringan Sabong',
          'Sign Up':'Mendaftar',
          'Create your account and become a master agent now.':'Buat akun Anda dan jadilah agen master sekarang.',
          // 'English':'Bahasa Inggris',
          'Already have an account?':'Sudah memiliki akun?',
          'Registered successfully! We will contact you for your account activation.':'Terdaftar dengan sukses! Kami akan menghubungi Anda untuk aktivasi akun Anda.',
          'Welcome':'Selamat datang',
          'Wallet Points':'Poin Dompet',
          'Commission':'Komisi',
          'Commissions':'Komisi',
          'Pending Deposits':'Setoran Tertunda',
          'Pending Withdrawals':'Penarikan Tertunda',
          'Active Players':'Pemain Aktif',
          'Pending Players':'Pemain Tertunda',
          'Blocked Players':'Pemain yang Diblokir',
          'Active Agent':'Agen Aktif',
          'Pending Agent':'Agen tertunda',
          'Blocked Agent':'Agen yang Diblokir',
          'Profile':'Profil',
          'Change Password':'Ganti kata sandi',
          'Logout':'Keluar',
          'Dashboard':'Dasbor',
          'Players':'Pemain',
          'Manage':'Mengelola',
          'Deposits':'Deposito',
          'Withdrawals':'Penarikan',
          'Agent':'Agen',
          'Tree View':'Pemandangan Pohon',
          'Cap View':'Tampilan Topi',
          'Funds Request':'Permintaan Dana',
          'Funds for Approval':'Dana untuk Persetujuan',
          'Ledgers':'buku besar',
          'Commission Points':'Poin Komisi',
          'Payment Channels':'Saluran Pembayaran',
          'Commission List':'Daftar Komisi',
          'Filter':'Saring',
          'Clear':'Jernih',
          'Status':'Status',
          'Period':'Periode',
          'Agent Code':'Kode Agen',
          'Amount':'Jumlah',
          'Approved Amount':'Jumlah yang Disetujui',
          'Start Date':'Mulai tanggal',
          'End Date':'Tanggal Akhir',
          'Showing':'Menampilkan',
          'Limit':'Membatasi',
          'export':'ekspor',
          'Pending':'Tertunda',
          'Approved':'Disetujui',
          'Adjusted':'Disesuaikan',
          'Game URL':'URL permainan',
          'Open this link':'Buka tautan ini',
          'Player Username':'Nama Pengguna Pemain',
          'Player Number':'Nomor Pemain',
          'Player No.':'Pemain No.',
          'Created Date':'Tanggal Dibuat',
          'Action':'Tindakan',
          'Players List':'Daftar Pemain',
          'Players List':'Daftar Pemain',
          'Players Deposit':'Deposit Pemain',
          'Player Deposits':'Deposit Pemain',
          'Player Deposit':'Deposit Pemain',
          'Disapproved':'Tidak disetujui',
          'Transaction No.':'No. Transaksi',
          'Players Withdrawals':'Penarikan Pemain',
          'Player Withdrawals':'Penarikan Pemain',
          'Active':'Aktif',
          'Inactive':'Tidak aktif',
          'Hold':'Memegang',
          'Blocked':'Diblokir',
          'Credit Limit':'Batas Kredit',
          'Commission Rate':'Tarif Komisi',
          'Agent Type':'Jenis Agen',
          'Parent Agent Code':'Kode Agen Induk',
          'Date Registered':'Tanggal Terdaftar',
          'Add New Agent':'Tambahkan Agen Baru',
          'Super Agent':'Agen Super',
          'Request Fund Transfer':'Minta Transfer Dana',
          'Fund Request List':'Daftar Permintaan Dana',
          'Payee Code':'Kode Penerima Pembayaran',
          'Payor Code':'Kode Pembayar',
          'Type':'Jenis',
          'Deposit':'Menyetorkan',
          'Withdraw':'Menarik',
          'Payor Type':'Jenis Pembayar',
          'Fund Approval List':'Daftar Persetujuan Dana',
          'Wallet Ledger':'Buku Besar Dompet',
          'Memo Type':'Jenis Memo',
          'Debit':'Debet',
          'Credit':'Kredit',
          'New Balance':'Keseimbangan baru',
          'Prev. Balance':'sebelumnya Keseimbangan',
          'Player Withdraw':'Penarikan Pemain',
          'Player Withdrawal':'Penarikan Pemain',
          'Player Credit Memo':'Memo Kredit Pemain',
          'Player Debit Memo':'Memo Debit Pemain',
          'Agent Deposit':'Setoran Agen',
          'Agent Withdraw':'Penarikan Agen',
          'Agent Withdrawal':'Penarikan Agen',
          'Agent Credit Memo':'Memo Kredit Agen',
          'Agent Debit Memo':'Memo Debit Agen',
          'Admin Credit Memo':'Memo Kredit Admin',
          'Admin Debit Memo':'Memo Debit Admin',
          'Commission Income':'Pendapatan Komisi',
          'Commission Approval':'Persetujuan Komisi',
          'Credit Memo':'Memo kredit',
          'Debit Memo':'Memo debit',
          'Commission Ledger List':'Daftar Buku Besar Komisi',
          'Address 1':'Alamat 1',
          'Address 2':'alamat 2',
          'Credit Limit':'Batas Kredit',
          'Commission Rate':'Tarif Komisi',
          'Cancel':'Membatalkan',
          'Save':'Menyimpan',
          'Remarks':'Perkataan',
          'Add Payment Channel':'Tambahkan Saluran Pembayaran',
          'Bank Name or Payment Channel Name (e.g HSBC WeChat Pay etc)':'Nama Bank atau Nama Saluran Pembayaran (misalnya, HSBC WeChat Pay, dll)',
          'Account Name':'Nama akun',
          'Account Number':'Nomor rekening',
          'Channel Name':'nama saluran',
          'Date Created':'Tanggal Diciptakan',
          'Welcome Back':'Selamat Datang kembali',
          'System Information' : 'Sistem Informasi',
          'Register Now': 'Daftar sekarang',
          'Pending Withdraws': 'Penarikan Tertunda',
          'Please wait...': 'Mohon tunggu...',
          'Approved': 'DISETUJUI',
          'APPROVED': 'DISETUJUI',
          'Account Information':'Informasi Akun',
          'Middle Name':'Nama tengah',
          'Email':'Surel',
          'Currency':'Mata uang',
          'Account Expired':'Akun Kedaluwarsa',
          'Credential Expired':'Kredensial Kedaluwarsa',
          'Enabled':'Diaktifkan',
          'Account Locked':'Akun terkunci',
          'First Name':'Nama depan',
          'Last Name':'Nama keluarga',
          'Mobile':'Seluler',
          'Created By':'Dibuat oleh',
          'Modified By':'Dimodifikasi Oleh',
          'Modified Date':'Tanggal yang diubah',
          'Lock':'Kunci',
          'Agent':'Agen',
          'Code':'Kode',
          'Mobile':'Seluler',
          'First Name':'Nama depan',
          'Last Name':'Nama keluarga',
          'Deactivate':'Menonaktifkan',
          'Activate':'Mengaktifkan',
          'Inactive':'Tidak aktif',
          'Active':'Aktif',
          'Commission Ledger':'Buku Besar Komisi',
          'Commission':'Komisi',
          'Commissions':'Komisi',
          'Downlines':'Downline',
          'Back to List':'Kembali ke daftar',
          'Reset Password':'Setel Ulang Kata Sandi',
          'Edit':'Sunting',
          'Username':'Nama pengguna',
          'Reset Password':'Setel Ulang Kata Sandi',
          'Cancel':'Membatalkan',
          'Submit':'Kirim',
          'Payee Type':'Jenis Penerima Pembayaran',
          'Trx Type.':'Tipe Trx.',
          'Trx No.':'Trx No.',
          'Created':'Dibuat',
          'Verified CS':'CS Terverifikasi',
          'Locked':'Terkunci',
          'Verifiedcs':'Terverifikasi',
          'New Password':'kata sandi baru',
          'out of':'dari',
          'to':'ke',
          'Change Paswword':'Ubah Kata Sandi',
          'Logout':'Keluar',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'Gunakan fasilitas ini ketika agen Anda lupa kata sandinya. Harap simpan kata sandi yang dihasilkan.',
          'Delete Record':'Hapus Rekaman',
          'Are you sure you want to delete this record?':'Anda yakin ingin menghapus catatan ini?',
          'Yes':'Ya',
          'No':'Tidak',
          'Select Country Code':'Pilih Kode Negara',
          'this Agent?':'Agen ini?',
          'Confirm':'Mengonfirmasi',
          'Are you sure you want to':'Apakah Anda yakin ingin?',
          'Approve':'Menyetujui',
          'this Commission? This action cannot be undone.':'Komisi ini? Tindakan ini tidak bisa dibatalkan.',
          'this Player? This action cannot be undone.':'Pemain ini? Tindakan ini tidak bisa dibatalkan.',
          'Verify':'Memeriksa',
          'this deposit? This action cannot be undone.':'setoran ini? Tindakan ini tidak bisa dibatalkan.',
          'this withdrawal? This action cannot be undone.':'penarikan ini? Tindakan ini tidak bisa dibatalkan.',
          'Network':'Jaringan',
          // 'English':'English',
          // 'Korean':'한국인',
          // 'Indonesian':'bahasa Indonesia',
          // 'Malaysian':'Malaysia',
          // 'Vietnamese':'Tiếng Việt',
          'Fill in the form below':'Isi formulir di bawah ini',
          'Convert': 'Mengubah',
          'your commission? Once you submit this transaction it cannot be undone.' : 'komisi Anda? Setelah Anda mengirimkan transaksi ini, itu tidak dapat dibatalkan.',
          'Convert Commission' : 'Komisi Konversi',
          'Agent Information':'Informasi Agen',
          'Player Deposit Information':'Informasi Setoran Pemain',
          'Fund Transaction Information':'Informasi Transaksi Dana',
          'Transaction Number':'Nomor transaksi',
          'Transaction Date':'Tanggal transaksi',
          'Transaction Type':'Tipe transaksi',
          'Transaction Amount':'Jumlah transaksi',
          'Reference Transaction Number':'Nomor Transaksi Referensi',
          'Name':'Nama',
          'Number':'Nomor',
          'Agent':'Agen',
          'Currency':'Mata uang',
          'New Wallet Balance':'Saldo Dompet Baru',
          'Previous balance':'Saldo sebelumnya',
          'Memo Type':'Jenis Memo',
          'Remarks':'Perkataan',
          'Account Name':'Nama akun',
          'Channel Name':'Nama saluran',
          'Account Number':'Nomor rekening',
          'Date Created':'Tanggal Diciptakan',
          'Add New Payment Channel':'Tambahkan Saluran Pembayaran Baru',
          'Commission Ledger List':'Daftar Buku Besar Komisi',
          'Previous Balance':'Saldo Sebelumnya',
          'Delete':'Hapus',
          'Bank Name or Payment Channel Name':'Nama Bank atau Nama Saluran Pembayaran',
          'Memo':'Memo',
          'Txn No.':'Txn No.',
          'Txn Type':'Tipe Txn',
          'Txn Date':'Txn Tanggal',
          'Credit':'Kredit',
          'CREDIT':'KREDIT',
          'DEBIT':'DEBET',
          'Admin':'Admin',
          'PENDING':'TERTUNDA',
          'DEPOSIT':'MENYETORKAN',
          'ADMIN':'ADMIN',
          'Note':'Catatan',
          'Fund type':'Jenis dana',
          'Fund Transaction':'Transaksi Dana',
          'ACTIVE':'AKTIF',
          'INACTIVE':'TIDAK AKTIF',
          'Minimum Commission Conversion':'Konversi Komisi Minimum',
          'Payment Channel Account Name':'Nama Akun Saluran Pembayaran',
          'Player Information':'Informasi Pemain',
          'Edit Agent':'Edit Agen',
          'Shareable Link':'Tautan yang Dapat Dibagikan',
          'Super Agen':'Agen Super',
          'Fund Type':'Jenis Dana',
          'DEBIT':'DEBIT',
          'Wallet Point Ledger Information':'Informasi Buku Besar Poin Dompet',
          'Commission Point Ledger Information':'Informasi Buku Besar Poin Komisi',
          'Account Expired':'Akun Kedaluwarsa',
          'Account Information':'Informasi Akun',
          'Account is Inactive, please contact the administrator at':'Akun Tidak Aktif, silakan hubungi administrator di',
          'Account is locked due to multiple invalid attempts, please contact the administrator at':'Akun terkunci karena beberapa upaya yang tidak valid, silakan hubungi administrator di',
          'Account is locked, please contact the administrator at':'Akun terkunci, silakan hubungi administrator di',
          'Account Locked':'Akun terkunci',
          'Account Name':'Nama akun',
          'Account Non Expired':'Akun Tidak Kedaluwarsa',
          'Account Non Locked':'Akun Tidak Terkunci',
          'Account Number':'Nomor rekening',
          'Action':'Tindakan',
          'Activate':'Mengaktifkan',
          'ACTIVE':'AKTIF',
          'Active Agent':'Agen Aktif',
          'Active Players':'Pemain Aktif',
          'Add Agent':'Tambahkan Agen',
          'Add New Agent':'Tambahkan Agen Baru',
          'Add New Operator':'Tambahkan Operator Baru',
          'Add New Payment Channel':'Tambahkan Saluran Pembayaran Baru',
          'Add New User':'Tambahkan pengguna baru',
          'Add Payment Channel':'Tambahkan Saluran Pembayaran',
          'Address':'Alamat',
          'Address 1':'Alamat 2',
          'Address 2':'alamat 2',
          'Adjusted':'Disesuaikan',
          'Admin':'Admin',
          'Admin Credit Memo':'Memo Kredit Admin',
          'Admin Debit Memo':'Memo Debit Admin',
          'ADMIN_CM':'ADMIN_CM',
          'ADMIN_DM':'ADMIN_DM',
          'Agent':'Agen',
          'Agent Add':'Agen Tambah',
          'Agent addition success!':'Keberhasilan penambahan agen!',
          'Agent Code':'Kode Agen',
          'Agent Code already taken':'Kode Agen sudah diambil',
          'Agent Comm. Rate':'Agen Kom. Kecepatan',
          'Agent Credit Memo':'Memo Kredit Agen',
          'Agent Debit Memo':'Memo Debit Agen',
          'Agent Deposit':'Setoran Agen',
          'Agent edit success!':'Agen berhasil mengedit!',
          'Agent Information':'Informasi Agen',
          'Agent List':'Daftar Agen',
          'Agent Name':'Nama agen',
          'Agent Type':'Jenis Agen',
          'Agent View':'Tampilan Agen',
          'Agent Withdraw':'Penarikan Agen',
          'AGENT_CM':'AGEN_CM',
          'AGENT_DEPOSIT':'AGEN_DEPOSIT',
          'AGENT_DM':'AGEN_DM',
          'AGENT_WITHDRAW':'AGEN_WITHDRAW',
          'Agents Add':'Agen Tambah',
          'Agents Edit':'Agen Sunting',
          'Agents List':'Daftar Agen',
          'Agents Tree':'Pohon Agen',
          'Agents View':'Tampilan Agen',
          'Already have an account?':'Sudah memiliki akun?',
          'Amount':'Jumlah',
          'API Key':'kunci API',
          'Application Error':'Aplikasi error',
          'Approve':'Menyetujui',
          'Approved':'Disetujui',
          'Approved Amount':'Jumlah yang Disetujui',
          'Approved Commission Amount':'Jumlah Komisi yang Disetujui',
          'Are you sure you want to':'Apakah Anda yakin ingin?',
          'Are you sure you want to <b>Approve</b> this Commission? This action cannot be undone.':'Anda yakin ingin <b>Menyetujui</b> Komisi ini? Tindakan ini tidak bisa dibatalkan.',
          'Are you sure you want to delete this record?':'Anda yakin ingin menghapus catatan ini?',
          'Back to List':'Kembali ke daftar',
          'Bank Name or Payment Channel Name':'Nama Bank atau Nama Saluran Pembayaran',
          'Base API Url':'Url API Dasar',
          'BECOME A':'MENJADI SEBUAH',
          'Bet Amount':'Jumlah Taruhan',
          'Bet Corner':'Sudut Taruhan',
          'Bet Result':'Hasil Taruhan',
          'BLOCKED':'DIBLOKIR',
          'Blocked Agent':'Agen yang Diblokir',
          'Blocked Players':'Pemain yang Diblokir',
          'Cancel':'Membatalkan',
          'Cap View':'Tampilan topi',
          'Change Password':'Ganti kata sandi',
          'Change password success! Use your new password on your next login':'Ubah kata sandi berhasil! Gunakan kata sandi baru Anda pada login berikutnya',
          'Channel Name':'nama saluran',
          'Channel Name (eg. GCash, Paymaya, ect)':'Nama Saluran (mis. GCash, Paymaya, dll)',
          'Clear':'Jernih',
          'Client Key':'Kunci Klien',
          'CNY':'CNY',
          'Code':'Kode',
          'Commission':'Komisi',
          'Commission Approval':'Persetujuan Komisi',
          'Commission Income':'Pendapatan Komisi',
          'Commission Information':'Informasi Komisi',
          'Commission Ledger':'Buku Besar Komisi',
          'Commission Ledger List':'Daftar Buku Besar Komisi',
          'Commission Ledger View':'Tampilan Buku Besar Komisi',
          'Commission List':'Daftar Komisi',
          'Commission Point Ledger Information':'Informasi Buku Besar Poin Komisi',
          'Commission Points':'Poin Komisi',
          'Commission Rate':'Tarif Komisi',
          'Commission Rate must not be zero':'Tarif Komisi tidak boleh nol',
          'Commission Rate Setup':'Pengaturan Tarif Komisi',
          'Commission Rate Setup edit success!':'Penyetelan Tarif Komisi berhasil mengedit!',
          'Commission Rate View':'Tampilan Tarif Komisi',
          'Commission Rates':'Tarif Komisi',
          'Commission View':'Tampilan Komisi',
          'COMMISSION_APPROVAL':'COMMISSION_APPROVAL',
          'COMMISSION_INCOME':'COMMISSION_INCOME',
          'Commissions':'Komisi',
          'Commissions List':'Daftar Komisi',
          'Commissions View':'Tampilan Komisi',
          'Confirm':'Mengonfirmasi',
          'Convert':'Mengubah',
          'Convert Commission':'Komisi Konversi',
          'Convert commission success!':'Konversi komisi sukses!',
          'Create Agent':'Buat Agen',
          'Create an Account':'Buat sebuah akun',
          'Create your account and become a master agent now.':'Buat akun Anda dan jadilah agen master sekarang.',
          'Created':'Dibuat',
          'Created By':'Dibuat oleh',
          'Created Date':'Tanggal Dibuat',
          'Credential Expired':'Kredensial Kedaluwarsa',
          'Credential Non Expired':'Kredensial Tidak Kedaluwarsa',
          'Credit':'Kredit',
          'Credit Limit must not be zero':'Batas Kredit tidak boleh nol',
          'Credit Memo':'Memo kredit',
          'Credit Memo List':'Daftar Memo Kredit',
          'CREDIT_MEMO':'MEMO KREDIT',
          'Currency':'Mata uang',
          'Currency*':'Mata uang*',
          'Dashboard':'Dasbor',
          'Date':'Tanggal',
          'Date Created':'Tanggal Diciptakan',
          'Date Modified':'Tanggal Dimodifikasi',
          'Date Registered':'Tanggal Terdaftar',
          'Deactivate':'Menonaktifkan',
          'Debit':'Debet',
          'Debit Memo':'Memo debit',
          'Debit Memo List':'Daftar Memo Debit',
          'DEBIT_MEMO':'MEMO DEBIT',
          'Delete':'Menghapus',
          'Deposit':'Menyetorkan',
          'Deposits':'Deposito',
          'Disapprove':'tidak setuju',
          'Disapproved':'Tidak disetujui',
          'Downlines':'downline',
          'download':'unduh',
          'e.g HSBC, WeChat Pay, etc':'misalnya HSBC, WeChat Pay, dll',
          'Earn 3% of commission in every bets!':'Hasilkan komisi 3% di setiap taruhan!',
          'Edit':'Sunting',
          'Edit Agent':'Edit Agen',
          'Edit Payment Channel':'Sunting Saluran Pembayaran',
          'Email':'Surel',
          'Email already taken':'Email sudah di ambil',
          'Email cannot be empty':'Email tidak boleh kosong',
          'Email is invalid':'email tidak valid',
          'Email not found':'Email tidak ditemukan',
          'Email*':'Surel*',
          'Enabled':'Diaktifkan',
          'End Date':'Tanggal Akhir',
          // 'English':'bahasa Inggris',
          'Enter your credentials to access your account.':'Masukkan kredensial Anda untuk mengakses akun Anda.',
          'Expired':'Kedaluwarsa',
          'export':'ekspor',
          'Fill in the form below':'Isi formulir di bawah ini',
          'Filter':'Saring',
          'First Name':'Nama depan',
          'First Name*':'Nama depan*',
          'Fund Approval List':'Daftar Persetujuan Dana',
          'Fund In':'dana masuk',
          'Fund List Approval':'Persetujuan Daftar Dana',
          'Fund List Request':'Permintaan Daftar Dana',
          'Fund Request List':'Daftar Permintaan Dana',
          'Fund Transaction':'Transaksi Dana',
          'Fund Transaction has been sent! please wait for the approval.':'Transaksi Dana telah terkirim! mohon ditunggu persetujuannya.',
          'Fund Transaction Information':'Informasi Transaksi Dana',
          'Fund type':'Jenis dana',
          'Fund View Approval':'Persetujuan Tampilan Dana',
          'Fund View Request':'Permintaan Tampilan Dana',
          'Funds For Approval':'Dana Untuk Persetujuan',
          'Funds List':'Daftar Dana',
          'Funds Request':'Permintaan Dana',
          'Funds View':'Tampilan Dana',
          'Game URL':'URL permainan',
          'Go Back':'Kembali',
          'HOLD':'MEMEGANG',
          'Home':'Rumah',
          'IDR':'Rp',
          'INACTIVE':'TIDAK AKTIF',
          'Indonesian':'bahasa Indonesia',
          'Insufficient Balance':'Saldo tidak mencukupi',
          'Insufficient Wallet Commission Balance':'Saldo Komisi Wallet tidak mencukupi',
          'Insufficient withdrawal amount':'Jumlah penarikan tidak mencukupi',
          'Invalid Amount':'Jumlah Tidak Valid',
          'Invalid credential':'Kredensial tidak sah',
          'Invalid credential on fourth attempt, account will be locked on 5th try':'Kredensial tidak valid pada upaya keempat, akun akan dikunci pada percobaan ke-5',
          'Invalid credential on third attempt, account will be locked on 5th try':'Kredensial tidak valid pada upaya ketiga, akun akan dikunci pada percobaan ke-5',
          'Invalid credential, please recheck your inputs':'Kredensial tidak valid, harap periksa kembali input Anda',
          'Invalid credentials, Your account has been locked, please contact the administrator at':'Kredensial tidak valid, Akun Anda telah dikunci, harap hubungi administrator di',
          'Invalid OTP':'OTP tidak valid',
          'Invalid OTP, please check your input or you may regenerate again':'OTP tidak valid, silakan periksa input Anda atau Anda dapat membuat ulang lagi',
          'Invalid Username / Password':'Nama pengguna / sandi tidak benar',
          'Invalid username or password, please check your input':'Nama pengguna atau kata sandi tidak valid, harap periksa input Anda',
          'Korean':'Korea',
          'KRW':'KRW',
          'Last Name':'Nama keluarga',
          'Last Name*':'Nama keluarga*',
          'Ledgers':'buku besar',
          'Limit':'Membatasi',
          'Lock':'Kunci',
          'Locked':'Terkunci',
          'Login':'Gabung',
          'Malaysian':'Malaysia',
          'Manage':'Mengelola',
          'Match Code':'Kode Pertandingan',
          'Memo':'Memo',
          'Memo Type':'Jenis Memo',
          'Middle Name':'Nama tengah',
          'Minimum Commission Conversion':'Konversi Komisi Minimum',
          'Mobile':'Seluler',
          'Mobile number already taken':'Nomor ponsel sudah diambil',
          'Mobile Number not found':'Nomor Ponsel tidak ditemukan',
          'Mobile*':'Ponsel*',
          'Modified By':'Dimodifikasi Oleh',
          'Modified Date':'Tanggal yang diubah',
          'MYR':'MYR',
          'Name':'Nama',
          'New Balance':'Keseimbangan baru',
          'New Password':'kata sandi baru',
          'New Wallet Balance':'Saldo Dompet Baru',
          'No':'Tidak',
          'Not Found':'Tidak ditemukan',
          'Not yet registered?':'Belum terdaftar?',
          'Note':'Catatan',
          'Note must not be empty':'Catatan wajib diisi',
          'Note*':'Catatan*',
          'Note: Each tier total should not be greater than total allowable commission rate (2%) as of December 4, 2020':'Catatan: Setiap total tingkat tidak boleh lebih besar dari total tingkat komisi yang diizinkan (2%) per 4 Desember 2020',
          'Notes':'Catatan',
          'NTD':'NTD',
          'Old Password':'password lama',
          'Open this link':'Buka tautan ini',
          'Operator':'Operator',
          'Operator Add':'Operator Tambah',
          'Operator addition success!':'Sukses penambahan operator!',
          'Operator Code':'Kode Operator',
          'Operator Information':'Informasi Operator',
          'Operator List':'Daftar Operator',
          'Operator modification success!':'Sukses modifikasi operator!',
          'Operator Name':'Nama Operator',
          'Operator View':'Tampilan Operator',
          'Operators':'Operator',
          'out of':'dari',
          'Page not found':'halaman tidak ditemukan',
          'Parent Agent Code':'Kode Agen Induk',
          'Password':'Kata sandi',
          'Password*':'Kata sandi*',
          'Payee Code':'Kode Penerima Pembayaran',
          'Payee Type':'Jenis Penerima Pembayaran',
          'Payment Channel Account Name':'Nama Akun Saluran Pembayaran',
          'Payment Channel Account Number':'Nomor Akun Saluran Pembayaran',
          'Payment Channel Add':'Tambahkan Saluran Pembayaran',
          'Payment Channel Edit':'Pengeditan Saluran Pembayaran',
          'Payment Channel edit success!':'Pengeditan Saluran Pembayaran berhasil!',
          'Payment Channel Information':'Informasi Saluran Pembayaran',
          'Payment Channel List':'Daftar Saluran Pembayaran',
          'Payment Channel View':'Tampilan Saluran Pembayaran',
          'Payment Channels':'Saluran Pembayaran',
          'Payor Code':'Kode Pembayar',
          'Payor Type':'Jenis Pembayar',
          'PENDING':'TERTUNDA',
          'Pending Agent':'Agen Tertunda',
          'Pending Deposits':'Setoran Tertunda',
          'Pending Players':'Pemain Tertunda',
          'Pending Withdraws':'Penarikan Tertunda',
          'Period':'Periode',
          'Plasada Amount':'Jumlah Plasada',
          'Player Bets':'Taruhan Pemain',
          'Player Credit Memo':'Memo Kredit Pemain',
          'Player Debit Memo':'Memo Debit Pemain',
          'Player Deposit':'Deposit Pemain',
          'Player Deposit Information':'Informasi Deposit Pemain',
          'Player Deposit List':'Daftar Deposit Pemain',
          'Player Deposit View':'Tampilan Deposit Pemain',
          'Player Information':'Informasi Pemain',
          'Player List':'Daftar Pemain',
          'Player No.':'Pemain No.',
          'Player Number':'Nomor Pemain',
          'Player Username':'Nama Pengguna Pemain',
          'Player View':'Tampilan Pemain',
          'Player Withdraw':'Penarikan Pemain',
          'Player Withdrawal Information':'Informasi Penarikan Pemain',
          'Player Withdrawal List':'Daftar Penarikan Pemain',
          'Player Withdrawal View':'Tampilan Penarikan Pemain',
          'PLAYER_CM':'PLAYER_CM',
          'PLAYER_DEPOSIT':'PLAYER_DEPOSIT',
          'PLAYER_DM':'PLAYER_DM',
          'PLAYER_WITHDRAW':'PLAYER_WITHDRAW',
          'Players':'Pemain',
          'Players Deposit':'Deposit Pemain',
          'Players List':'Daftar Pemain',
          'Players Withdrawals':'Penarikan Pemain',
          'Please wait…':'Mohon tunggu…',
          'Prev. Balance':'sebelumnya Keseimbangan',
          'Previous Balance':'Saldo sebelumnya',
          'Profile':'Profil',
          'records':'catatan',
          'Reference Transaction Number':'Referensi Nomor Transaksi',
          'Register':'Daftar',
          'Register Now':'Daftar sekarang',
          'Registered successfully! We will contact you for your account activation.':'Terdaftar dengan sukses! Kami akan menghubungi Anda untuk aktivasi akun Anda.',
          'Registration':'Registrasi',
          'Remarks':'Perkataan',
          'Request Fund Transfer':'Minta Transfer Dana',
          'Requested Amount Must Be Greater Than Commission Balance':'Jumlah yang Diminta Harus Lebih Besar Dari Saldo Komisi',
          'Requested amount must be greater than minimum transfer amount':'Jumlah yang diminta harus lebih besar dari jumlah transfer minimum',
          'Reset':'Mengatur ulang',
          'Reset Password':'Setel Ulang Kata Sandi',
          'Reset Password success!':'Setel Ulang Kata Sandi berhasil!',
          'Role':'Peran',
          's password?':'kata sandi?',
          'Sabong Network Login':'Login Jaringan Sabong',
          'Save':'Menyimpan',
          'Secret Key':'Kunci rahasia',
          'Select Country Code':'Pilih Kode Negara',
          'Setup':'Mempersiapkan',
          'Share Code Link has been copied to your clipboard!':'Bagikan Tautan Kode telah disalin ke papan klip Anda!',
          'Shareable Link':'Tautan yang Dapat Dibagikan',
          'Shop Name is already taken':'Nama Toko sudah diambil',
          'Showing':'Menampilkan',
          'Sign Up':'Mendaftar',
          'Skype':'Skype',
          'Something went wrong, please contact the administrator at':'Ada yang tidak beres, silakan hubungi administrator di',
          'Start Date':'Mulai tanggal',
          'Status':'Status',
          'Sub Agent':'Sub Agen',
          'Sub Agent Comm. Rate':'Sub Agen Kom. Kecepatan',
          'SUB_AGENT':'SUB_AGENT',
          'Submit':'Kirim',
          'Super Admin':'Admin super',
          'Super Agent':'Agen Super',
          'Super Agent Comm. Rate':'Super Agen Kom. Kecepatan',
          'SUPER MASTER AGENT':'AGEN SUPER MASTER',
          'SUPER_ADMIN':'SUPER_ADMIN',
          'SUPER_AGENT':'SUPER_AGENT',
          'System Information':'Sistem Informasi',
          'Telegram':'Telegram',
          'The :attribute is not valid format and you can only use numeric and (+) plus sign':':attribute bukan format yang valid dan Anda hanya dapat menggunakan numerik dan (+) tanda plus',
          'The Commission Rate must be less than the rate assigned to you.':'Tarif Komisi harus kurang dari tarif yang diberikan kepada Anda.',
          'this Agent?':'Agen ini?',
          'this Commission? This action cannot be undone.':'Komisi ini? Tindakan ini tidak bisa dibatalkan.',
          'this deposit? This action cannot be undone.':'setoran ini? Tindakan ini tidak bisa dibatalkan.',
          'this Player? This action cannot be undone.':'Pemain ini? Tindakan ini tidak bisa dibatalkan.',
          'this withdrawal? This action cannot be undone.':'penarikan ini? Tindakan ini tidak bisa dibatalkan.',
          'Tier 1 (Single Layer commissioning)':'Tingkat 1 (Pengaturan satu Lapisan)',
          'Tier 2 (Double Layer commissioning)':'Tier 2 (Pengaturan Lapisan Ganda)',
          'Tier 3 (Tri Layer commissioning)':'Tier 3 (Pengaturan Tri Layer)',
          'to':'ke',
          'toggle password visibility':'beralih visibilitas kata sandi',
          'Total commission from player will be divided to Super Agent and Agent. Sum should equate to allowable commission of sabong.':'Total komisi dari pemain akan dibagi menjadi Super Agent dan Agent. Jumlah harus sama dengan komisi sabong yang diizinkan.',
          'Total commission from player will be divided to Super Agent, Agent and Sub Agent. Sum should equate to allowable commission of sabong.':'Total komisi dari pemain akan dibagi menjadi Super Agent, Agent dan Sub Agent. Jumlah harus sama dengan komisi sabong yang diizinkan.',
          'Total Commission from player will directly go to Super Agent alone.':'Total Komisi dari pemain akan langsung masuk ke Super Agent saja.',
          'Total Sum':'Jumlah Total',
          'Transaction Amount':'jumlah transaksi',
          'Transaction Date':'tanggal transaksi',
          'Transaction No.':'No. Transaksi',
          'Transaction Number':'Nomor transaksi',
          'Transaction Type':'tipe transaksi',
          'Transfer From Commission':'Transfer Dari Komisi',
          'Transfer To Wallet':'Transfer Ke Dompet',
          'TRANSFER_FROM_COMMISSION':'TRANSFER_FROM_COMMISSION',
          'TRANSFER_TO_WALLET':'TRANSFER_TO_WALLET',
          'Trx No.':'Trmksh No.',
          'Trx Type.':'Tipe Trx.',
          'Txn Date':'Txn Tanggal',
          'Txn No.':'Txn No.',
          'Txn Type':'Jenis Txn',
          'Type':'Jenis',
          'Unable to process your request':'Tidak dapat memproses permintaan Anda',
          'Unlock':'Membuka kunci',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'Gunakan fasilitas ini ketika agen Anda lupa kata sandinya. Harap simpan kata sandi yang dihasilkan.',
          'User':'Pengguna',
          'User Add':'Tambahkan Pengguna',
          'User Agent':'Agen pengguna',
          'User Change Password':'Pengguna Ubah Kata Sandi',
          'User Information':'informasi pengguna',
          'User List':'Daftar pengguna',
          'User Name':'Nama pengguna',
          'User Name*':'Nama pengguna*',
          'User View':'Tampilan Pengguna',
          'Username':'Nama pengguna',
          'Username already taken':'Nama pengguna sudah diambil',
          'Username already used':'Nama pengguna sudah digunakan',
          'Username cannot be empty':'Nama pengguna tidak boleh kosong',
          'Username is already taken':'Nama pengguna sudah diambil',
          'Username not found':'Nama pengguna tidak ditemukan',
          'Users':'Pengguna',
          'Verified CS':'CS Terverifikasi',
          'VerifiedCS':'CS Terverifikasi',
          'Verify':'Memeriksa',
          'Vietnamese':'Orang Vietnam',
          'VND':'VND',
          'Wallet Ledger':'Buku Besar Dompet',
          'Wallet Ledger View':'Tampilan Buku Besar Dompet',
          'Wallet Point Ledger Information':'Informasi Buku Besar Poin Dompet',
          'Wallet Points':'Poin Dompet',
          'Welcome Back':'Selamat Datang kembali',
          'Whatsapp':'ada apa',
          'Withdraw':'Menarik',
          'Withdrawals':'Penarikan',
          'Yes':'Ya',
          'You have insuficient fund, please request for top up first.':'Anda memiliki dana yang tidak mencukupi, mohon untuk melakukan top up terlebih dahulu.',
          'your commission? Once you submit this transaction it cannot be undone.':'komisi Anda? Setelah Anda mengirimkan transaksi ini, itu tidak dapat dibatalkan.',
        },
      },
      zhhans: { //chinese simplified
        translations: {
          'Add New Agent':'添加新代理',
          'Commission List':'佣金清单',
          'Enter your credentials to access your account.':'输入您的凭据以访问您的帐户。',
          'username':'用户名',
          'password':'密码',
          'Login':'登录',
          'Not yet registered?':'还没有注册？',
          'Create an Account':'创建一个帐户',
          'Earn 3% of commission in every bets!':'在每次投注中赚取 3% 的佣金',
          'REGISTER NOW':'现在注册',
          'BECOME A':'成为一个',
          'SUPER MASTER AGENT':'超级主代理',
          // 'English':'英语',
          // 'Indonesian':'印度尼西亚',
          // 'Korean':'韩国人',
          // 'Malaysian':'马来西亚人',
          // 'Vietnamese':'越南语',
          'The username field is required.':'用户名字段是必需的。',
          'The password field is required.':'密码字段是必需的。',
          'Sorry unable to serve your request. Please contact administrator.':'很抱歉无法满足您的要求。请联系管理员。',
          'User Name*':'用户名*',
          'Username*':'用户名*',
          'Password*':'密码*',
          'First Name*':'名*',
          'Firstname*':'名*',
          'Last Name*':'姓*',
          'Lastname*':'姓*',
          'Currency*':'货币*',
          'Email*':'电子邮件*',
          'Mobile*':'移动的*',
          'Telegram':'电报',
          'Skype':'Skype',
          'Whatsapp':'应用程式',
          'Register':'登记',
          'Sabong Network recreational hobby':'Sabong 网络娱乐爱好',
          'Sign Up':'报名',
          'Create your account and become a master agent now.':'创建您的帐户并立即成为主代理。',
          // 'English':'英语',
          'Already have an account?':'已经有一个帐户？',
          'Registered successfully! We will contact you for your account activation.':'注册成功！我们将与您联系以激活您的帐户。',
          'Welcome':'欢迎',
          'Wallet Points':'钱包积分',
          'Commission':'委员会',
          'Commissions':'委员会',
          'Pending Deposits':'待定存款',
          'Pending Withdrawals':'待取款',
          'Active Players':'活跃玩家',
          'Pending Players':'待定球员',
          'Blocked Players':'被阻止的玩家',
          'Active Agent':'主动代理',
          'Pending Agent':'待定代理',
          'Blocked Agent':'被阻止的代理',
          'Profile':'轮廓',
          'Change Password':'更改密码',
          'Logout':'登出',
          'Dashboard':'仪表盘',
          'Players':'球员',
          'Manage':'管理',
          'Deposits':'存款',
          'Withdrawals':'提款',
          'Agent':'代理人',
          'Tree View':'树视图',
          'Cap View':'帽视图',
          'Funds Request':'资金申请',
          'Funds for Approval':'审批资金',
          'Ledgers':'分类帐',
          'Commission Points':'佣金积分',
          'Payment Channels':'支付渠道',
          'Commission List':'佣金清单',
          'Filter':'筛选',
          'Clear':'清除',
          'Status':'地位',
          'Period':'时期',
          'Agent Code':'代理代码',
          'Amount':'数量',
          'Approved Amount':'核准金额',
          'Start Date':'开始日期',
          'End Date':'结束日期',
          'Showing':'显示',
          'Limit':'限制',
          'export':'出口',
          'Pending':'待办的',
          'Approved':'得到正式认可的',
          'Adjusted':'调整后',
          'Game URL':'游戏网址',
          'Open this link':'打开这个链接',
          'Player Username':'玩家用户名',
          'Player Number':'玩家编号',
          'Player No.':'球员编号',
          'Created Date':'创建日期',
          'Action':'行动',
          'Players List':'球员名单',
          'Players List':'球员名单',
          'Players Deposit':'玩家存款',
          'Player Deposits':'玩家存款',
          'Player Deposit':'玩家存款',
          'Disapproved':'未获批准',
          'Transaction No.':'交易编号',
          'Players Withdrawals':'玩家提款',
          'Player Withdrawals':'玩家提款',
          'Active':'积极的',
          'Inactive':'不活动',
          'Hold':'抓住',
          'Blocked':'被封锁',
          'Credit Limit':'信用额度',
          'Commission Rate':'佣金率',
          'Agent Type':'代理类型',
          'Parent Agent Code':'父代理代码',
          'Date Registered':'注册日期',
          'Add New Agent':'添加新代理',
          'Super Agent':'超级经纪人',
          'Request Fund Transfer':'请求资金转账',
          'Fund Request List':'资金申请清单',
          'Payee Code':'收款人代码',
          'Payor Code':'付款人代码',
          'Type':'类型',
          'Deposit':'订金',
          'Withdraw':'提取',
          'Payor Type':'付款人类型',
          'Fund Approval List':'基金审批清单',
          'Wallet Ledger':'钱包账本',
          'Memo Type':'备忘录类型',
          'Debit':'借方',
          'Credit':'信用',
          'New Balance':'新百伦',
          'Prev. Balance':'上一页平衡',
          'Player Withdraw':'玩家退出',
          'Player Withdrawal':'玩家退出',
          'Player Credit Memo':'玩家信用凭证',
          'Player Debit Memo':'玩家借记单',
          'Agent Deposit':'代理押金',
          'Agent Withdraw':'代理退出',
          'Agent Withdrawal':'代理退出',
          'Agent Credit Memo':'代理贷项凭证',
          'Agent Debit Memo':'代理借记单',
          'Admin Credit Memo':'管理员信用凭证',
          'Admin Debit Memo':'管理员借记通知单',
          'Commission Income':'佣金收入',
          'Commission Approval':'委员会批准',
          'Credit Memo':'贷项通知单',
          'Debit Memo':'借方凭证',
          'Commission Ledger List':'佣金分类帐清单',
          'Address 1':'地址1',
          'Address 2':'地址2',
          'Credit Limit':'信用额度',
          'Commission Rate':'佣金率',
          'Cancel':'取消',
          'Save':'节省',
          'Remarks':'评论',
          'Add Payment Channel':'添加支付渠道',
          'Bank Name or Payment Channel Name (e.g HSBC WeChat Pay etc)':'银行名称或支付渠道名称（例如汇丰微信支付等）',
          'Account Name':'用户名',
          'Account Number':'帐号',
          'Channel Name':'频道名称',
          'Date Created':'创建日期',
          'Welcome Back':'欢迎回来',
          'System Information' : '系統信息',
          'Register Now': '现在注册',
          'Pending Withdraws': '待取款',
          'Please wait...': '请稍等...',
          'Approved': '得到正式认可的',
          'APPROVED': '得到正式认可的',
          'Account Information':'帐户信息',
          'Middle Name':'中間名字',
          'Email':'電子郵件',
          'Currency':'貨幣',
          'Account Expired':'帳戶已過期',
          'Credential Expired':'憑證已過期',
          'Enabled':'啟用',
          'Account Locked':'帳戶被鎖定',
          'First Name':'名',
          'Last Name':'姓',
          'Mobile':'移動的',
          'Created By':'由...製作',
          'Modified By':'修改者',
          'Modified Date':'修改日期',
          'Lock':'鎖',
          'Agent':'代理人',
          'Code':'代碼',
          'Mobile':'移動的',
          'First Name':'名',
          'Last Name':'姓',
          'Deactivate':'停用',
          'Activate':'啟用',
          'Inactive':'不活動',
          'Active':'積極的',
          'Commission Ledger':'佣金分類帳',
          'Commission':'委員會',
          'Commissions':'委員會',
          'Downlines':'下線',
          'Back to List':'返回目錄',
          'Reset Password':'重設密碼',
          'Edit':'編輯',
          'Username':'用戶名',
          'Reset Password':'重設密碼',
          'Cancel':'取消',
          'Submit':'提交',
          'Payee Type':'收款人類型',
          'Trx Type.':'傳輸類型。',
          'Trx No.':'中繼編號',
          'Created':'已創建',
          'Verified CS':'經驗證的 CS',
          'Locked':'鎖定',
          'Verifiedcs':'已驗證',
          'New Password':'新密碼',
          'out of':'在......之外',
          'to':'到',
          'Change Paswword':'更改密碼',
          'Logout':'登出',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'當您的代理忘記了他/她的密碼時使用此工具。請保存生成的密碼。',
          'Delete Record':'刪除記錄',
          'Are you sure you want to delete this record?':'您確定要刪除此記錄嗎？',
          'Yes':'是的',
          'No':'不',
          'Select Country Code':'選擇國家代碼',
          'this Agent?':'這個代理？',
          'Confirm':'確認',
          'Are you sure you want to':'你確定你要',
          'Approve':'批准',
          'this Commission? This action cannot be undone.':'這個委員會？此操作無法撤消。',
          'this Player? This action cannot be undone.':'這個播放器？此操作無法撤消。',
          'Verify':'核實',
          'this deposit? This action cannot be undone.':'這個存款？此操作無法撤消。',
          'this withdrawal? This action cannot be undone.':'這次撤資？此操作無法撤消。',
          'Network':'网络',
          // 'English':'English',
          // 'Korean':'한국인',
          // 'Indonesian':'bahasa Indonesia',
          // 'Malaysian':'Malaysia',
          // 'Vietnamese':'Tiếng Việt',
          'Fill in the form below':'填写下面的表格',
          'Convert': '转变',
          'your commission? Once you submit this transaction it cannot be undone.' : '你的佣金？一旦您提交此交易,就无法撤消。',
          'Convert Commission' : '转换佣金',
          'Agent Information':'代理信息',
          'Player Deposit Information':'玩家存款信息',
          'Fund Transaction Information':'基金交易信息',
          'Transaction Number':'交易号',
          'Transaction Date':'交易日期',
          'Transaction Type':'交易类型',
          'Transaction Amount':'交易金额',
          'Reference Transaction Number':'参考交易编号',
          'Name':'姓名',
          'Number':'数字',
          'Agent':'代理人',
          'Currency':'货币',
          'New Wallet Balance':'新钱包余额',
          'Previous balance':'以前的余额',
          'Memo Type':'备忘录类型',
          'Remarks':'评论',
          'Account Name':'用户名',
          'Channel Name':'频道名称',
          'Account Number':'帐号',
          'Date Created':'创建日期',
          'Add New Payment Channel':'添加新的支付渠道',
          'Commission Ledger List':'佣金分类帐清单',
          'Previous Balance':'以前的余额',
          'Delete':'删除',
          'Bank Name or Payment Channel Name':'银行名称或支付渠道名称',
          'Memo':'备忘录',
          'Txn No.':'Txn 号',
          'Txn Type':'Txn 类型',
          'Txn Date':'交易日期',
          'Credit':'信用',
          'Debit':'借记',
          'CREDIT':'信用',
          'DEBIT':'借方',
          'Admin':'管理员',
          'PENDING':'待定',
          'DEPOSIT':'存款',
          'ADMIN':'管理员',
          'Note':'注意',
          'Fund type':'基金类型',
          'Fund Transaction':'基金交易',
          'ACTIVE':'活跃',
          'INACTIVE':'不活动',
          'Minimum Commission Conversion':'最低佣金转换',
          'Payment Channel Account Name':'支付渠道账户名称',
          'Player Information':'玩家信息',
          'Edit Agent':'编辑代理',
          'Shareable Link':'共享链接',
          'Super Agen':'超级时代',
          'Fund Type':'基金类型',
          'DEBIT':'借方',
          'Wallet Point Ledger Information':'钱包点分类帐信息',
          'Commission Point Ledger Information':'佣金点分类帐信息',
          'Account Expired':'帐户已过期',
          'Account Information':'帐户信息',
          'Account is Inactive, please contact the administrator at':'帐户处于非活动状态，请联系管理员',
          'Account is locked due to multiple invalid attempts, please contact the administrator at':'账户因多次无效尝试被锁定，请联系管理员',
          'Account is locked, please contact the administrator at':'账号被锁定，请联系管理员',
          'Account Locked':'帐户被锁定',
          'Account Name':'用户名',
          'Account Non Expired':'帐户未过期',
          'Account Non Locked':'账户未锁定',
          'Account Number':'帐号',
          'Action':'行动',
          'Activate':'启用',
          'ACTIVE':'积极的',
          'Active Agent':'主动代理',
          'Active Players':'活跃玩家',
          'Add Agent':'添加代理',
          'Add New Agent':'添加新代理',
          'Add New Operator':'添加新操作员',
          'Add New Payment Channel':'添加新的支付渠道',
          'Add New User':'添加新用户',
          'Add Payment Channel':'添加支付渠道',
          'Address':'地址',
          'Address 1':'地址2',
          'Address 2':'地址2',
          'Adjusted':'调整后',
          'Admin':'行政',
          'Admin Credit Memo':'管理员信用凭证',
          'Admin Debit Memo':'管理员借记通知单',
          'ADMIN_CM':'管理员_CM',
          'ADMIN_DM':'ADMIN_DM',
          'Agent':'代理人',
          'Agent Add':'代理添加',
          'Agent addition success!':'代理添加成功！',
          'Agent Code':'代理代码',
          'Agent Code already taken':'代理代码已被占用',
          'Agent Comm. Rate':'代理通讯。速度',
          'Agent Credit Memo':'代理贷项凭证',
          'Agent Debit Memo':'代理借记单',
          'Agent Deposit':'代理押金',
          'Agent edit success!':'代理编辑成功！',
          'Agent Information':'代理信息',
          'Agent List':'代理商名单',
          'Agent Name':'代理名称',
          'Agent Type':'代理类型',
          'Agent View':'代理视图',
          'Agent Withdraw':'代理退出',
          'AGENT_CM':'代理_CM',
          'AGENT_DEPOSIT':'AGENT_DEPOSIT',
          'AGENT_DM':'代理_DM',
          'AGENT_WITHDRAW':'AGENT_WITHDRAW',
          'Agents Add':'代理添加',
          'Agents Edit':'特工 编辑',
          'Agents List':'代理商名单',
          'Agents Tree':'代理树',
          'Agents View':'代理视图',
          'Already have an account?':'已经有一个帐户？',
          'Amount':'数量',
          'API Key':'API 密钥',
          'Application Error':'应用程序错误',
          'Approve':'批准',
          'Approved':'得到正式认可的',
          'Approved Amount':'核准金额',
          'Approved Commission Amount':'批准的佣金金额',
          'Are you sure you want to':'你确定你要',
          'Are you sure you want to <b>Approve</b> this Commission? This action cannot be undone.':'您确定要<b>批准</b>这个委员会吗？此操作无法撤消。',
          'Are you sure you want to delete this record?':'您确定要删除此记录吗？',
          'Back to List':'返回目录',
          'Bank Name or Payment Channel Name':'银行名称或支付渠道名称',
          'Base API Url':'基本 API 网址',
          'BECOME A':'成为一个',
          'Bet Amount':'投注金额',
          'Bet Corner':'下注角',
          'Bet Result':'投注结果',
          'BLOCKED':'已屏蔽',
          'Blocked Agent':'被阻止的代理',
          'Blocked Players':'被阻止的玩家',
          'Cancel':'取消',
          'Cap View':'帽视图',
          'Change Password':'更改密码',
          'Change password success! Use your new password on your next login':'修改密码成功！下次登录时使用新密码',
          'Channel Name':'频道名称',
          'Channel Name (eg. GCash, Paymaya, ect)':'渠道名称（例如 GCash、Paymaya 等）',
          'Clear':'清除',
          'Client Key':'客户端密钥',
          'CNY':'中国新年',
          'Code':'代码',
          'Commission':'委员会',
          'Commission Approval':'委员会批准',
          'Commission Income':'佣金收入',
          'Commission Information':'佣金信息',
          'Commission Ledger':'佣金分类帐',
          'Commission Ledger List':'佣金分类帐清单',
          'Commission Ledger View':'佣金分类帐视图',
          'Commission List':'佣金清单',
          'Commission Point Ledger Information':'佣金点分类帐信息',
          'Commission Points':'佣金积分',
          'Commission Rate':'佣金率',
          'Commission Rate must not be zero':'佣金率不得为零',
          'Commission Rate Setup':'佣金率设置',
          'Commission Rate Setup edit success!':'佣金率设置编辑成功！',
          'Commission Rate View':'佣金率视图',
          'Commission Rates':'佣金率',
          'Commission View':'佣金视图',
          'COMMISSION_APPROVAL':'COMMISSION_APPROVAL',
          'COMMISSION_INCOME':'佣金_收入',
          'Commissions':'佣金',
          'Commissions List':'佣金清单',
          'Commissions View':'佣金视图',
          'Confirm':'确认',
          'Convert':'转变',
          'Convert Commission':'转换佣金',
          'Convert commission success!':'转换佣金成功！',
          'Create Agent':'创建代理',
          'Create an Account':'创建一个帐户',
          'Create your account and become a master agent now.':'创建您的帐户并立即成为主代理。',
          'Created':'已创建',
          'Created By':'由...制作',
          'Created Date':'创建日期',
          'Credential Expired':'凭证已过期',
          'Credential Non Expired':'凭证未过期',
          'Credit':'信用',
          'Credit Limit must not be zero':'信用额度不得为零',
          'Credit Memo':'贷项通知单',
          'Credit Memo List':'贷记凭证清单',
          'CREDIT_MEMO':'信用_备忘录',
          'Currency':'货币',
          'Currency*':'货币*',
          'Dashboard':'仪表盘',
          'Date':'日期',
          'Date Created':'创建日期',
          'Date Modified':'修改日期',
          'Date Registered':'注册日期',
          'Deactivate':'停用',
          'Debit':'借方',
          'Debit Memo':'借方凭证',
          'Debit Memo List':'借记单列表',
          'DEBIT_MEMO':'借方凭证',
          'Delete':'删除',
          'Deposit':'订金',
          'Deposits':'存款',
          'Disapprove':'不赞成',
          'Disapproved':'未获批准',
          'Downlines':'下线',
          'download':'下载',
          'e.g HSBC, WeChat Pay, etc':'例如汇丰银行、微信支付等',
          'Earn 3% of commission in every bets!':'在每次投注中赚取 3% 的佣金！',
          'Edit':'编辑',
          'Edit Agent':'编辑代理',
          'Edit Payment Channel':'编辑支付渠道',
          'Email':'电子邮件',
          'Email already taken':'电子邮件已被占用',
          'Email cannot be empty':'邮箱不能为空',
          'Email is invalid':'电子邮件无效',
          'Email not found':'电子邮件没有找到',
          'Email*':'电子邮件*',
          'Enabled':'启用',
          'End Date':'结束日期',
          // 'English':'英语',
          'Enter your credentials to access your account.':'输入您的凭据以访问您的帐户。',
          'Expired':'已到期',
          'export':'出口',
          'Fill in the form below':'填写下面的表格',
          'Filter':'筛选',
          'First Name':'名',
          'First Name*':'名*',
          'Fund Approval List':'基金审批清单',
          'Fund In':'资金投入',
          'Fund List Approval':'基金名单审批',
          'Fund List Request':'基金清单请求',
          'Fund Request List':'资金申请清单',
          'Fund Transaction':'基金交易',
          'Fund Transaction has been sent! please wait for the approval.':'基金交易已发送！请等待批准。',
          'Fund Transaction Information':'基金交易信息',
          'Fund type':'基金类型',
          'Fund View Approval':'基金查看审批',
          'Fund View Request':'基金查看请求',
          'Funds For Approval':'审批资金',
          'Funds List':'基金清单',
          'Funds Request':'资金申请',
          'Funds View':'资金视图',
          'Game URL':'游戏网址',
          'Go Back':'回去',
          'HOLD':'抓住',
          'Home':'家',
          'IDR':'印尼盾',
          'INACTIVE':'不活动',
          'Indonesian':'印度尼西亚',
          'Insufficient Balance':'余额不足',
          'Insufficient Wallet Commission Balance':'钱包佣金余额不足',
          'Insufficient withdrawal amount':'提款金额不足',
          'Invalid Amount':'金额无效',
          'Invalid credential':'凭证无效',
          'Invalid credential on fourth attempt, account will be locked on 5th try':'第 4 次尝试时凭据无效，第 5 次尝试时帐户将被锁定',
          'Invalid credential on third attempt, account will be locked on 5th try':'第三次尝试时凭据无效，第五次尝试时帐户将被锁定',
          'Invalid credential, please recheck your inputs':'凭据无效，请重新检查您的输入',
          'Invalid credentials, Your account has been locked, please contact the administrator at':'凭据无效，您的帐户已被锁定，请联系管理员',
          'Invalid OTP':'无效的一次性密码',
          'Invalid OTP, please check your input or you may regenerate again':'无效的 OTP，请检查您的输入，否则您可能会重新生成',
          'Invalid Username / Password':'无效的用户名/密码',
          'Invalid username or password, please check your input':'用户名或密码无效，请检查您的输入',
          'Korean':'韩国人',
          'KRW':'韩元',
          'Last Name':'姓',
          'Last Name*':'姓*',
          'Ledgers':'分类帐',
          'Limit':'限制',
          'Lock':'锁',
          'Locked':'锁定',
          'Login':'登录',
          'Malaysian':'马来西亚人',
          'Manage':'管理',
          'Match Code':'匹配码',
          'Memo':'备忘录',
          'Memo Type':'备忘录类型',
          'Middle Name':'中间名字',
          'Minimum Commission Conversion':'最低佣金转换',
          'Mobile':'移动的',
          'Mobile number already taken':'手机号已被占用',
          'Mobile Number not found':'找不到手机号码',
          'Mobile*':'移动的*',
          'Modified By':'修改者',
          'Modified Date':'修改日期',
          'MYR':'马币',
          'Name':'姓名',
          'New Balance':'新百伦',
          'New Password':'新密码',
          'New Wallet Balance':'新钱包余额',
          'No':'不',
          'Not Found':'未找到',
          'Not yet registered?':'还没有注册？',
          'Note':'笔记',
          'Note must not be empty':'备注不能为空',
          'Note*':'笔记*',
          'Note: Each tier total should not be greater than total allowable commission rate (2%) as of December 4, 2020':'注意：截至 2020 年 12 月 4 日，每个层级的总和不应大于允许的总佣金率 (2%)',
          'Notes':'笔记',
          'NTD':'新唐人',
          'Old Password':'旧密码',
          'Open this link':'打开这个链接',
          'Operator':'操作员',
          'Operator Add':'运营商添加',
          'Operator addition success!':'运算符添加成功！',
          'Operator Code':'操作员代码',
          'Operator Information':'运营商信息',
          'Operator List':'操作员列表',
          'Operator modification success!':'操作符修改成功！',
          'Operator Name':'操作员姓名',
          'Operator View':'操作员视图',
          'Operators':'运营商',
          'out of':'在......之外',
          'Page not found':'找不到网页',
          'Parent Agent Code':'父代理代码',
          'Password':'密码',
          'Password*':'密码*',
          'Payee Code':'收款人代码',
          'Payee Type':'收款人类型',
          'Payment Channel Account Name':'支付渠道账户名称',
          'Payment Channel Account Number':'支付渠道账号',
          'Payment Channel Add':'支付渠道添加',
          'Payment Channel Edit':'支付渠道 编辑',
          'Payment Channel edit success!':'支付通道编辑成功！',
          'Payment Channel Information':'支付渠道信息',
          'Payment Channel List':'支付渠道列表',
          'Payment Channel View':'支付渠道视图',
          'Payment Channels':'支付渠道',
          'Payor Code':'付款人代码',
          'Payor Type':'付款人类型',
          'PENDING':'待办的',
          'Pending Agent':'待定代理',
          'Pending Deposits':'待定存款',
          'Pending Players':'待定球员',
          'Pending Withdraws':'待提款',
          'Period':'时期',
          'Plasada Amount':'数量',
          'Player Bets':'玩家投注',
          'Player Credit Memo':'玩家信用凭证',
          'Player Debit Memo':'玩家借记单',
          'Player Deposit':'玩家存款',
          'Player Deposit Information':'玩家存款信息',
          'Player Deposit List':'玩家存款清单',
          'Player Deposit View':'玩家存款视图',
          'Player Information':'玩家信息',
          'Player List':'球员名单',
          'Player No.':'球员编号',
          'Player Number':'玩家编号',
          'Player Username':'玩家用户名',
          'Player View':'播放器视图',
          'Player Withdraw':'玩家退出',
          'Player Withdrawal Information':'玩家提款信息',
          'Player Withdrawal List':'玩家退出列表',
          'Player Withdrawal View':'玩家退出视图',
          'PLAYER_CM':'PLAYER_CM',
          'PLAYER_DEPOSIT':'PLAYER_DEPOSIT',
          'PLAYER_DM':'PLAYER_DM',
          'PLAYER_WITHDRAW':'PLAYER_WITHDRAW',
          'Players':'球员',
          'Players Deposit':'玩家存款',
          'Players List':'球员名单',
          'Players Withdrawals':'玩家提款',
          'Please wait…':'请稍等…',
          'Prev. Balance':'上一页平衡',
          'Previous Balance':'以前的余额',
          'Profile':'轮廓',
          'records':'记录',
          'Reference Transaction Number':'参考交易编号',
          'Register':'登记',
          'Register Now':'现在注册',
          'Registered successfully! We will contact you for your account activation.':'注册成功！我们将与您联系以激活您的帐户。',
          'Registration':'登记',
          'Remarks':'评论',
          'Request Fund Transfer':'请求资金转账',
          'Requested Amount Must Be Greater Than Commission Balance':'要求的金额必须大于佣金余额',
          'Requested amount must be greater than minimum transfer amount':'要求的金额必须大于最低转账金额',
          'Reset':'重启',
          'Reset Password':'重设密码',
          'Reset Password success!':'重置密码成功！',
          'Role':'角色',
          's password?':'密码？',
          'Sabong Network Login':'Sabong 网络登录',
          'Save':'节省',
          'Secret Key':'密钥',
          'Select Country Code':'选择国家代码',
          'Setup':'设置',
          'Share Code Link has been copied to your clipboard!':'共享代码链接已复制到您的剪贴板！',
          'Shareable Link':'可分享的链接',
          'Shop Name is already taken':'店铺名称已被占用',
          'Showing':'显示',
          'Sign Up':'报名',
          'Skype':'Skype',
          'Something went wrong, please contact the administrator at':'出了点问题，请联系管理员',
          'Start Date':'开始日期',
          'Status':'地位',
          'Sub Agent':'子代理',
          'Sub Agent Comm. Rate':'子代理通讯。速度',
          'SUB_AGENT':'子代理',
          'Submit':'提交',
          'Super Admin':'超级管理员',
          'Super Agent':'超级经纪人',
          'Super Agent Comm. Rate':'超级代理通讯。速度',
          'SUPER MASTER AGENT':'超级主代理',
          'SUPER_ADMIN':'超级管理员',
          'SUPER_AGENT':'超级代理',
          'System Information':'系统信息',
          'Telegram':'电报',
          'The :attribute is not valid format and you can only use numeric and (+) plus sign':':attribute 格式无效，您只能使用数字和 (+) 加号',
          'The Commission Rate must be less than the rate assigned to you.':'佣金率必须低于分配给您的费率。',
          'this Agent?':'这个代理？',
          'this Commission? This action cannot be undone.':'这个委员会？此操作无法撤消。',
          'this deposit? This action cannot be undone.':'这个存款？此操作无法撤消。',
          'this Player? This action cannot be undone.':'这个播放器？此操作无法撤消。',
          'this withdrawal? This action cannot be undone.':'这次撤资？此操作无法撤消。',
          'Tier 1 (Single Layer commissioning)':'第 1 层（单层调试）',
          'Tier 2 (Double Layer commissioning)':'Tier 2（双层调试）',
          'Tier 3 (Tri Layer commissioning)':'第 3 层（三层调试）',
          'to':'到',
          'toggle password visibility':'切换密码可见性',
          'Total commission from player will be divided to Super Agent and Agent. Sum should equate to allowable commission of sabong.':'玩家的总佣金将分为超级经纪人和经纪人。总和应等于允许的 sabong 佣金。',
          'Total commission from player will be divided to Super Agent, Agent and Sub Agent. Sum should equate to allowable commission of sabong.':'玩家的总佣金将分为超级经纪人、经纪人和副经纪人。总和应等于允许的 sabong 佣金。',
          'Total Commission from player will directly go to Super Agent alone.':'玩家的总佣金将单独直接转到超级代理。',
          'Total Sum':'总和',
          'Transaction Amount':'交易金额',
          'Transaction Date':'交易日期',
          'Transaction No.':'交易编号',
          'Transaction Number':'交易号',
          'Transaction Type':'交易类型',
          'Transfer From Commission':'从佣金转移',
          'Transfer To Wallet':'转移到钱包',
          'TRANSFER_FROM_COMMISSION':'TRANSFER_FROM_COMMISSION',
          'TRANSFER_TO_WALLET':'TRANSFER_TO_WALLET',
          'Trx No.':'中继编号',
          'Trx Type.':'传输类型。',
          'Txn Date':'交易日期',
          'Txn No.':'交易编号',
          'Txn Type':'交易类型',
          'Type':'类型',
          'Unable to process your request':'无法处理您的请求',
          'Unlock':'开锁',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'当您的代理忘记了他/她的密码时使用此工具。请保存生成的密码。',
          'User':'用户',
          'User Add':'用户添加',
          'User Agent':'用户代理',
          'User Change Password':'用户更改密码',
          'User Information':'用户信息',
          'User List':'用户列表',
          'User Name':'用户名',
          'User Name*':'用户名*',
          'User View':'用户视图',
          'Username':'用户名',
          'Username already taken':'用户名已被使用',
          'Username already used':'用户名已被使用',
          'Username cannot be empty':'用户名不能为空',
          'Username is already taken':'用户名已被占用',
          'Username not found':'未找到用户名',
          'Users':'用户',
          'Verified CS':'经验证的 CS',
          'VerifiedCS':'验证CS',
          'Verify':'核实',
          'Vietnamese':'越南语',
          'VND':'越南盾',
          'Wallet Ledger':'钱包账本',
          'Wallet Ledger View':'钱包分类帐视图',
          'Wallet Point Ledger Information':'钱包点分类帐信息',
          'Wallet Points':'钱包积分',
          'Welcome Back':'欢迎回来',
          'Whatsapp':'应用程式',
          'Withdraw':'提取',
          'Withdrawals':'提款',
          'Yes':'是的',
          'You have insuficient fund, please request for top up first.':'您的资金不足，请先申请充值。',
          'your commission? Once you submit this transaction it cannot be undone.':'你的佣金？一旦您提交此交易，就无法撤消。',
        },
      },
      zhhant: { //chinese traditional
        translations: {
          'Add New Agent':'添加新代理',
          'Commission List':'佣金清单',
          'Enter your credentials to access your account.':'输入您的凭据以访问您的帐户。',
          'username':'用户名',
          'password':'密码',
          'Login':'登录',
          'Not yet registered?':'还没有注册？',
          'Create an Account':'创建一个帐户',
          'Earn 3% of commission in every bets!':'在每次投注中赚取 3% 的佣金！',
          'REGISTER NOW':'现在注册',
          'BECOME A':'成为一个',
          'SUPER MASTER AGENT':'超级主代理',
          // 'English':'英语',
          // 'Indonesian':'印度尼西亚',
          // 'Korean':'韩国人',
          // 'Malaysian':'马来西亚人',
          // 'Vietnamese':'越南语',
          'The username field is required.':'用户名字段是必需的。',
          'The password field is required.':'密码字段是必需的。',
          'Sorry unable to serve your request. Please contact administrator.':'很抱歉无法满足您的要求。请联系管理员。',
          'User Name*':'用户名*',
          'Username*':'用户名*',
          'Password*':'密码*',
          'First Name*':'名*',
          'Firstname*':'名*',
          'Last Name*':'姓*',
          'Lastname*':'姓*',
          'Currency*':'货币*',
          'Email*':'电子邮件*',
          'Mobile*':'移动的*',
          'Telegram':'电报',
          'Skype':'Skype',
          'Whatsapp':'应用程式',
          'Register':'登记',
          'Sabong Network recreational hobby':'Sabong 网络娱乐爱好',
          'Sign Up':'报名',
          'Create your account and become a master agent now.':'创建您的帐户并立即成为主代理。',
          // 'English':'英语',
          'Already have an account?':'已经有一个帐户？',
          'Registered successfully! We will contact you for your account activation.':'注册成功！我们将与您联系以激活您的帐户。',
          'Welcome':'欢迎',
          'Wallet Points':'钱包积分',
          'Commission':'委员会',
          'Commissions':'委员会',
          'Pending Deposits':'待定存款',
          'Pending Withdrawals':'待取款',
          'Active Players':'活跃玩家',
          'Pending Players':'待定球员',
          'Blocked Players':'被阻止的玩家',
          'Active Agent':'主动代理',
          'Pending Agent':'待定代理',
          'Blocked Agent':'被阻止的代理',
          'Profile':'轮廓',
          'Change Password':'更改密码',
          'Logout':'登出',
          'Dashboard':'仪表盘',
          'Players':'球员',
          'Manage':'管理',
          'Deposits':'存款',
          'Withdrawals':'提款',
          'Agent':'代理人',
          'Tree View':'树视图',
          'Cap View':'帽視圖',
          'Funds Request':'资金申请',
          'Funds for Approval':'审批资金',
          'Ledgers':'分类帐',
          'Commission Points':'佣金积分',
          'Payment Channels':'支付渠道',
          'Commission List':'佣金清单',
          'Filter':'筛选',
          'Clear':'清除',
          'Status':'地位',
          'Period':'时期',
          'Agent Code':'代理代码',
          'Amount':'数量',
          'Approved Amount':'核准金额',
          'Start Date':'开始日期',
          'End Date':'结束日期',
          'Showing':'显示',
          'Limit':'限制',
          'export':'出口',
          'Pending':'待办的',
          'Approved':'得到正式认可的',
          'Adjusted':'调整后',
          'Game URL':'游戏网址',
          'Open this link':'打开这个链接',
          'Player Username':'玩家用户名',
          'Player Number':'玩家编号',
          'Player No.':'球员编号',
          'Created Date':'创建日期',
          'Action':'行动',
          'Players List':'球员名单',
          'Players List':'球员名单',
          'Players Deposit':'玩家存款',
          'Player Deposits':'玩家存款',
          'Player Deposit':'玩家存款',
          'Disapproved':'未获批准',
          'Transaction No.':'交易编号',
          'Players Withdrawals':'玩家提款',
          'Player Withdrawals':'玩家提款',
          'Active':'积极的',
          'Inactive':'不活动',
          'Hold':'抓住',
          'Blocked':'被封锁',
          'Credit Limit':'信用额度',
          'Commission Rate':'佣金率',
          'Agent Type':'代理类型',
          'Parent Agent Code':'父代理代码',
          'Date Registered':'注册日期',
          'Add New Agent':'添加新代理',
          'Super Agent':'超级经纪人',
          'Request Fund Transfer':'请求资金转账',
          'Fund Request List':'资金申请清单',
          'Payee Code':'收款人代码',
          'Payor Code':'付款人代码',
          'Type':'类型',
          'Deposit':'订金',
          'Withdraw':'提取',
          'Payor Type':'付款人类型',
          'Fund Approval List':'基金审批清单',
          'Wallet Ledger':'钱包账本',
          'Memo Type':'备忘录类型',
          'Debit':'借方',
          'Credit':'信用',
          'New Balance':'新百伦',
          'Prev. Balance':'上一页平衡',
          'Player Withdraw':'玩家退出',
          'Player Withdrawal':'玩家退出',
          'Player Credit Memo':'玩家信用凭证',
          'Player Debit Memo':'玩家借记单',
          'Agent Deposit':'代理押金',
          'Agent Withdraw':'代理退出',
          'Agent Withdrawal':'代理退出',
          'Agent Credit Memo':'代理贷项凭证',
          'Agent Debit Memo':'代理借记单',
          'Admin Credit Memo':'管理员信用凭证',
          'Admin Debit Memo':'管理员借记通知单',
          'Commission Income':'佣金收入',
          'Commission Approval':'委员会批准',
          'Credit Memo':'贷项通知单',
          'Debit Memo':'借方凭证',
          'Commission Ledger List':'佣金分类帐清单',
          'Address 1':'地址1',
          'Address 2':'地址2',
          'Credit Limit':'信用额度',
          'Commission Rate':'佣金率',
          'Cancel':'取消',
          'Save':'节省',
          'Remarks':'评论',
          'Add Payment Channel':'添加支付渠道',
          'Bank Name or Payment Channel Name (e.g HSBC WeChat Pay etc)':'银行名称或支付渠道名称（例如汇丰微信支付等）',
          'Account Name':'用户名',
          'Account Number':'帐号',
          'Channel Name':'频道名称',
          'Date Created':'创建日期',
          'Welcome Back':'歡迎回來',
          'System Information' : '系統信息',
          'Register Now': '現在註冊',
          'Pending Withdraws': '待取款',
          'Please wait...': '请稍等...',
          'Approved': '得到正式認可的',
          'APPROVED': '得到正式認可的',
          'Account Information':'帳戶信息',
          'Middle Name':'中间名字',
          'Email':'电子邮件',
          'Currency':'货币',
          'Account Expired':'帐户已过期',
          'Credential Expired':'凭证已过期',
          'Enabled':'启用',
          'Account Locked':'帐户被锁定',
          'First Name':'名',
          'Last Name':'姓',
          'Mobile':'移动的',
          'Created By':'由...制作',
          'Modified By':'修改者',
          'Modified Date':'修改日期',
          'Lock':'锁',
          'Agent':'代理人',
          'Code':'代码',
          'Mobile':'移动的',
          'First Name':'名',
          'Last Name':'姓',
          'Deactivate':'停用',
          'Activate':'启用',
          'Inactive':'不活动',
          'Active':'积极的',
          'Commission Ledger':'佣金分类帐',
          'Commission':'委员会',
          'Downlines':'下线',
          'Back to List':'返回目录',
          'Reset Password':'重设密码',
          'Edit':'编辑',
          'Username':'用户名',
          'Reset Password':'重设密码',
          'Cancel':'取消',
          'Submit':'提交',
          'Payee Type':'收款人类型',
          'Trx Type.':'传输类型。',
          'Trx No.':'中继编号',
          'Created':'已创建',
          'Verified CS':'经验证的 CS',
          'Locked':'锁定',
          'Verifiedcs':'已验证',
          'New Password':'新密码',
          'out of':'在......之外',
          'to':'到',
          'Change Paswword':'更改密码',
          'Logout':'登出',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'当您的代理忘记了他/她的密码时使用此工具。请保存生成的密码。',
          'Delete Record':'删除记录',
          'Are you sure you want to delete this record?':'您确定要删除此记录吗？',
          'Yes':'是的',
          'No':'不',
          'Select Country Code':'选择国家代码',
          'this Agent?':'这个代理？',
          'Confirm':'确认',
          'Are you sure you want to':'你确定你要',
          'Approve':'批准',
          'this Commission? This action cannot be undone.':'这个委员会？此操作无法撤消。',
          'this Player? This action cannot be undone.':'这个播放器？此操作无法撤消。',
          'Verify':'核实',
          'this deposit? This action cannot be undone.':'这个存款？此操作无法撤消。',
          'this withdrawal? This action cannot be undone.':'这次撤资？此操作无法撤消。',
          'Network':'網絡',
          // 'English':'English',
          // 'Korean':'한국인',
          // 'Indonesian':'bahasa Indonesia',
          // 'Malaysian':'Malaysia',
          // 'Vietnamese':'Tiếng Việt',
          'Fill in the form below':'填寫下面的表格',
          'Convert': '轉變',
          'your commission? Once you submit this transaction it cannot be undone.' : '你的佣金？一旦您提交此交易,就無法撤消。',
          'Convert Commission' : '轉換佣金',
          'Agent Information':'代理信息',
          'Player Deposit Information':'玩家存款信息',
          'Fund Transaction Information':'基金交易信息',
          'Transaction Number':'交易號',
          'Transaction Date':'交易日期',
          'Transaction Type':'交易類型',
          'Transaction Amount':'交易金額',
          'Reference Transaction Number':'參考交易編號',
          'Name':'姓名',
          'Number':'數字',
          'Agent':'代理人',
          'Currency':'貨幣',
          'New Wallet Balance':'新錢包餘額',
          'Previous balance':'以前的餘額',
          'Memo Type':'備忘錄類型',
          'Remarks':'評論',
          'Account Name':'用戶名',
          'Channel Name':'頻道名稱',
          'Account Number':'帳號',
          'Date Created':'創建日期',
          'Add New Payment Channel':'添加新的支付渠道',
          'Commission Ledger List':'佣金分類帳清單',
          'Previous Balance':'以前的餘額',
          'Delete':'刪除',
          'Bank Name or Payment Channel Name':'銀行名稱或支付渠道名稱',
          'Memo':	'備忘錄',
          'Txn No.':'Txn 號',
          'Txn Type':'Txn 類型',
          'Txn Date':'交易日期',
          'Credit':'信用',
          'Debit':'借記',
          'CREDIT':'信用',
          'DEBIT':'借方',
          'Admin':'管理員',
          'PENDING':'待定',
          'DEPOSIT':'存款',
          'ADMIN':'管理員',
          'Note':'注意',
          'Fund type':'基金類型',
          'Fund Transaction':'基金交易',
          'ACTIVE':'活躍',
          'INACTIVE':'不活動',
          'Minimum Commission Conversion':'最低佣金轉換',
          'Payment Channel Account Name':'支付渠道賬戶名稱',
          'Player Information':'玩家信息',
          'Edit Agent':'編輯代理',
          'Shareable Link':'共享鏈接',
          'Super Agen':'超級時代',
          'Fund Type':'基金類型',
          'DEBIT':'借方',
          'Wallet Point Ledger Information':'錢包點分類帳信息',
          'Commission Point Ledger Information':'佣金點分類帳信息',
          'Account Expired':'帐户已过期',
          'Account Information':'帐户信息',
          'Account is Inactive, please contact the administrator at':'帐户处于非活动状态，请联系管理员',
          'Account is locked due to multiple invalid attempts, please contact the administrator at':'账户因多次无效尝试被锁定，请联系管理员',
          'Account is locked, please contact the administrator at':'账号被锁定，请联系管理员',
          'Account Locked':'帐户被锁定',
          'Account Name':'用户名',
          'Account Non Expired':'帐户未过期',
          'Account Non Locked':'账户未锁定',
          'Account Number':'帐号',
          'Action':'行动',
          'Activate':'启用',
          'ACTIVE':'积极的',
          'Active Agent':'主动代理',
          'Active Players':'活跃玩家',
          'Add Agent':'添加代理',
          'Add New Agent':'添加新代理',
          'Add New Operator':'添加新操作员',
          'Add New Payment Channel':'添加新的支付渠道',
          'Add New User':'添加新用户',
          'Add Payment Channel':'添加支付渠道',
          'Address':'地址',
          'Address 1':' 地址 2',
          'Address 2':'地址2',
          'Adjusted':'调整后',
          'Admin':'行政',
          'Admin Credit Memo':'管理员信用凭证',
          'Admin Debit Memo':'管理员借记通知单',
          'ADMIN_CM':'管理员_CM',
          'ADMIN_DM':'ADMIN_DM',
          'Agent':'代理人',
          'Agent Add':'代理添加',
          'Agent addition success!':'代理添加成功！',
          'Agent Code':'代理代码',
          'Agent Code already taken':'代理代码已被占用',
          'Agent Comm. Rate':'代理通讯。速度',
          'Agent Credit Memo':'代理贷项凭证',
          'Agent Debit Memo':'代理借记单',
          'Agent Deposit':'代理押金',
          'Agent edit success!':'代理编辑成功！',
          'Agent Information':'代理信息',
          'Agent List':'代理商名单',
          'Agent Name':'代理名称',
          'Agent Type':'代理类型',
          'Agent View':'代理视图',
          'Agent Withdraw':'代理退出',
          'AGENT_CM':'代理_CM',
          'AGENT_DEPOSIT':'AGENT_DEPOSIT',
          'AGENT_DM':'代理_DM',
          'AGENT_WITHDRAW':'AGENT_WITHDRAW',
          'Agents Add':'代理添加',
          'Agents Edit':'特工 编辑',
          'Agents List':'代理商名单',
          'Agents Tree':'代理树',
          'Agents View':'代理视图',
          'Already have an account?':'已经有一个帐户？',
          'Amount':'数量',
          'API Key':'API 密钥',
          'Application Error':'应用程序错误',
          'Approve':'批准',
          'Approved':'得到正式认可的',
          'Approved Amount':'核准金额',
          'Approved Commission Amount':'批准的佣金金额',
          'Are you sure you want to':'你确定你要',
          'Are you sure you want to <b>Approve</b> this Commission? This action cannot be undone.':'您确定要<b>批准</b>这个委员会吗？此操作无法撤消。',
          'Are you sure you want to delete this record?':'您确定要删除此记录吗？',
          'Back to List':'返回目录',
          'Bank Name or Payment Channel Name':'银行名称或支付渠道名称',
          'Base API Url':'基本 API 网址',
          'BECOME A':'成为一个',
          'Bet Amount':'投注金额',
          'Bet Corner':'下注角',
          'Bet Result':'投注结果',
          'BLOCKED':'已屏蔽',
          'Blocked Agent':'被阻止的代理',
          'Blocked Players':'被阻止的玩家',
          'Cancel':'取消',
          'Cap View':'帽视图',
          'Change Password':'更改密码',
          'Change password success! Use your new password on your next login':'修改密码成功！下次登录时使用新密码',
          'Channel Name':'频道名称',
          'Channel Name (eg. GCash, Paymaya, ect)':'渠道名称（例如 GCash、Paymaya 等）',
          'Clear':'清除',
          'Client Key':'客户端密钥',
          'CNY':'中国新年',
          'Code':'代码',
          'Commission':'委员会',
          'Commission Approval':'委员会批准',
          'Commission Income':'佣金收入',
          'Commission Information':'佣金信息',
          'Commission Ledger':'佣金分类帐',
          'Commission Ledger List':'佣金分类帐清单',
          'Commission Ledger View':'佣金分类帐视图',
          'Commission List':'佣金清单',
          'Commission Point Ledger Information':'佣金点分类帐信息',
          'Commission Points':'佣金积分',
          'Commission Rate':'佣金率',
          'Commission Rate must not be zero':'佣金率不得为零',
          'Commission Rate Setup':'佣金率设置',
          'Commission Rate Setup edit success!':'佣金率设置编辑成功！',
          'Commission Rate View':'佣金率视图',
          'Commission Rates':'佣金率',
          'Commission View':'佣金视图',
          'COMMISSION_APPROVAL':'COMMISSION_APPROVAL',
          'COMMISSION_INCOME':'佣金_收入',
          'Commissions':'佣金',
          'Commissions List':'佣金清单',
          'Commissions View':'佣金视图',
          'Confirm':'确认',
          'Convert':'转变',
          'Convert Commission':'转换佣金',
          'Convert commission success!':'转换佣金成功！',
          'Create Agent':'创建代理',
          'Create an Account':'创建一个帐户',
          'Create your account and become a master agent now.':'创建您的帐户并立即成为主代理。',
          'Created':'已创建',
          'Created By':'由...制作',
          'Created Date':'创建日期',
          'Credential Expired':'凭证已过期',
          'Credential Non Expired':'凭证未过期',
          'Credit':'信用',
          'Credit Limit must not be zero':'信用额度不得为零',
          'Credit Memo':'贷项通知单',
          'Credit Memo List':'贷记凭证清单',
          'CREDIT_MEMO':'信用_备忘录',
          'Currency':'货币',
          'Currency*':'货币*',
          'Dashboard':'仪表盘',
          'Date':'日期',
          'Date Created':'创建日期',
          'Date Modified':'修改日期',
          'Date Registered':'注册日期',
          'Deactivate':'停用',
          'Debit':'借方',
          'Debit Memo':'借方凭证',
          'Debit Memo List':'借记单列表',
          'DEBIT_MEMO':'借方凭证',
          'Delete':'删除',
          'Deposit':'订金',
          'Deposits':'存款',
          'Disapprove':'不赞成',
          'Disapproved':'未获批准',
          'Downlines':'下线',
          'download':'下载',
          'e.g HSBC, WeChat Pay, etc':'例如汇丰银行、微信支付等',
          'Earn 3% of commission in every bets!':'在每次投注中赚取 3% 的佣金！',
          'Edit':'编辑',
          'Edit Agent':'编辑代理',
          'Edit Payment Channel':'编辑支付渠道',
          'Email':'电子邮件',
          'Email already taken':'电子邮件已被占用',
          'Email cannot be empty':'邮箱不能为空',
          'Email is invalid':'电子邮件无效',
          'Email not found':'电子邮件没有找到',
          'Email*':'电子邮件*',
          'Enabled':'启用',
          'End Date':'结束日期',
          // 'English':'英语',
          'Enter your credentials to access your account.':'输入您的凭据以访问您的帐户。',
          'Expired':'已到期',
          'export':'出口',
          'Fill in the form below':'填写下面的表格',
          'Filter':'筛选',
          'First Name':'名',
          'First Name*':'名*',
          'Fund Approval List':'基金审批清单',
          'Fund In':'资金投入',
          'Fund List Approval':'基金名单审批',
          'Fund List Request':'基金清单请求',
          'Fund Request List':'资金申请清单',
          'Fund Transaction':'基金交易',
          'Fund Transaction has been sent! please wait for the approval.':'基金交易已发送！请等待批准。',
          'Fund Transaction Information':'基金交易信息',
          'Fund type':'基金类型',
          'Fund View Approval':'基金查看审批',
          'Fund View Request':'基金查看请求',
          'Funds For Approval':'审批资金',
          'Funds List':'基金清单',
          'Funds Request':'资金申请',
          'Funds View':'资金视图',
          'Game URL':'游戏网址',
          'Go Back':'回去',
          'HOLD':'抓住',
          'Home':'家',
          'IDR':'印尼盾',
          'INACTIVE':'不活动',
          'Indonesian':'印度尼西亚',
          'Insufficient Balance':'余额不足',
          'Insufficient Wallet Commission Balance':'钱包佣金余额不足',
          'Insufficient withdrawal amount':'提款金额不足',
          'Invalid Amount':'金额无效',
          'Invalid credential':'凭证无效',
          'Invalid credential on fourth attempt, account will be locked on 5th try':'第 4 次尝试时凭据无效，第 5 次尝试时帐户将被锁定',
          'Invalid credential on third attempt, account will be locked on 5th try':'第三次尝试时凭据无效，第五次尝试时帐户将被锁定',
          'Invalid credential, please recheck your inputs':'凭据无效，请重新检查您的输入',
          'Invalid credentials, Your account has been locked, please contact the administrator at':'凭据无效，您的帐户已被锁定，请联系管理员',
          'Invalid OTP':'无效的一次性密码',
          'Invalid OTP, please check your input or you may regenerate again':'无效的 OTP，请检查您的输入，否则您可能会重新生成',
          'Invalid Username / Password':'无效的用户名/密码',
          'Invalid username or password, please check your input':'用户名或密码无效，请检查您的输入',
          'Korean':'韩国人',
          'KRW':'韩元',
          'Last Name':'姓',
          'Last Name*':'姓*',
          'Ledgers':'分类帐',
          'Limit':'限制',
          'Lock':'锁',
          'Locked':'锁定',
          'Login':'登录',
          'Malaysian':'马来西亚人',
          'Manage':'管理',
          'Match Code':'匹配码',
          'Memo':'备忘录',
          'Memo Type':'备忘录类型',
          'Middle Name':'中间名字',
          'Minimum Commission Conversion':'最低佣金转换',
          'Mobile':'移动的',
          'Mobile number already taken':'手机号已被占用',
          'Mobile Number not found':'找不到手机号码',
          'Mobile*':'移动的*',
          'Modified By':'修改者',
          'Modified Date':'修改日期',
          'MYR':'马币',
          'Name':'姓名',
          'New Balance':'新百伦',
          'New Password':'新密码',
          'New Wallet Balance':'新钱包余额',
          'No':'不',
          'Not Found':'未找到',
          'Not yet registered?':'还没有注册？',
          'Note':'笔记',
          'Note must not be empty':'备注不能为空',
          'Note*':'笔记*',
          'Note: Each tier total should not be greater than total allowable commission rate (2%) as of December 4, 2020':'注意：截至 2020 年 12 月 4 日，每个层级的总和不应大于允许的总佣金率 (2%)',
          'Notes':'笔记',
          'NTD':'新唐人',
          'Old Password':'旧密码',
          'Open this link':'打开这个链接',
          'Operator':'操作员',
          'Operator Add':'运营商添加',
          'Operator addition success!':'运算符添加成功！',
          'Operator Code':'操作员代码',
          'Operator Information':'运营商信息',
          'Operator List':'操作员列表',
          'Operator modification success!':'操作符修改成功！',
          'Operator Name':'操作员姓名',
          'Operator View':'操作员视图',
          'Operators':'运营商',
          'out of':'在......之外',
          'Page not found':'找不到网页',
          'Parent Agent Code':'父代理代码',
          'Password':'密码',
          'Password*':'密码*',
          'Payee Code':'收款人代码',
          'Payee Type':'收款人类型',
          'Payment Channel Account Name':'支付渠道账户名称',
          'Payment Channel Account Number':'支付渠道账号',
          'Payment Channel Add':'支付渠道添加',
          'Payment Channel Edit':'支付渠道 编辑',
          'Payment Channel edit success!':'支付通道编辑成功！',
          'Payment Channel Information':'支付渠道信息',
          'Payment Channel List':'支付渠道列表',
          'Payment Channel View':'支付渠道视图',
          'Payment Channels':'支付渠道',
          'Payor Code':'付款人代码',
          'Payor Type':'付款人类型',
          'PENDING':'待办的',
          'Pending Agent':'待定代理',
          'Pending Deposits':'待定存款',
          'Pending Players':'待定球员',
          'Pending Withdraws':'待提款',
          'Period':'时期',
          'Plasada Amount':'数量',
          'Player Bets':'玩家投注',
          'Player Credit Memo':'玩家信用凭证',
          'Player Debit Memo':'玩家借记单',
          'Player Deposit':'玩家存款',
          'Player Deposit Information':'玩家存款信息',
          'Player Deposit List':'玩家存款清单',
          'Player Deposit View':'玩家存款视图',
          'Player Information':'玩家信息',
          'Player List':'球员名单',
          'Player No.':'球员编号',
          'Player Number':'玩家编号',
          'Player Username':'玩家用户名',
          'Player View':'播放器视图',
          'Player Withdraw':'玩家退出',
          'Player Withdrawal Information':'玩家提款信息',
          'Player Withdrawal List':'玩家退出列表',
          'Player Withdrawal View':'玩家退出视图',
          'PLAYER_CM':'PLAYER_CM',
          'PLAYER_DEPOSIT':'PLAYER_DEPOSIT',
          'PLAYER_DM':'PLAYER_DM',
          'PLAYER_WITHDRAW':'PLAYER_WITHDRAW',
          'Players':'球员',
          'Players Deposit':'玩家存款',
          'Players List':'球员名单',
          'Players Withdrawals':'玩家提款',
          'Please wait…':'请稍等…',
          'Prev. Balance':'上一页平衡',
          'Previous Balance':'以前的余额',
          'Profile':'轮廓',
          'records':'记录',
          'Reference Transaction Number':'参考交易编号',
          'Register':'登记',
          'Register Now':'现在注册',
          'Registered successfully! We will contact you for your account activation.':'注册成功！我们将与您联系以激活您的帐户。',
          'Registration':'登记',
          'Remarks':'评论',
          'Request Fund Transfer':'请求资金转账',
          'Requested Amount Must Be Greater Than Commission Balance':'要求的金额必须大于佣金余额',
          'Requested amount must be greater than minimum transfer amount':'要求的金额必须大于最低转账金额',
          'Reset':'重启',
          'Reset Password':'重设密码',
          'Reset Password success!':'重置密码成功！',
          'Role':'角色',
          's password?':'密码？',
          'Sabong Network Login':'Sabong 网络登录',
          'Save':'节省',
          'Secret Key':'密钥',
          'Select Country Code':'选择国家代码',
          'Setup':'设置',
          'Share Code Link has been copied to your clipboard!':'共享代码链接已复制到您的剪贴板！',
          'Shareable Link':'可分享的链接',
          'Shop Name is already taken':'店铺名称已被占用',
          'Showing':'显示',
          'Sign Up':'报名',
          'Skype':'Skype',
          'Something went wrong, please contact the administrator at':'出了点问题，请联系管理员',
          'Start Date':'开始日期',
          'Status':'地位',
          'Sub Agent':'子代理',
          'Sub Agent Comm. Rate':'子代理通讯。速度',
          'SUB_AGENT':'子代理',
          'Submit':'提交',
          'Super Admin':'超级管理员',
          'Super Agent':'超级经纪人',
          'Super Agent Comm. Rate':'超级代理通讯。速度',
          'SUPER MASTER AGENT':'超级主代理',
          'SUPER_ADMIN':'超级管理员',
          'SUPER_AGENT':'超级代理',
          'System Information':'系统信息',
          'Telegram':'电报',
          'The :attribute is not valid format and you can only use numeric and (+) plus sign':':attribute 格式无效，您只能使用数字和 (+) 加号',
          'The Commission Rate must be less than the rate assigned to you.':'佣金率必须低于分配给您的费率。',
          'this Agent?':'这个代理？',
          'this Commission? This action cannot be undone.':'这个委员会？此操作无法撤消。',
          'this deposit? This action cannot be undone.':'这个存款？此操作无法撤消。',
          'this Player? This action cannot be undone.':'这个播放器？此操作无法撤消。',
          'this withdrawal? This action cannot be undone.':'这次撤资？此操作无法撤消。',
          'Tier 1 (Single Layer commissioning)':'第 1 层（单层调试）',
          'Tier 2 (Double Layer commissioning)':'Tier 2（双层调试）',
          'Tier 3 (Tri Layer commissioning)':'第 3 层（三层调试）',
          'to':'到',
          'toggle password visibility':'切换密码可见性',
          'Total commission from player will be divided to Super Agent and Agent. Sum should equate to allowable commission of sabong.':'玩家的总佣金将分为超级经纪人和经纪人。总和应等于允许的 sabong 佣金。',
          'Total commission from player will be divided to Super Agent, Agent and Sub Agent. Sum should equate to allowable commission of sabong.':'玩家的总佣金将分为超级经纪人、经纪人和副经纪人。总和应等于允许的 sabong 佣金。',
          'Total Commission from player will directly go to Super Agent alone.':'玩家的总佣金将单独直接转到超级代理。',
          'Total Sum':'总和',
          'Transaction Amount':'交易金额',
          'Transaction Date':'交易日期',
          'Transaction No.':'交易编号',
          'Transaction Number':'交易号',
          'Transaction Type':'交易类型',
          'Transfer From Commission':'从佣金转移',
          'Transfer To Wallet':'转移到钱包',
          'TRANSFER_FROM_COMMISSION':'TRANSFER_FROM_COMMISSION',
          'TRANSFER_TO_WALLET':'TRANSFER_TO_WALLET',
          'Trx No.':'中继编号',
          'Trx Type.':'传输类型。',
          'Txn Date':'交易日期',
          'Txn No.':'交易编号',
          'Txn Type':'交易类型',
          'Type':'类型',
          'Unable to process your request':'无法处理您的请求',
          'Unlock':'开锁',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'当您的代理忘记了他/她的密码时使用此工具。请保存生成的密码。',
          'User':'用户',
          'User Add':'用户添加',
          'User Agent':'用户代理',
          'User Change Password':'用户更改密码',
          'User Information':'用户信息',
          'User List':'用户列表',
          'User Name':'用户名',
          'User Name*':'用户名*',
          'User View':'用户视图',
          'Username':'用户名',
          'Username already taken':'用户名已被使用',
          'Username already used':'用户名已被使用',
          'Username cannot be empty':'用户名不能为空',
          'Username is already taken':'用户名已被占用',
          'Username not found':'未找到用户名',
          'Users':'用户',
          'Verified CS':'经验证的 CS',
          'VerifiedCS':'验证CS',
          'Verify':'核实',
          'Vietnamese':'越南语',
          'VND':'越南盾',
          'Wallet Ledger':'钱包账本',
          'Wallet Ledger View':'钱包分类帐视图',
          'Wallet Point Ledger Information':'钱包点分类帐信息',
          'Wallet Points':'钱包积分',
          'Welcome Back':'欢迎回来',
          'Whatsapp':'应用程式',
          'Withdraw':'提取',
          'Withdrawals':'提款',
          'Yes':'是的',
          'You have insuficient fund, please request for top up first.':'您的资金不足，请先申请充值。',
          'your commission? Once you submit this transaction it cannot be undone.':'你的佣金？一旦您提交此交易，就无法撤消。',
        },
      },
      my: { //Malay
        translations: {
          'Add New Agent':'Tambah Ejen Baharu',
          'Commission List':'Senarai Komisen',
          'Enter your credentials to access your account.':'Masukkan kelayakan anda untuk mengakses akaun anda.',
          'username':'nama pengguna',
          'password':'kata laluan',
          'Login':'Log masuk',
          'Not yet registered?':'Belum berdaftar?',
          'Create an Account':'Buat Akaun',
          'Earn 3% of commission in every bets!':'Dapatkan 3% komisen dalam setiap pertaruhan!',
          'REGISTER NOW':'DAFTAR SEKARANG',
          'BECOME A':'MENJADI SEORANG',
          'SUPER MASTER AGENT':'EJEN SUPER MASTER',
          // 'English':'Inggeris',
          // 'Indonesian':'Indonesia',
          // 'Korean':'bahasa korea',
          // 'Malaysian':'Malaysia',
          // 'Vietnamese':'bahasa Vietnam',
          'The username field is required.':'Medan nama pengguna diperlukan.',
          'The password field is required.':'Medan kata laluan diperlukan.',
          'Sorry unable to serve your request. Please contact administrator.':'Maaf tidak dapat memenuhi permintaan anda. Sila hubungi pentadbir.',
          'User Name*':'Nama Pengguna*',
          'Username*':'Nama pengguna*',
          'Password*':'Kata laluan*',
          'First Name*':'Nama pertama*',
          'Firstname*':'Nama pertama*',
          'Last Name*':'Nama terakhir*',
          'Lastname*':'Nama terakhir*',
          'Currency*':'mata wang*',
          'Email*':'E-mel*',
          'Mobile*':'Mudah Alih*',
          'Telegram':'Telegram',
          'Skype':'Skype',
          'Whatsapp':'Whatsapp',
          'Register':'Daftar',
          'Sabong Network recreational hobby':'Hobi rekreasi Sabong Network',
          'Sign Up':'Daftar',
          'Create your account and become a master agent now.':'Buat akaun anda dan jadilah ejen induk sekarang.',
          // 'English':'Inggeris',
          'Already have an account?':'Sudah mempunyai akaun?',
          'Registered successfully! We will contact you for your account activation.':'Berjaya mendaftar! Kami akan menghubungi anda untuk pengaktifan akaun anda.',
          'Welcome':'selamat datang',
          'Wallet Points':'Mata Dompet',
          'Commission':'Suruhanjaya',
          'Commissions':'Suruhanjaya',
          'Pending Deposits':'Deposit Tertunda',
          'Pending Withdrawals':'Pengeluaran Menunggu',
          'Active Players':'Pemain Aktif',
          'Pending Players':'Pemain menunggu',
          'Blocked Players':'Pemain yang disekat',
          'Active Agent':'Agen Aktif',
          'Pending Agent':'Ejen menunggu',
          'Blocked Agent':'Ejen yang disekat',
          'Profile':'Profil',
          'Change Password':'Tukar kata laluan',
          'Logout':'Log keluar',
          'Dashboard':'Papan pemuka',
          'Players':'Pemain',
          'Manage':'Mengurus',
          'Deposits':'Deposit',
          'Withdrawals':'Pengeluaran',
          'Agent':'Ejen',
          'Tree View':'Pemandangan Pokok',
          'Cap View':'Cap View',
          'Funds Request':'Permintaan Dana',
          'Funds for Approval':'Dana untuk Kelulusan',
          'Ledgers':'Lejar',
          'Commission Points':'Mata Komisen',
          'Payment Channels':'Saluran Pembayaran',
          'Commission List':'Senarai Komisen',
          'Filter':'Penapis',
          'Clear':'Jelas',
          'Status':'Status',
          'Period':'Tempoh',
          'Agent Code':'Kod Ejen',
          'Amount':'Jumlah',
          'Approved Amount':'Jumlah yang Diluluskan',
          'Start Date':'Tarikh mula',
          'End Date':'Tarikh tamat',
          'Showing':'Menunjukkan',
          'Limit':'Had',
          'export':'eksport',
          'Pending':'Yang belum selesai',
          'Approved':'Diluluskan',
          'Adjusted':'Dilaraskan',
          'Game URL':'URL permainan',
          'Open this link':'Buka pautan ini',
          'Player Username':'Nama Pengguna Pemain',
          'Player Number':'Nombor Pemain',
          'Player No.':'Pemain No.',
          'Created Date':'Tarikh Dicipta',
          'Action':'Tindakan',
          'Players List':'Senarai Pemain',
          'Players List':'Senarai Pemain',
          'Players Deposit':'Deposit Pemain',
          'Player Deposits':'Deposit Pemain',
          'Player Deposit':'Deposit Pemain',
          'Disapproved':'Tidak diluluskan',
          'Transaction No.':'Transaksi No.',
          'Players Withdrawals':'Pengeluaran Pemain',
          'Player Withdrawals':'Pengeluaran Pemain',
          'Active':'Aktif',
          'Inactive':'Tidak aktif',
          'Hold':'tahan',
          'Blocked':'Disekat',
          'Credit Limit':'Had kredit',
          'Commission Rate':'Kadar Komisen',
          'Agent Type':'Jenis Ejen',
          'Parent Agent Code':'Kod Ejen Ibu Bapa',
          'Date Registered':'Tarikh Didaftar',
          'Add New Agent':'Tambah Ejen Baharu',
          'Super Agent':'Ejen Super',
          'Request Fund Transfer':'Minta Pindahan Dana',
          'Fund Request List':'Senarai Permintaan Dana',
          'Payee Code':'Kod Penerima',
          'Payor Code':'Kod Pembayar',
          'Type':'taip',
          'Deposit':'Deposit',
          'Withdraw':'tarik diri',
          'Payor Type':'Jenis Pembayar',
          'Fund Approval List':'Senarai Kelulusan Dana',
          'Wallet Ledger':'Lejar Dompet',
          'Memo Type':'Jenis Memo',
          'Debit':'Debit',
          'Credit':'Kredit',
          'New Balance':'Keseimbangan baru',
          'Prev. Balance':'Sebelum Seimbang',
          'Player Withdraw':'Penarikan Diri Pemain',
          'Player Withdrawal':'Penarikan Diri Pemain',
          'Player Credit Memo':'Memo Kredit Pemain',
          'Player Debit Memo':'Memo Debit Pemain',
          'Agent Deposit':'Deposit Agen',
          'Agent Withdraw':'Penarikan Agen',
          'Agent Withdrawal':'Pengeluaran Agen',
          'Agent Credit Memo':'Memo Kredit Ejen',
          'Agent Debit Memo':'Memo Debit Ejen',
          'Admin Credit Memo':'Memo Kredit Admin',
          'Admin Debit Memo':'Memo Debit Pentadbir',
          'Commission Income':'Pendapatan Komisen',
          'Commission Approval':'Kelulusan Komisen',
          'Credit Memo':'Memo kredit',
          'Debit Memo':'Memo Debit',
          'Commission Ledger List':'Senarai Lejar Komisen',
          'Address 1':'alamat 1',
          'Address 2':'Alamat 2',
          'Credit Limit':'Had kredit',
          'Commission Rate':'Kadar Komisen',
          'Cancel':'Batal',
          'Save':'Jimat',
          'Remarks':'Kenyataan',
          'Add Payment Channel':'Tambah Saluran Pembayaran',
          'Bank Name or Payment Channel Name (e.g HSBC WeChat Pay etc)':'Nama Bank atau Nama Saluran Pembayaran (cth. HSBC WeChat Pay dll)',
          'Account Name':'Nama akaun',
          'Account Number':'Nombor akaun',
          'Channel Name':'Nama Saluran',
          'Date Created':'Tarikh Dibuat',
          'Welcome Back':'Selamat kembali',
          'System Information' : 'Maklumat sistem',
          'Register Now':'Daftar sekarang',
          'Pending Withdraws': 'Pengeluaran Menunggu',
          'Please wait...': 'Sila tunggu...',
          'Approved': 'Diluluskan',
          'APPROVED': 'Diluluskan',
          'Account Information':'Maklumat Akaun',
          'Middle Name':'Nama tengah',
          'Email':'E-mel',
          'Currency':'mata wang',
          'Account Expired':'Akaun Tamat Tempoh',
          'Credential Expired':'Tauliah Tamat Tempoh',
          'Enabled':'Didayakan',
          'Account Locked':'Akaun dikunci',
          'First Name':'Nama pertama',
          'Last Name':'Nama terakhir',
          'Mobile':'mudah alih',
          'Created By':'Dicipta oleh',
          'Modified By':'Diubahsuai Oleh',
          'Modified Date':'Tarikh Diubah Suai',
          'Lock':'Kunci',
          'Agent':'Ejen',
          'Code':'Kod',
          'Mobile':'mudah alih',
          'First Name':'Nama pertama',
          'Last Name':'Nama terakhir',
          'Deactivate':'Nyahaktifkan',
          'Activate':'Aktifkan',
          'Inactive':'Tidak aktif',
          'Active':'Aktif',
          'Commission Ledger':'Lejar Komisen',
          'Commission':'Suruhanjaya',
          'Downlines':'Downline',
          'Back to List':'Kembali kepada senarai',
          'Reset Password':'Menetapkan semula kata laluan',
          'Edit':'Sunting',
          'Username':'Nama pengguna',
          'Reset Password':'Menetapkan semula kata laluan',
          'Cancel':'Batal',
          'Submit':'Hantar',
          'Payee Type':'Jenis Penerima',
          'Trx Type.':'Jenis Trx.',
          'Trx No.':'Trx No.',
          'Created':'Dicipta',
          'Verified CS':'CS yang disahkan',
          'Locked':'Terkunci',
          'Verifiedcs':'Verifiedcs',
          'New Password':'Kata laluan baharu',
          'out of':'daripada',
          'to':'kepada',
          'Change Paswword':'Tukar Kata Laluan',
          'Logout':'Log keluar',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'Gunakan kemudahan ini apabila ejen anda terlupa kata laluannya. Sila simpan kata laluan yang dijana.',
          'Delete Record':'Padam Rekod',
          'Are you sure you want to delete this record?':'Adakah anda pasti mahu memadamkan rekod ini?',
          'Yes':'ya',
          'No':'Tidak',
          'Select Country Code':'Pilih Kod Negara',
          'this Agent?':'Ejen ini?',
          'Confirm':'sahkan',
          'Are you sure you want to':'Adakah anda pasti mahu',
          'Approve':'Terima',
          'this Commission? This action cannot be undone.':'Suruhanjaya ini? Tindakan ini tidak boleh dibuat asal.',
          'this Player? This action cannot be undone.':'Pemain ini? Tindakan ini tidak boleh dibuat asal.',
          'Verify':'Sahkan',
          'this deposit? This action cannot be undone.':'deposit ini? Tindakan ini tidak boleh dibuat asal.',
          'this withdrawal? This action cannot be undone.':'pengeluaran ini? Tindakan ini tidak boleh dibuat asal.',
          'Fill in the form below':'Isi borang di bawah',
          'Convert': 'Tukar',
          'your commission? Once you submit this transaction it cannot be undone.' : 'komisen anda? Sebaik sahaja anda menyerahkan transaksi ini, transaksi ini tidak boleh dibuat asal.',
          'Convert Commission' : 'Komisen Tukar',
          'Agent Information':'Maklumat Ejen',
          'Player Deposit Information':'Maklumat Deposit Pemain',
          'Fund Transaction Information':'Maklumat Transaksi Dana',
          'Transaction Number':'Nombor transaksi',
          'Transaction Date':'Tarikh transaksi',
          'Transaction Type':'Jenis Transaksi',
          'Transaction Amount':'Jumlah transaksi',
          'Reference Transaction Number':'Nombor Urus Niaga Rujukan',
          'Name':'Nama',
          'Number':'Nombor',
          'Agent':'Ejen',
          'Currency':'Mata wang',
          'New Wallet Balance':'Baki Wallet Baharu',
          'Previous balance':'Baki sebelumnya',
          'Memo Type':'Jenis Memo',
          'Remarks':'Kenyataan',
          'Account Name':'Nama akaun',
          'Channel Name':'Nama Saluran',
          'Account Number':'Nombor akaun',
          'Date Created':'Tarikh Dibuat',
          'Add New Payment Channel':'Tambah Saluran Pembayaran Baharu',
          'Commission Ledger List':'Senarai Lejar Komisen',
          'Previous Balance':'Baki Sebelumnya',
          'Delete':'Padam',
          'Bank Name or Payment Channel Name':'Nama Bank atau Nama Saluran Pembayaran',
          'Memo':'Memo',
          'Txn No.':'Txn No.',
          'Txn Type':'Jenis Txn',
          'Txn Date':'Tarikh Txn',
          'Credit':'Kredit',
          'CREDIT':'KREDIT',
          'DEBIT':'DEBET',
          'Admin':'Pentadbir',
          'PENDING':'MENUNGGU',
          'DEPOSIT':'DEPOSIT',
          'ADMIN':'ADMIN',
          'Note':'Nota',
          'Fund type':'Jenis dana',
          'Fund Transaction':'Transaksi Dana',
          'ACTIVE':'AKTIF',
          'INACTIVE':'TIDAK AKTIF',
          'Minimum Commission Conversion':'Penukaran Komisen Minimum',
          'Payment Channel Account Name':'Nama Akaun Saluran Pembayaran',
          'Player Information':'Maklumat Pemain',
          'Edit Agent':'Ejen Edit',
          'Shareable Link':'Pautan Boleh Kongsi',
          'Super Agen':'Super Agen',
          'Fund Type':'Jenis Dana',
          'DEBIT':'DEBIT',
          'Wallet Point Ledger Information':'Maklumat Lejar Mata Dompet',
          'Commission Point Ledger Information':'Maklumat Lejar Mata Komisen',
          'Account Expired':'Akaun Tamat Tempoh',
          'Account Information':'Maklumat Akaun',
          'Account is Inactive, please contact the administrator at':'Akaun Tidak Aktif, sila hubungi pentadbir di',
          'Account is locked due to multiple invalid attempts, please contact the administrator at':'Akaun dikunci kerana beberapa percubaan tidak sah, sila hubungi pentadbir di',
          'Account is locked, please contact the administrator at':'Akaun dikunci, sila hubungi pentadbir di',
          'Account Locked':'Akaun dikunci',
          'Account Name':'Nama akaun',
          'Account Non Expired':'Akaun Tidak Luput',
          'Account Non Locked':'Akaun Tidak Dikunci',
          'Account Number':'Nombor akaun',
          'Action':'Tindakan',
          'Activate':'Aktifkan',
          'ACTIVE':'AKTIF',
          'Active Agent':'Agen Aktif',
          'Active Players':'Pemain Aktif',
          'Add Agent':'Tambah Ejen',
          'Add New Agent':'Tambah Ejen Baharu',
          'Add New Operator':'Tambah Operator Baharu',
          'Add New Payment Channel':'Tambah Saluran Pembayaran Baharu',
          'Add New User':'Tambah Pengguna Baharu',
          'Add Payment Channel':'Tambah Saluran Pembayaran',
          'Address':'Alamat',
          'Address 1':'alamat 1',
          'Address 2':'Alamat 2',
          'Adjusted':'Dilaraskan',
          'Admin':'Admin',
          'Admin Credit Memo':'Memo Kredit Admin',
          'Admin Debit Memo':'Memo Debit Pentadbir',
          'ADMIN_CM':'ADMIN_CM',
          'ADMIN_DM':'ADMIN_DM',
          'Agent':'Ejen',
          'Agent Add':'Tambah Agen',
          'Agent addition success!':'Kejayaan penambahan ejen!',
          'Agent Code':'Kod Ejen',
          'Agent Code already taken':'Kod Ejen sudah diambil',
          'Agent Comm. Rate':'Ejen Comm. Kadar',
          'Agent Credit Memo':'Memo Kredit Ejen',
          'Agent Debit Memo':'Memo Debit Ejen',
          'Agent Deposit':'Deposit Agen',
          'Agent edit success!':'Kejayaan edit ejen!',
          'Agent Information':'Maklumat Ejen',
          'Agent List':'Senarai Ejen',
          'Agent Name':'Nama Ejen',
          'Agent Type':'Jenis Ejen',
          'Agent View':'Pandangan Ejen',
          'Agent Withdraw':'Penarikan Agen',
          'AGENT_CM':'AGENT_CM',
          'AGENT_DEPOSIT':'AGENT_DEPOSIT',
          'AGENT_DM':'AGENT_DM',
          'AGENT_WITHDRAW':'AGENT_MENARIK',
          'Agents Add':'Ejen Tambah',
          'Agents Edit':'Ejen Edit',
          'Agents List':'Senarai Ejen',
          'Agents Tree':'Pokok Agen',
          'Agents View':'Pandangan Ejen',
          'Already have an account?':'Sudah mempunyai akaun?',
          'Amount':'Jumlah',
          'API Key':'Kunci API',
          'Application Error':'Ralat Aplikasi',
          'Approve':'Terima',
          'Approved':'Diluluskan',
          'Approved Amount':'Jumlah yang Diluluskan',
          'Approved Commission Amount':'Jumlah Komisen yang Diluluskan',
          'Are you sure you want to':'Adakah anda pasti mahu',
          'Are you sure you want to <b>Approve</b> this Commission? This action cannot be undone.':'Adakah anda pasti mahu <b>Meluluskan</b> Suruhanjaya ini? Tindakan ini tidak boleh dibuat asal.',
          'Are you sure you want to delete this record?':'Adakah anda pasti mahu memadamkan rekod ini?',
          'Back to List':'Kembali kepada senarai',
          'Bank Name or Payment Channel Name':'Nama Bank atau Nama Saluran Pembayaran',
          'Base API Url':'Url API asas',
          'BECOME A':'MENJADI SEORANG',
          'Bet Amount':'Jumlah Pertaruhan',
          'Bet Corner':'Sudut Pertaruhan',
          'Bet Result':'Keputusan Taruhan',
          'BLOCKED':'TERSEKAT',
          'Blocked Agent':'Ejen yang disekat',
          'Blocked Players':'Pemain yang disekat',
          'Cancel':'Batal',
          'Cap View':'Cap View',
          'Change Password':'Tukar kata laluan',
          'Change password success! Use your new password on your next login':'Tukar kata laluan kejayaan! Gunakan kata laluan baharu anda pada log masuk seterusnya',
          'Channel Name':'Nama Saluran',
          'Channel Name (eg. GCash, Paymaya, ect)':'Nama Saluran (cth. GCash, Paymaya, dll)',
          'Clear':'Jelas',
          'Client Key':'Kunci Pelanggan',
          'CNY':'Tahun Baru Cina',
          'Code':'Kod',
          'Commission':'Suruhanjaya',
          'Commission Approval':'Kelulusan Komisen',
          'Commission Income':'Pendapatan Komisen',
          'Commission Information':'Maklumat Suruhanjaya',
          'Commission Ledger':'Lejar Komisen',
          'Commission Ledger List':'Senarai Lejar Komisen',
          'Commission Ledger View':'Pandangan Lejar Komisen',
          'Commission List':'Senarai Komisen',
          'Commission Point Ledger Information':'Maklumat Lejar Mata Komisen',
          'Commission Points':'Mata Komisen',
          'Commission Rate':'Kadar Komisen',
          'Commission Rate must not be zero':'Kadar Komisen mestilah tidak sifar',
          'Commission Rate Setup':'Persediaan Kadar Komisen',
          'Commission Rate Setup edit success!':'Kejayaan edit Persediaan Kadar Komisen!',
          'Commission Rate View':'Pandangan Kadar Komisen',
          'Commission Rates':'Kadar Komisen',
          'Commission View':'Pandangan Komisen',
          'COMMISSION_APPROVAL':'KELULUSAN_KOMISI',
          'COMMISSION_INCOME':'COMMISSION_INCOME',
          'Commissions':'Komisen',
          'Commissions List':'Senarai Komisen',
          'Commissions View':'Pandangan Komisen',
          'Confirm':'sahkan',
          'Convert':'Tukar',
          'Convert Commission':'Komisen Tukar',
          'Convert commission success!':'Tukar kejayaan komisen!',
          'Create Agent':'Buat Ejen',
          'Create an Account':'Buat Akaun',
          'Create your account and become a master agent now.':'Buat akaun anda dan jadilah ejen induk sekarang.',
          'Created':'Dicipta',
          'Created By':'Dicipta oleh',
          'Created Date':'Tarikh Dicipta',
          'Credential Expired':'Tauliah Tamat Tempoh',
          'Credential Non Expired':'Tauliah Tidak Luput',
          'Credit':'Kredit',
          'Credit Limit must not be zero':'Had Kredit tidak boleh sifar',
          'Credit Memo':'Memo kredit',
          'Credit Memo List':'Senarai Memo Kredit',
          'CREDIT_MEMO':'MEMO KREDIT',
          'Currency':'mata wang',
          'Currency*':'mata wang*',
          'Dashboard':'Papan pemuka',
          'Date':'Tarikh',
          'Date Created':'Tarikh Dibuat',
          'Date Modified':'Tarikh Diubah Suai',
          'Date Registered':'Tarikh Didaftar',
          'Deactivate':'Nyahaktifkan',
          'Debit':'Debit',
          'Debit Memo':'Memo Debit',
          'Debit Memo List':'Senarai Memo Debit',
          'DEBIT_MEMO':'DEBIT_MEMO',
          'Delete':'Padam',
          'Deposit':'Deposit',
          'Deposits':'Deposit',
          'Disapprove':'tak setuju',
          'Disapproved':'Tidak diluluskan',
          'Downlines':'Downline',
          'download':'muat turun',
          'e.g HSBC, WeChat Pay, etc':'cth HSBC, WeChat Pay, dsb',
          'Earn 3% of commission in every bets!':'Dapatkan 3% komisen dalam setiap pertaruhan!',
          'Edit':'Sunting',
          'Edit Agent':'Edit Ejen',
          'Edit Payment Channel':'Edit Saluran Pembayaran',
          'Email':'E-mel',
          'Email already taken':'E-mel sudah diambil',
          'Email cannot be empty':'E-mel tidak boleh kosong',
          'Email is invalid':'Email tidak sah',
          'Email not found':'E-mel tidak ditemui',
          'Email*':'E-mel*',
          'Enabled':'Didayakan',
          'End Date':'Tarikh tamat',
          // 'English':'Inggeris',
          'Enter your credentials to access your account.':'Masukkan kelayakan anda untuk mengakses akaun anda.',
          'Expired':'Tamat tempoh',
          'export':'eksport',
          'Fill in the form below':'Isi borang di bawah',
          'Filter':'Penapis',
          'First Name':'Nama pertama',
          'First Name*':'Nama pertama*',
          'Fund Approval List':'Senarai Kelulusan Dana',
          'Fund In':'Dana Masuk',
          'Fund List Approval':'Kelulusan Senarai Dana',
          'Fund List Request':'Permintaan Senarai Dana',
          'Fund Request List':'Senarai Permintaan Dana',
          'Fund Transaction':'Urus Niaga Dana',
          'Fund Transaction has been sent! please wait for the approval.':'Transaksi Dana telah dihantar! sila tunggu kelulusan.',
          'Fund Transaction Information':'Maklumat Transaksi Dana',
          'Fund type':'Jenis dana',
          'Fund View Approval':'Kelulusan Pandangan Dana',
          'Fund View Request':'Permintaan Pandangan Dana',
          'Funds For Approval':'Dana Untuk Kelulusan',
          'Funds List':'Senarai Dana',
          'Funds Request':'Permintaan Dana',
          'Funds View':'Pandangan Dana',
          'Game URL':'URL permainan',
          'Go Back':'Pergi balik',
          'HOLD':'TAHAN',
          'Home':'Rumah',
          'IDR':'IDR',
          'INACTIVE':'TIDAK AKTIF',
          'Indonesian':'Indonesia',
          'Insufficient Balance':'Baki Tidak Mencukupi',
          'Insufficient Wallet Commission Balance':'Baki Komisen Wallet Tidak Mencukupi',
          'Insufficient withdrawal amount':'Jumlah pengeluaran yang tidak mencukupi',
          'Invalid Amount':'Jumlah Tidak Sah',
          'Invalid credential':'Bukti kelayakan tidak sah',
          'Invalid credential on fourth attempt, account will be locked on 5th try':'Bukti kelayakan tidak sah pada percubaan keempat, akaun akan dikunci pada percubaan ke-5',
          'Invalid credential on third attempt, account will be locked on 5th try':'Bukti kelayakan tidak sah pada percubaan ketiga, akaun akan dikunci pada percubaan ke-5',
          'Invalid credential, please recheck your inputs':'Bukti kelayakan tidak sah, sila semak semula input anda',
          'Invalid credentials, Your account has been locked, please contact the administrator at':'Bukti kelayakan tidak sah, Akaun anda telah dikunci, sila hubungi pentadbir di',
          'Invalid OTP':'OTP tidak sah',
          'Invalid OTP, please check your input or you may regenerate again':'OTP tidak sah, sila semak input anda atau anda boleh menjana semula semula',
          'Invalid Username / Password':'Nama Pengguna / Kata Laluan Tidak Sah',
          'Invalid username or password, please check your input':'Nama pengguna atau kata laluan tidak sah, sila semak input anda',
          'Korean':'bahasa korea',
          'KRW':'KRW',
          'Last Name':'Nama terakhir',
          'Last Name*':'Nama terakhir*',
          'Ledgers':'Lejar',
          'Limit':'Had',
          'Lock':'Kunci',
          'Locked':'Terkunci',
          'Login':'Log masuk',
          'Malaysian':'Malaysia',
          'Manage':'Mengurus',
          'Match Code':'Kod Padanan',
          'Memo':'Memo',
          'Memo Type':'Jenis Memo',
          'Middle Name':'Nama tengah',
          'Minimum Commission Conversion':'Penukaran Komisen Minimum',
          'Mobile':'mudah alih',
          'Mobile number already taken':'Nombor mudah alih sudah diambil',
          'Mobile Number not found':'Nombor Mudah Alih tidak ditemui',
          'Mobile*':'Mudah Alih*',
          'Modified By':'Diubahsuai Oleh',
          'Modified Date':'Tarikh Diubah Suai',
          'MYR':'MYR',
          'Name':'nama',
          'New Balance':'Keseimbangan baru',
          'New Password':'Kata laluan baharu',
          'New Wallet Balance':'Baki Wallet Baharu',
          'No':'Tidak',
          'Not Found':'Tidak ditemui',
          'Not yet registered?':'Belum berdaftar?',
          'Note':'Catatan',
          'Note must not be empty':'Nota tidak boleh kosong',
          'Note*':'Catatan*',
          'Note: Each tier total should not be greater than total allowable commission rate (2%) as of December 4, 2020':'Nota: Setiap jumlah peringkat tidak boleh lebih besar daripada jumlah kadar komisen yang dibenarkan (2%) pada 4 Disember 2020',
          'Notes':'Nota',
          'NTD':'NTD',
          'Old Password':'Kata laluan lama',
          'Open this link':'Buka pautan ini',
          'Operator':'Operator',
          'Operator Add':'Tambah Operator',
          'Operator addition success!':'Kejayaan penambahan operator!',
          'Operator Code':'Kod Operator',
          'Operator Information':'Maklumat Operator',
          'Operator List':'Senarai Operator',
          'Operator modification success!':'Kejayaan pengubahsuaian operator!',
          'Operator Name':'Nama Operator',
          'Operator View':'Pandangan Operator',
          'Operators':'Operator',
          'out of':'daripada',
          'Page not found':'Laman tidak dijumpai',
          'Parent Agent Code':'Kod Ejen Ibu Bapa',
          'Password':'Kata laluan',
          'Password*':'Kata laluan*',
          'Payee Code':'Kod Penerima',
          'Payee Type':'Jenis Penerima',
          'Payment Channel Account Name':'Nama Akaun Saluran Pembayaran',
          'Payment Channel Account Number':'Nombor Akaun Saluran Pembayaran',
          'Payment Channel Add':'Tambah Saluran Pembayaran',
          'Payment Channel Edit':'Edit Saluran Pembayaran',
          'Payment Channel edit success!':'Kejayaan pengeditan Saluran Pembayaran!',
          'Payment Channel Information':'Maklumat Saluran Pembayaran',
          'Payment Channel List':'Senarai Saluran Pembayaran',
          'Payment Channel View':'Pandangan Saluran Pembayaran',
          'Payment Channels':'Saluran Pembayaran',
          'Payor Code':'Kod Pembayar',
          'Payor Type':'Jenis Pembayar',
          'PENDING':'YANG BELUM SELESAI',
          'Pending Agent':'Ejen menunggu',
          'Pending Deposits':'Deposit Tertunda',
          'Pending Players':'Pemain menunggu',
          'Pending Withdraws':'Menunggu Pengeluaran',
          'Period':'Tempoh',
          'Plasada Amount':'Jumlah Plasada',
          'Player Bets':'Taruhan Pemain',
          'Player Credit Memo':'Memo Kredit Pemain',
          'Player Debit Memo':'Memo Debit Pemain',
          'Player Deposit':'Deposit Pemain',
          'Player Deposit Information':'Maklumat Deposit Pemain',
          'Player Deposit List':'Senarai Deposit Pemain',
          'Player Deposit View':'Pandangan Deposit Pemain',
          'Player Information':'Maklumat Pemain',
          'Player List':'Senarai Pemain',
          'Player No.':'Pemain No.',
          'Player Number':'Nombor Pemain',
          'Player Username':'Nama Pengguna Pemain',
          'Player View':'Pandangan Pemain',
          'Player Withdraw':'Penarikan Diri Pemain',
          'Player Withdrawal Information':'Maklumat Pengeluaran Pemain',
          'Player Withdrawal List':'Senarai Penarikan Pemain',
          'Player Withdrawal View':'Pandangan Pengeluaran Pemain',
          'PLAYER_CM':'PLAYER_CM',
          'PLAYER_DEPOSIT':'PLAYER_DEPOSIT',
          'PLAYER_DM':'PLAYER_DM',
          'PLAYER_WITHDRAW':'PLAYER_WITHDRRAW',
          'Players':'Pemain',
          'Players Deposit':'Deposit Pemain',
          'Players List':'Senarai Pemain',
          'Players Withdrawals':'Pengeluaran Pemain',
          'Please wait…':'Sila tunggu…',
          'Prev. Balance':'Sebelum Seimbang',
          'Previous Balance':'Baki sebelumnya',
          'Profile':'Profil',
          'records':'rekod',
          'Reference Transaction Number':'Nombor Urus Niaga Rujukan',
          'Register':'Daftar',
          'Register Now':'Daftar sekarang',
          'Registered successfully! We will contact you for your account activation.':'Berjaya mendaftar! Kami akan menghubungi anda untuk pengaktifan akaun anda.',
          'Registration':'Pendaftaran',
          'Remarks':'Kenyataan',
          'Request Fund Transfer':'Minta Pindahan Dana',
          'Requested Amount Must Be Greater Than Commission Balance':'Jumlah yang Diminta Mesti Lebih Besar Daripada Baki Komisen',
          'Requested amount must be greater than minimum transfer amount':'Amaun yang diminta mestilah lebih besar daripada jumlah pemindahan minimum',
          'Reset':'Tetapkan semula',
          'Reset Password':'Menetapkan semula kata laluan',
          'Reset Password success!':'Tetapkan semula kata laluan kejayaan!',
          'Role':'Peranan',
          's password?':'kata laluan?',
          'Sabong Network Login':'Log Masuk Rangkaian Sabong',
          'Save':'Jimat',
          'Secret Key':'Kunci Rahsia',
          'Select Country Code':'Pilih Kod Negara',
          'Setup':'Persediaan',
          'Share Code Link has been copied to your clipboard!':'Pautan Kod Kongsi telah disalin ke papan keratan anda!',
          'Shareable Link':'Pautan Boleh Kongsi',
          'Shop Name is already taken':'Nama Kedai sudah diambil',
          'Showing':'Menunjukkan',
          'Sign Up':'Daftar',
          'Skype':'Skype',
          'Something went wrong, please contact the administrator at':'Kesilapan telah berlaku, sila hubungi pentadbir di',
          'Start Date':'Tarikh mula',
          'Status':'Status',
          'Sub Agent':'Sub Ejen',
          'Sub Agent Comm. Rate':'Sub Ejen Comm. Kadar',
          'SUB_AGENT':'SUB_AGENT',
          'Submit':'Hantar',
          'Super Admin':'Super Admin',
          'Super Agent':'Ejen Super',
          'Super Agent Comm. Rate':'Super Agent Comm. Kadar',
          'SUPER MASTER AGENT':'EJEN SUPER MASTER',
          'SUPER_ADMIN':'SUPER_ADMIN',
          'SUPER_AGENT':'SUPER_AGENT',
          'System Information':'Maklumat sistem',
          'Telegram':'Telegram',
          'The :attribute is not valid format and you can only use numeric and (+) plus sign':'Atribut : bukan format yang sah dan anda hanya boleh menggunakan tanda angka dan (+) tambah',
          'The Commission Rate must be less than the rate assigned to you.':'Kadar Komisen mestilah kurang daripada kadar yang diberikan kepada anda.',
          'this Agent?':'Ejen ini?',
          'this Commission? This action cannot be undone.':'Suruhanjaya ini? Tindakan ini tidak boleh dibuat asal.',
          'this deposit? This action cannot be undone.':'deposit ini? Tindakan ini tidak boleh dibuat asal.',
          'this Player? This action cannot be undone.':'Pemain ini? Tindakan ini tidak boleh dibuat asal.',
          'this withdrawal? This action cannot be undone.':'pengeluaran ini? Tindakan ini tidak boleh dibuat asal.',
          'Tier 1 (Single Layer commissioning)':'Tahap 1 (Pentauliahan Lapisan Tunggal)',
          'Tier 2 (Double Layer commissioning)':'Tahap 2 (Pentauliahan Lapisan Berganda)',
          'Tier 3 (Tri Layer commissioning)':'Tahap 3 (Pentauliahan Tri Lapisan)',
          'to':'kepada',
          'toggle password visibility':'togol keterlihatan kata laluan',
          'Total commission from player will be divided to Super Agent and Agent. Sum should equate to allowable commission of sabong.':'Jumlah komisen daripada pemain akan dibahagikan kepada Super Agent dan Agent. Jumlah hendaklah sama dengan komisen sabong yang dibenarkan.',
          'Total commission from player will be divided to Super Agent, Agent and Sub Agent. Sum should equate to allowable commission of sabong.':'Jumlah komisen daripada pemain akan dibahagikan kepada Super Agent, Agent dan Sub Agent. Jumlah hendaklah sama dengan komisen sabong yang dibenarkan.',
          'Total Commission from player will directly go to Super Agent alone.':'Jumlah Komisen daripada pemain akan terus kepada Super Agent sahaja.',
          'Total Sum':'Jumlah Jumlah',
          'Transaction Amount':'jumlah transaksi',
          'Transaction Date':'tarikh transaksi',
          'Transaction No.':'Transaksi No.',
          'Transaction Number':'Nombor transaksi',
          'Transaction Type':'Jenis Transaksi',
          'Transfer From Commission':'Pemindahan Daripada Komisen',
          'Transfer To Wallet':'Pindahkan Ke Dompet',
          'TRANSFER_FROM_COMMISSION':'TRANSFER_FROM_COMMISSION',
          'TRANSFER_TO_WALLET':'TRANSFER_TO_WALLET',
          'Trx No.':'Trx No.',
          'Trx Type.':'Jenis Trx.',
          'Txn Date':'Txn Tarikh',
          'Txn No.':'Txn No.',
          'Txn Type':'Jenis Txn',
          'Type':'taip',
          'Unable to process your request':'Tidak dapat memproses permintaan anda',
          'Unlock':'Buka kunci',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'Gunakan kemudahan ini apabila ejen anda terlupa kata laluannya. Sila simpan kata laluan yang dijana.',
          'User':'pengguna',
          'User Add':'Tambah Pengguna',
          'User Agent':'Ejen Pengguna',
          'User Change Password':'Tukar Kata Laluan Pengguna',
          'User Information':'Maklumat Pengguna',
          'User List':'Senarai Pengguna',
          'User Name':'Nama Pengguna',
          'User Name*':'Nama Pengguna*',
          'User View':'Pandangan Pengguna',
          'Username':'Nama pengguna',
          'Username already taken':'Nama pengguna sudah diambil',
          'Username already used':'Nama pengguna sudah digunakan',
          'Username cannot be empty':'Nama pengguna tidak boleh kosong',
          'Username is already taken':'Nama pengguna sudah diambil',
          'Username not found':'Nama pengguna tidak ditemui',
          'Users':'Pengguna',
          'Verified CS':'CS yang disahkan',
          'VerifiedCS':'VerifiedCS',
          'Verify':'Sahkan',
          'Vietnamese':'bahasa Vietnam',
          'VND':'VND',
          'Wallet Ledger':'Lejar Dompet',
          'Wallet Ledger View':'Pandangan Lejar Dompet',
          'Wallet Point Ledger Information':'Maklumat Lejar Mata Dompet',
          'Wallet Points':'Mata Dompet',
          'Welcome Back':'Selamat kembali',
          'Whatsapp':'Whatsapp',
          'Withdraw':'tarik diri',
          'Withdrawals':'Pengeluaran',
          'Yes':'ya',
          'You have insuficient fund, please request for top up first.':'Anda mempunyai dana yang tidak mencukupi, sila minta tambah nilai terlebih dahulu.',
          'your commission? Once you submit this transaction it cannot be undone.':'komisen anda? Sebaik sahaja anda menyerahkan transaksi ini, transaksi ini tidak boleh dibuat asal.',
        },
      },
      ko: { //korea
        translations: {
          'Add New Agent':'새 에이전트 추가',
          'Commission List':'커미션 목록',
          'Enter your credentials to access your account.':'계정에 액세스하려면 자격 증명을 입력하세요.',
          'username':'사용자 이름',
          'password':'비밀번호',
          'Login':'로그인',
          'Not yet registered?':'아직 등록하지 않으셨나요?',
          'Create an Account':'계정 만들기',
          'Earn 3% of commission in every bets!':'모든 베팅에서 커미션의 3%를 받으세요!',
          'REGISTER NOW':'지금 등록하세요',
          'BECOME A':'에이 되기',
          'SUPER MASTER AGENT':'슈퍼마스터 에이전트',
          // 'English':'영어',
          // 'Indonesian':'인도네시아 인',
          // 'Korean':'한국인',
          // 'Malaysian':'말레이시아인',
          // 'Vietnamese':'베트남어',
          'The username field is required.':'사용자 이름 필드는 필수 항목입니다.',
          'The password field is required.':'비밀번호 필드는 필수 항목입니다.',
          'Sorry unable to serve your request. Please contact administrator.':'귀하의 요청을 처리할 수 없어 죄송합니다. 관리자에게 문의하십시오.',
          'User Name*':'사용자 이름*',
          'Username*':'사용자 이름*',
          'Password*':'비밀번호*',
          'First Name*':'이름*',
          'Firstname*':'이름*',
          'Last Name*':'성*',
          'Lastname*':'성*',
          'Currency*':'통화*',
          'Email*':'이메일*',
          'Mobile*':'이동하는*',
          'Telegram':'전보',
          'Skype':'스카이프',
          'Whatsapp':'왓츠앱',
          'Register':'등록하다',
          'Sabong Network recreational hobby':'사봉네트워크 여가 취미',
          'Sign Up':'가입하기',
          'Create your account and become a master agent now.':'지금 계정을 만들고 마스터 에이전트가 되십시오.',
          // 'English':'영어',
          'Already have an account?':'이미 계정이 있습니까?',
          'Registered successfully! We will contact you for your account activation.':'가입 성공! 계정 활성화를 위해 연락드리겠습니다.',
          'Welcome':'어서 오십시오',
          'Wallet Points':'지갑 포인트',
          'Commission':'수수료',
          'Commissions':'수수료',
          'Pending Deposits':'보류 중인 예금',
          'Pending Withdrawals':'보류 중인 출금',
          'Active Players':'활성 플레이어',
          'Pending Players':'대기 중인 플레이어',
          'Blocked Players':'차단된 플레이어',
          'Active Agent':'활성 에이전트',
          'Pending Agent':'대기 중인 에이전트',
          'Blocked Agent':'차단된 에이전트',
          'Profile':'프로필',
          'Change Password':'비밀번호 변경',
          'Logout':'로그 아웃',
          'Dashboard':'계기반',
          'Players':'선수',
          'Manage':'관리하다',
          'Deposits':'매장',
          'Withdrawals':'출금',
          'Agent':'에이전트',
          'Tree View':'트리 뷰',
          'Cap View':'캡 뷰',
          'Funds Request':'자금 요청',
          'Funds for Approval':'승인을 위한 자금',
          'Ledgers':'원장',
          'Commission Points':'커미션 포인트',
          'Payment Channels':'결제 채널',
          'Commission List':'커미션 목록',
          'Filter':'필터',
          'Clear':'분명한',
          'Status':'상태',
          'Period':'기간',
          'Agent Code':'에이전트 코드',
          'Amount':'양',
          'Approved Amount':'승인 금액',
          'Start Date':'시작일',
          'End Date':'종료일',
          'Showing':'전시',
          'Limit':'한계',
          'export':'내 보내다',
          'Pending':'보류 중',
          'Approved':'승인됨',
          'Adjusted':'조정됨',
          'Game URL':'게임 URL',
          'Open this link':'이 링크 열기',
          'Player Username':'플레이어 사용자 이름',
          'Player Number':'선수 번호',
          'Player No.':'선수번호',
          'Created Date':'만든 날짜',
          'Action':'동작',
          'Players List':'선수 목록',
          'Players List':'선수 목록',
          'Players Deposit':'선수 보증금',
          'Player Deposits':'선수 예치금',
          'Player Deposit':'선수 보증금',
          'Disapproved':'비승인',
          'Transaction No.':'거래 번호',
          'Players Withdrawals':'선수 철수',
          'Player Withdrawals':'선수 기권',
          'Active':'활동적인',
          'Inactive':'비활성',
          'Hold':'잡고있다',
          'Blocked':'막힌',
          'Credit Limit':'신용 한도',
          'Commission Rate':'수수료율',
          'Agent Type':'에이전트 유형',
          'Parent Agent Code':'상위 에이전트 코드',
          'Date Registered':'등록 날짜',
          'Add New Agent':'새 에이전트 추가',
          'Super Agent':'슈퍼 에이전트',
          'Request Fund Transfer':'자금 이체 요청',
          'Fund Request List':'자금 요청 목록',
          'Payee Code':'수취인 코드',
          'Payor Code':'지불인 코드',
          'Type':'유형',
          'Deposit':'보증금',
          'Withdraw':'철회하다',
          'Payor Type':'지급인 유형',
          'Fund Approval List':'자금 승인 목록',
          'Wallet Ledger':'지갑 원장',
          'Memo Type':'메모 유형',
          'Debit':'차변',
          'Credit':'신용 거래',
          'New Balance':'새로운 균형',
          'Prev. Balance':'이전 균형',
          'Player Withdraw':'선수 기권',
          'Player Withdrawal':'선수 철수',
          'Player Credit Memo':'선수 크레딧 메모',
          'Player Debit Memo':'플레이어 차변 메모',
          'Agent Deposit':'에이전트 보증금',
          'Agent Withdraw':'에이전트 철수',
          'Agent Withdrawal':'에이전트 철수',
          'Agent Credit Memo':'에이전트 대변 메모',
          'Agent Debit Memo':'대리인 차변 메모',
          'Admin Credit Memo':'관리자 크레딧 메모',
          'Admin Debit Memo':'관리 차변 메모',
          'Commission Income':'수수료 수입',
          'Commission Approval':'위원회 승인',
          'Credit Memo':'대변 ​​메모',
          'Debit Memo':'차변 메모',
          'Commission Ledger List':'수수료 장부 목록',
          'Address 1':'주소 1',
          'Address 2':'주소 2',
          'Credit Limit':'신용 한도',
          'Commission Rate':'수수료율',
          'Cancel':'취소',
          'Save':'구하다',
          'Remarks':'비고',
          'Add Payment Channel':'결제 채널 추가',
          'Bank Name or Payment Channel Name (e.g HSBC WeChat Pay etc)':'은행 이름 또는 결제 채널 이름(예',
          'Account Name':'계정 이름',
          'Account Number':'계좌 번호',
          'Channel Name':'채널 이름',
          'Date Created':'생성 일자',
          'Welcome Back':'다시 오신 것을 환영합니다',
          'System Information' : '시스템 정보',
          'Register Now':'지금 등록하세요',
          'Pending Withdraws': '보류 중인 출금',
          'Please wait...': '기다리세요...',
          'Approved': '승인됨',
          'APPROVED': '승인됨',
          'Account Information':'계정 정보',
          'Middle Name':'중간 이름',
          'Email':'이메일',
          'Currency':'통화',
          'Account Expired':'계정 만료됨',
          'Credential Expired':'자격 증명 만료됨',
          'Enabled':'활성화됨',
          'Account Locked':'잠긴 계정',
          'First Name':'이름',
          'Last Name':'성',
          'Mobile':'이동하는',
          'Created By':'만든 사람',
          'Modified By':'수정자',
          'Modified Date':'수정 날짜',
          'Lock':'자물쇠',
          'Agent':'에이전트',
          'Code':'암호',
          'Mobile':'이동하는',
          'First Name':'이름',
          'Last Name':'성',
          'Deactivate':'비활성화',
          'Activate':'활성화',
          'Inactive':'비활성',
          'Active':'활동적인',
          'Commission Ledger':'커미션 원장',
          'Commission':'수수료',
          'Commissions':'수수료',
          'Downlines':'다운라인',
          'Back to List':'다시 목록으로',
          'Reset Password':'암호를 재설정',
          'Edit':'편집하다',
          'Username':'사용자 이름',
          'Reset Password':'암호를 재설정',
          'Cancel':'취소',
          'Submit':'제출하다',
          'Payee Type':'수취인 유형',
          'Trx Type.':'전송 유형.',
          'Trx No.':'전송 번호',
          'Created':'만들어진',
          'Verified CS':'검증된 CS',
          'Locked':'잠김',
          'Verifiedcs':'Verifiedcs',
          'New Password':'새 비밀번호',
          'out of':'밖으로',
          'to':'에게',
          'Change Paswword':'비밀번호 변경',
          'Logout':'로그 아웃',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'에이전트가 비밀번호를 잊어버렸을 때 이 기능을 사용하십시오. 생성된 비밀번호를 저장해 주세요.',
          'Delete Record':'기록 삭제',
          'Are you sure you want to delete this record?':'이 레코드를 삭제하시겠습니까?',
          'Yes':'예',
          'No':'아니요',
          'Select Country Code':'국가 코드 선택',
          'this Agent?':'이 에이전트?',
          'Confirm':'확인하다',
          'Are you sure you want to':'하시겠습니까?',
          'Approve':'승인하다',
          'this Commission? This action cannot be undone.':'이 위원회? 이 작업은 취소할 수 없습니다.',
          'this Player? This action cannot be undone.':'이 플레이어? 이 작업은 취소할 수 없습니다.',
          'Verify':'검증',
          'this deposit? This action cannot be undone.':'이 보증금? 이 작업은 취소할 수 없습니다.',
          'this withdrawal? This action cannot be undone.':'이번 철수? 이 작업은 취소할 수 없습니다.',
          'Network':'회로망',
          // 'English':'English',
          // 'Korean':'한국인',
          // 'Indonesian':'bahasa Indonesia',
          // 'Malaysian':'Malaysia',
          // 'Vietnamese':'Tiếng Việt',
          'Fill in the form below':'아래 양식을 작성하세요',
          'Convert': '전환하다',
          'your commission? Once you submit this transaction it cannot be undone.' : '당신의 커미션? 이 거래를 제출하면 취소할 수 없습니다.',
          'Convert Commission' : '전환 수수료',
          'Agent Information':'에이전트 정보',
          'Player Deposit Information':'선수 입금 정보',
          'Fund Transaction Information':'자금거래정보',
          'Transaction Number':'거래 번호',
          'Transaction Date':'거래 날짜',
          'Transaction Type':'거래 유형',
          'Transaction Amount':'거래금액',
          'Reference Transaction Number':'참조 거래 번호',
          'Name':'이름',
          'Number':'숫자',
          'Agent':'에이전트',
          'Currency':'통화',
          'New Wallet Balance':'새 지갑 잔액',
          'Previous balance':'이전 잔고',
          'Memo Type':'메모타입',
          'Remarks':'비고',
          'Account Name':'계정 이름',
          'Channel Name':'채널 이름',
          'Account Number':'계좌 번호',
          'Date Created':'생성 일자',
          'Add New Payment Channel':'새 결제 채널 추가',
          'Commission Ledger List':'커미션 원장 목록',
          'Previous Balance':'이전 잔액',
          'Delete':'삭제',
          'Bank Name or Payment Channel Name':'은행 이름 또는 지불 채널 이름',
          'Memo':'메모',
          'Txn No.':'송신번호',
          'Txn Type':'전송 유형',
          'Txn Date':'송신일자',
          'Credit':'크레딧',
          'CREDIT':'크레딧',
          'DEBIT':'직불금',
          'Admin':'관리자',
          'PENDING':'보류 중',
          'DEPOSIT':'입금',
          'ADMIN':'관리자',
          'Note':'참고',
          'Fund type':'펀드 종류',
          'Fund Transaction':'자금거래',
          'ACTIVE':'활성',
          'INACTIVE':'비활성',
          'Minimum Commission Conversion':'최소 수수료 전환',
          'Payment Channel Account Name':'결제 채널 계정 이름',
          'Player Information':'선수 정보',
          'Edit Agent':'에이전트 편집',
          'Shareable Link':'공유 가능한 링크',
          'Super Agen':'슈퍼에이전트',
          'Fund Type':'펀드 유형',
          'DEBIT':'직불금',
          'Wallet Point Ledger Information':'월렛 포인트 원장 정보',
          'Commission Point Ledger Information':'커미션 포인트 원장 정보',
          'Account Expired':'계정 만료됨',
          'Account Information':'계정 정보',
          'Account is Inactive, please contact the administrator at':'계정이 비활성화되어 있습니다. 관리자에게 문의하십시오.',
          'Account is locked due to multiple invalid attempts, please contact the administrator at':'여러 번의 잘못된 시도로 인해 계정이 잠겼습니다. 관리자에게 다음 주소로 문의하십시오.',
          'Account is locked, please contact the administrator at':'계정이 잠겨 있습니다. 관리자에게 문의하십시오.',
          'Account Locked':'잠긴 계정',
          'Account Name':'계정 이름',
          'Account Non Expired':'만료되지 않은 계정',
          'Account Non Locked':'계정이 잠기지 않음',
          'Account Number':'계좌 번호',
          'Action':'동작',
          'Activate':'활성화',
          'ACTIVE':'활동적인',
          'Active Agent':'활성 에이전트',
          'Active Players':'활성 플레이어',
          'Add Agent':'에이전트 추가',
          'Add New Agent':'새 에이전트 추가',
          'Add New Operator':'새 연산자 추가',
          'Add New Payment Channel':'새 결제 채널 추가',
          'Add New User':'새 사용자 추가',
          'Add Payment Channel':'결제 채널 추가',
          'Address':'주소',
          'Address 1':'주소 1',
          'Address 2':'주소 2',
          'Adjusted':'조정됨',
          'Admin':'관리자',
          'Admin Credit Memo':'관리자 크레딧 메모',
          'Admin Debit Memo':'관리 차변 메모',
          'ADMIN_CM':'ADMIN_CM',
          'ADMIN_DM':'ADMIN_DM',
          'Agent':'에이전트',
          'Agent Add':'에이전트 추가',
          'Agent addition success!':'에이전트 추가 성공!',
          'Agent Code':'에이전트 코드',
          'Agent Code already taken':'에이전트 코드가 이미 사용 중입니다.',
          'Agent Comm. Rate':'에이전트 통신 비율',
          'Agent Credit Memo':'에이전트 대변 메모',
          'Agent Debit Memo':'대리인 차변 메모',
          'Agent Deposit':'에이전트 보증금',
          'Agent edit success!':'에이전트 편집 성공!',
          'Agent Information':'에이전트 정보',
          'Agent List':'에이전트 목록',
          'Agent Name':'에이전트 이름',
          'Agent Type':'에이전트 유형',
          'Agent View':'에이전트 보기',
          'Agent Withdraw':'에이전트 철수',
          'AGENT_CM':'에이전트_CM',
          'AGENT_DEPOSIT':'AGENT_DEPOSIT',
          'AGENT_DM':'에이전트_DM',
          'AGENT_WITHDRAW':'에이전트_WITHDRAW',
          'Agents Add':'에이전트 추가',
          'Agents Edit':'에이전트 편집',
          'Agents List':'에이전트 목록',
          'Agents Tree':'에이전트 트리',
          'Agents View':'에이전트 보기',
          'Already have an account?':'이미 계정이 있습니까?',
          'Amount':'양',
          'API Key':'API 키',
          'Application Error':'애플리케이션 오류',
          'Approve':'승인하다',
          'Approved':'승인됨',
          'Approved Amount':'승인 금액',
          'Approved Commission Amount':'승인된 커미션 금액',
          'Are you sure you want to':'하시겠습니까?',
          'Are you sure you want to <b>Approve</b> this Commission? This action cannot be undone.':'이 위원회를 <b>승인</b>하시겠습니까? 이 작업은 취소할 수 없습니다.',
          'Are you sure you want to delete this record?':'이 레코드를 삭제하시겠습니까?',
          'Back to List':'다시 목록으로',
          'Bank Name or Payment Channel Name':'은행 이름 또는 지불 채널 이름',
          'Base API Url':'기본 API URL',
          'BECOME A':'에이 되기',
          'Bet Amount':'배팅 금액',
          'Bet Corner':'베팅 코너',
          'Bet Result':'베팅 결과',
          'BLOCKED':'막힌',
          'Blocked Agent':'차단된 에이전트',
          'Blocked Players':'차단된 플레이어',
          'Cancel':'취소',
          'Cap View':'캡 뷰',
          'Change Password':'비밀번호 변경',
          'Change password success! Use your new password on your next login':'비밀번호 변경 성공! 다음 로그인 시 새 비밀번호 사용',
          'Channel Name':'채널 이름',
          'Channel Name (eg. GCash, Paymaya, ect)':'채널 이름(예: GCash, Paymaya 등)',
          'Clear':'분명한',
          'Client Key':'클라이언트 키',
          'CNY':'CNY',
          'Code':'암호',
          'Commission':'수수료',
          'Commission Approval':'커미션 승인',
          'Commission Income':'수수료 수입',
          'Commission Information':'수수료 정보',
          'Commission Ledger':'커미션 원장',
          'Commission Ledger List':'수수료 원장 목록',
          'Commission Ledger View':'커미션 원장 보기',
          'Commission List':'커미션 목록',
          'Commission Point Ledger Information':'커미션 포인트 원장 정보',
          'Commission Points':'커미션 포인트',
          'Commission Rate':'수수료율',
          'Commission Rate must not be zero':'수수료율은 0이 아니어야 합니다.',
          'Commission Rate Setup':'수수료율 설정',
          'Commission Rate Setup edit success!':'수수료율 설정 편집 성공!',
          'Commission Rate View':'수수료율 보기',
          'Commission Rates':'수수료율',
          'Commission View':'커미션 보기',
          'COMMISSION_APPROVAL':'커미션_승인',
          'COMMISSION_INCOME':'COMMISSION_INCOME',
          'Commissions':'커미션',
          'Commissions List':'커미션 목록',
          'Commissions View':'커미션 보기',
          'Confirm':'확인하다',
          'Convert':'전환하다',
          'Convert Commission':'전환 수수료',
          'Convert commission success!':'변환 커미션 성공!',
          'Create Agent':'에이전트 생성',
          'Create an Account':'계정 만들기',
          'Create your account and become a master agent now.':'지금 계정을 만들고 마스터 에이전트가 되십시오.',
          'Created':'만들어진',
          'Created By':'만든 사람',
          'Created Date':'만든 날짜',
          'Credential Expired':'자격 증명 만료됨',
          'Credential Non Expired':'자격 증명 만료되지 않음',
          'Credit':'신용 거래',
          'Credit Limit must not be zero':'신용 한도는 0이 아니어야 합니다.',
          'Credit Memo':'대변 ​​메모',
          'Credit Memo List':'대변 ​​메모 목록',
          'CREDIT_MEMO':'CREDIT_MEMO',
          'Currency':'통화',
          'Currency*':'통화*',
          'Dashboard':'계기반',
          'Date':'날짜',
          'Date Created':'생성 일자',
          'Date Modified':'수정 날짜',
          'Date Registered':'등록 날짜',
          'Deactivate':'비활성화',
          'Debit':'차변',
          'Debit Memo':'차변 메모',
          'Debit Memo List':'차변 메모 목록',
          'DEBIT_MEMO':'DEBIT_MEMO',
          'Delete':'삭제',
          'Deposit':'보증금',
          'Deposits':'매장',
          'Disapprove':'안된다고 하다',
          'Disapproved':'비승인',
          'Downlines':'다운라인',
          'download':'다운로드',
          'e.g HSBC, WeChat Pay, etc':'예: HSBC, WeChat Pay 등',
          'Earn 3% of commission in every bets!':'모든 베팅에서 커미션의 3%를 받으세요!',
          'Edit':'편집하다',
          'Edit Agent':'에이전트 편집',
          'Edit Payment Channel':'결제 채널 수정',
          'Email':'이메일',
          'Email already taken':'이미 받은 이메일',
          'Email cannot be empty':'이메일은 비워둘 수 없습니다.',
          'Email is invalid':'이메일이 잘못되었습니다.',
          'Email not found':'이메일을 찾을 수 없음',
          'Email*':'이메일*',
          'Enabled':'활성화됨',
          'End Date':'종료일',
          // 'English':'영어',
          'Enter your credentials to access your account.':'계정에 액세스하려면 자격 증명을 입력하세요.',
          'Expired':'만료됨',
          'export':'내 보내다',
          'Fill in the form below':'아래 양식을 작성하세요',
          'Filter':'필터',
          'First Name':'이름',
          'First Name*':'이름*',
          'Fund Approval List':'기금 승인 목록',
          'Fund In':'펀드 인',
          'Fund List Approval':'펀드 목록 승인',
          'Fund List Request':'기금 목록 요청',
          'Fund Request List':'자금 요청 목록',
          'Fund Transaction':'자금 거래',
          'Fund Transaction has been sent! please wait for the approval.':'펀드 거래가 전송되었습니다! 승인을 기다려주세요.',
          'Fund Transaction Information':'자금 거래 정보',
          'Fund type':'펀드 유형',
          'Fund View Approval':'펀드 조회 승인',
          'Fund View Request':'펀드 조회 요청',
          'Funds For Approval':'승인을 위한 자금',
          'Funds List':'자금 목록',
          'Funds Request':'자금 요청',
          'Funds View':'자금 보기',
          'Game URL':'게임 URL',
          'Go Back':'돌아가기',
          'HOLD':'잡고있다',
          'Home':'집',
          'IDR':'IDR',
          'INACTIVE':'비활성',
          'Indonesian':'인도네시아 인',
          'Insufficient Balance':'잔액 불충분',
          'Insufficient Wallet Commission Balance':'지갑 수수료 잔액 부족',
          'Insufficient withdrawal amount':'출금금액 부족',
          'Invalid Amount':'잘못된 금액',
          'Invalid credential':'잘못된 자격 증명',
          'Invalid credential on fourth attempt, account will be locked on 5th try':'네 번째 시도에서 유효하지 않은 자격 증명, 다섯 번째 시도에서 계정이 잠깁니다.',
          'Invalid credential on third attempt, account will be locked on 5th try':'세 번째 시도에서 유효하지 않은 자격 증명, 다섯 번째 시도에서 계정이 잠깁니다.',
          'Invalid credential, please recheck your inputs':'자격 증명이 잘못되었습니다. 입력을 다시 확인하세요.',
          'Invalid credentials, Your account has been locked, please contact the administrator at':'유효하지 않은 자격 증명, 귀하의 계정이 잠겼습니다. 관리자에게 문의하십시오.',
          'Invalid OTP':'잘못된 OTP',
          'Invalid OTP, please check your input or you may regenerate again':'잘못된 OTP입니다. 입력을 확인하거나 다시 생성할 수 있습니다.',
          'Invalid Username / Password':'잘못된 사용자 이름/비밀번호',
          'Invalid username or password, please check your input':'사용자 이름 또는 비밀번호가 잘못되었습니다. 입력 내용을 확인하세요.',
          'Korean':'한국인',
          'KRW':'원',
          'Last Name':'성',
          'Last Name*':'성*',
          'Ledgers':'원장',
          'Limit':'한계',
          'Lock':'자물쇠',
          'Locked':'잠김',
          'Login':'로그인',
          'Malaysian':'말레이시아인',
          'Manage':'관리하다',
          'Match Code':'매치 코드',
          'Memo':'메모',
          'Memo Type':'메모 유형',
          'Middle Name':'중간 이름',
          'Minimum Commission Conversion':'최소 수수료 전환',
          'Mobile':'이동하는',
          'Mobile number already taken':'이미 사용 중인 휴대폰 번호',
          'Mobile Number not found':'휴대폰 번호를 찾을 수 없습니다',
          'Mobile*':'이동하는*',
          'Modified By':'수정자',
          'Modified Date':'수정 날짜',
          'MYR':'MYR',
          'Name':'이름',
          'New Balance':'새로운 균형',
          'New Password':'새 비밀번호',
          'New Wallet Balance':'새 지갑 잔액',
          'No':'아니요',
          'Not Found':'찾을 수 없음',
          'Not yet registered?':'아직 등록되지 않았습니까?',
          'Note':'메모',
          'Note must not be empty':'메모는 비워둘 수 없습니다.',
          'Note*':'메모*',
          'Note: Each tier total should not be greater than total allowable commission rate (2%) as of December 4, 2020':'참고: 각 등급 합계는 2020년 12월 4일 현재 총 허용 수수료율(2%)보다 크지 않아야 합니다.',
          'Notes':'노트',
          'NTD':'NTD',
          'Old Password':'기존 비밀번호',
          'Open this link':'이 링크 열기',
          'Operator':'운영자',
          'Operator Add':'연산자 추가',
          'Operator addition success!':'연산자 추가 성공!',
          'Operator Code':'운영자 코드',
          'Operator Information':'운영자 정보',
          'Operator List':'운영자 목록',
          'Operator modification success!':'오퍼레이터 수정 성공!',
          'Operator Name':'운영자 이름',
          'Operator View':'운영자 보기',
          'Operators':'연산자',
          'out of':'밖으로',
          'Page not found':'페이지를 찾을 수 없음',
          'Parent Agent Code':'상위 에이전트 코드',
          'Password':'비밀번호',
          'Password*':'비밀번호*',
          'Payee Code':'수취인 코드',
          'Payee Type':'수취인 유형',
          'Payment Channel Account Name':'결제 채널 계정 이름',
          'Payment Channel Account Number':'결제 채널 계정 번호',
          'Payment Channel Add':'결제 채널 추가',
          'Payment Channel Edit':'결제 채널 수정',
          'Payment Channel edit success!':'결제 채널 수정 성공!',
          'Payment Channel Information':'결제 채널 정보',
          'Payment Channel List':'결제 채널 목록',
          'Payment Channel View':'결제 채널 보기',
          'Payment Channels':'결제 채널',
          'Payor Code':'지불인 코드',
          'Payor Type':'지급인 유형',
          'PENDING':'보류 중',
          'Pending Agent':'대기 중인 에이전트',
          'Pending Deposits':'보류 중인 예금',
          'Pending Players':'보류 중인 플레이어',
          'Pending Withdraws':'보류 중인 인출',
          'Period':'기간',
          'Plasada Amount':'플라사다 양',
          'Player Bets':'플레이어 베팅',
          'Player Credit Memo':'선수 크레딧 메모',
          'Player Debit Memo':'플레이어 차변 메모',
          'Player Deposit':'선수 보증금',
          'Player Deposit Information':'선수 예치금 정보',
          'Player Deposit List':'선수 예치금 목록',
          'Player Deposit View':'선수 예치금 보기',
          'Player Information':'선수 정보',
          'Player List':'플레이어 목록',
          'Player No.':'선수번호',
          'Player Number':'선수 번호',
          'Player Username':'플레이어 사용자 이름',
          'Player View':'플레이어 보기',
          'Player Withdraw':'선수 기권',
          'Player Withdrawal Information':'선수 탈퇴 정보',
          'Player Withdrawal List':'선수 탈퇴 목록',
          'Player Withdrawal View':'선수 철수 보기',
          'PLAYER_CM':'PLAYER_CM',
          'PLAYER_DEPOSIT':'PLAYER_DEPOSIT',
          'PLAYER_DM':'PLAYER_DM',
          'PLAYER_WITHDRAW':'PLAYER_WITHDRAW',
          'Players':'선수',
          'Players Deposit':'선수 보증금',
          'Players List':'선수 목록',
          'Players Withdrawals':'선수 철수',
          'Please wait…':'기다리세요…',
          'Prev. Balance':'이전 균형',
          'Previous Balance':'이전 잔고',
          'Profile':'프로필',
          'records':'기록',
          'Reference Transaction Number':'참조 거래 번호',
          'Register':'등록하다',
          'Register Now':'지금 등록하세요',
          'Registered successfully! We will contact you for your account activation.':'가입 성공! 계정 활성화를 위해 연락드리겠습니다.',
          'Registration':'등록',
          'Remarks':'비고',
          'Request Fund Transfer':'자금 이체 요청',
          'Requested Amount Must Be Greater Than Commission Balance':'요청 금액은 커미션 잔액보다 커야 합니다.',
          'Requested amount must be greater than minimum transfer amount':'요청 금액은 최소 이체 금액보다 커야 합니다.',
          'Reset':'초기화',
          'Reset Password':'암호를 재설정',
          'Reset Password success!':'비밀번호 재설정 성공!',
          'Role':'역할',
          's password?':'비밀번호?',
          'Sabong Network Login':'사봉네트워크 로그인',
          'Save':'구하다',
          'Secret Key':'비밀 키',
          'Select Country Code':'국가 코드 선택',
          'Setup':'설정',
          'Share Code Link has been copied to your clipboard!':'공유 코드 링크가 클립보드에 복사되었습니다!',
          'Shareable Link':'공유 가능한 링크',
          'Shop Name is already taken':'상점 이름은 이미 사용 중입니다.',
          'Showing':'전시',
          'Sign Up':'가입하기',
          'Skype':'스카이프',
          'Something went wrong, please contact the administrator at':'문제가 발생했습니다. 관리자에게 문의하세요.',
          'Start Date':'시작일',
          'Status':'상태',
          'Sub Agent':'서브 에이전트',
          'Sub Agent Comm. Rate':'보조 에이전트 통신 비율',
          'SUB_AGENT':'SUB_AGENT',
          'Submit':'제출하다',
          'Super Admin':'최고 관리자',
          'Super Agent':'슈퍼 에이전트',
          'Super Agent Comm. Rate':'슈퍼 에이전트 통신 비율',
          'SUPER MASTER AGENT':'슈퍼마스터 에이전트',
          'SUPER_ADMIN':'SUPER_ADMIN',
          'SUPER_AGENT':'SUPER_AGENT',
          'System Information':'시스템 정보',
          'Telegram':'전보',
          'The :attribute is not valid format and you can only use numeric and (+) plus sign':':attribute는 유효한 형식이 아니며 숫자와 (+) 더하기 기호만 사용할 수 있습니다.',
          'The Commission Rate must be less than the rate assigned to you.':'커미션 요율은 귀하에게 할당된 요율보다 낮아야 합니다.',
          'this Agent?':'이 에이전트?',
          'this Commission? This action cannot be undone.':'이 위원회? 이 작업은 취소할 수 없습니다.',
          'this deposit? This action cannot be undone.':'이 보증금? 이 작업은 취소할 수 없습니다.',
          'this Player? This action cannot be undone.':'이 플레이어? 이 작업은 취소할 수 없습니다.',
          'this withdrawal? This action cannot be undone.':'이번 철수? 이 작업은 취소할 수 없습니다.',
          'Tier 1 (Single Layer commissioning)':'계층 1(단일 계층 시운전)',
          'Tier 2 (Double Layer commissioning)':'계층 2(이중 레이어 시운전)',
          'Tier 3 (Tri Layer commissioning)':'Tier 3(트라이 레이어 시운전)',
          'to':'에게',
          'toggle password visibility':'비밀번호 가시성 전환',
          'Total commission from player will be divided to Super Agent and Agent. Sum should equate to allowable commission of sabong.':'플레이어의 총 커미션은 슈퍼 에이전트와 에이전트로 나뉩니다. 합계는 사봉의 허용 수수료와 동일해야 합니다.',
          'Total commission from player will be divided to Super Agent, Agent and Sub Agent. Sum should equate to allowable commission of sabong.':'플레이어의 총 커미션은 슈퍼 에이전트, 에이전트 및 서브 에이전트로 나뉩니다. 합계는 사봉의 허용 수수료와 동일해야 합니다.',
          'Total Commission from player will directly go to Super Agent alone.':'플레이어의 총 커미션은 슈퍼 에이전트 단독으로 직접 이동합니다.',
          'Total Sum':'총 합계',
          'Transaction Amount':'거래 금액',
          'Transaction Date':'거래 날짜',
          'Transaction No.':'거래 번호',
          'Transaction Number':'거래 번호',
          'Transaction Type':'거래 유형',
          'Transfer From Commission':'커미션에서 전송',
          'Transfer To Wallet':'지갑으로 이체',
          'TRANSFER_FROM_COMMISSION':'TRANSFER_FROM_COMMISSION',
          'TRANSFER_TO_WALLET':'TRANSFER_TO_WALLET',
          'Trx No.':'전송 번호',
          'Trx Type.':'전송 유형.',
          'Txn Date':'송신 날짜',
          'Txn No.':'발신번호',
          'Txn Type':'전송 유형',
          'Type':'유형',
          'Unable to process your request':'귀하의 요청을 처리할 수 없습니다',
          'Unlock':'터놓다',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'에이전트가 비밀번호를 잊어버렸을 때 이 기능을 사용하십시오. 생성된 비밀번호를 저장해 주세요.',
          'User':'사용자',
          'User Add':'사용자 추가',
          'User Agent':'사용자 에이전트',
          'User Change Password':'사용자 비밀번호 변경',
          'User Information':'사용자 정보',
          'User List':'사용자 목록',
          'User Name':'사용자 이름',
          'User Name*':'사용자 이름*',
          'User View':'사용자 보기',
          'Username':'사용자 이름',
          'Username already taken':'이미 사용중인 이름입니다',
          'Username already used':'이미 사용 중인 사용자 이름',
          'Username cannot be empty':'사용자 이름은 비워둘 수 없습니다.',
          'Username is already taken':'이미 사용중인 이름입니다',
          'Username not found':'사용자 이름을 찾을 수 없습니다',
          'Users':'사용자',
          'Verified CS':'검증된 CS',
          'VerifiedCS':'VerifiedCS',
          'Verify':'검증',
          'Vietnamese':'베트남어',
          'VND':'VND',
          'Wallet Ledger':'지갑 원장',
          'Wallet Ledger View':'지갑 원장 보기',
          'Wallet Point Ledger Information':'월렛 포인트 원장 정보',
          'Wallet Points':'지갑 포인트',
          'Welcome Back':'다시 오신 것을 환영합니다',
          'Whatsapp':'왓츠앱',
          'Withdraw':'철회하다',
          'Withdrawals':'출금',
          'Yes':'예',
          'You have insuficient fund, please request for top up first.':'자금이 부족합니다. 먼저 충전을 요청하십시오.',
          'your commission? Once you submit this transaction it cannot be undone.':'당신의 커미션? 이 거래를 제출하면 취소할 수 없습니다.',
        },
      },
      vi: { //viatnemese
        translations: {
          'Add New Agent':'Thêm đại lý mới',
          'Commission List':'Danh sách hoa hồng',
          'Enter your credentials to access your account.':'Nhập thông tin đăng nhập của bạn để truy cập tài khoản của bạn.',
          'username':'tên tài khoản',
          'password':'mật khẩu mở khóa',
          'Login':'Đăng nhập',
          'Not yet registered?':'Vẫn chưa được đăng ký?',
          'Create an Account':'Tạo một tài khoản',
          'Earn 3% of commission in every bets!':'Kiếm 3% hoa hồng trong mỗi lần đặt cược!',
          'REGISTER NOW':'ĐĂNG KÝ NGAY',
          'BECOME A':'TRỞ THÀNH MỘT',
          'SUPER MASTER AGENT':'ĐẠI LÝ SIÊU CHÍNH',
          // 'English':'tiếng Anh',
          // 'Indonesian':'Người Indonesia',
          // 'Korean':'Hàn Quốc',
          // 'Malaysian':'người Malaysia',
          // 'Vietnamese':'Tiếng Việt',
          'The username field is required.':'Trường tên người dùng là bắt buộc.',
          'The password field is required.':'Trường mật khẩu là bắt buộc.',
          'Sorry unable to serve your request. Please contact administrator.':'Xin lỗi không thể phục vụ yêu cầu của bạn. Vui lòng liên hệ với quản trị viên.',
          'User Name*':'Tên tài khoản*',
          'Username*':'Tên tài khoản*',
          'Password*':'Mật khẩu*',
          'First Name*':'Tên đầu tiên*',
          'Firstname*':'Tên đầu tiên*',
          'Last Name*':'Họ*',
          'Lastname*':'Họ*',
          'Currency*':'Tiền tệ*',
          'Email*':'E-mail*',
          'Mobile*':'Di động *',
          'Telegram':'Telegram',
          'Skype':'Ứng dụng trò chuyện',
          'Whatsapp':'Whatsapp',
          'Register':'Đăng ký',
          'Sabong Network recreational hobby':'Sabong Network sở thích giải trí',
          'Sign Up':'Đăng ký',
          'Create your account and become a master agent now.':'Tạo tài khoản của bạn và trở thành một đại lý chính ngay bây giờ.',
          // 'English':'tiếng Anh',
          'Already have an account?':'Bạn co săn san để tạo một tai khoản?',
          'Registered successfully! We will contact you for your account activation.':'Đã đăng ký thành công! Chúng tôi sẽ liên hệ với bạn để kích hoạt tài khoản của bạn.',
          'Welcome':'Chào mừng',
          'Wallet Points':'Điểm trên Wallet',
          'Commission':'hội đồng',
          'Pending Deposits':'Tiền gửi đang chờ xử lý',
          'Pending Withdrawals':'Rút tiền đang chờ xử lý',
          'Active Players':'Người chơi đang hoạt động',
          'Pending Players':'Người chơi đang chờ xử lý',
          'Blocked Players':'Người chơi bị chặn',
          'Active Agent':'Tác nhân đang hoạt động',
          'Pending Agent':'Đại lý đang chờ xử lý',
          'Blocked Agent':'Tác nhân bị chặn',
          'Profile':'Hồ sơ',
          'Change Password':'Đổi mật khẩu',
          'Logout':'Đăng xuất',
          'Dashboard':'bảng điều khiển',
          'Players':'Người chơi',
          'Manage':'Quản lý',
          'Deposits':'Tiền gửi',
          'Withdrawals':'Rút tiền',
          'Agent':'Đại lý',
          'Tree View':'Chế độ xem cây',
          'Cap View':'Cap View',
          'Funds Request':'Yêu cầu quỹ',
          'Funds for Approval':'Kinh phí phê duyệt',
          'Ledgers':'Sổ cái',
          'Commission Points':'Điểm hoa hồng',
          'Payment Channels':'Các kênh thanh toán',
          'Commission List':'Danh sách hoa hồng',
          'Filter':'Lọc',
          'Clear':'Sạch',
          'Status':'Trạng thái',
          'Period':'Khoảng thời gian',
          'Agent Code':'Mã đại lý',
          'Amount':'Số lượng',
          'Approved Amount':'Số tiền được chấp thuận',
          'Start Date':'Ngày bắt đầu',
          'End Date':'Ngày cuối',
          'Showing':'Hiển thị',
          'Limit':'Giới hạn',
          'export':'xuất khẩu',
          'Pending':'Chưa giải quyết',
          'Approved':'Tán thành',
          'Adjusted':'Điều chỉnh',
          'Game URL':'URL trò chơi',
          'Open this link':'Mở liên kết này',
          'Player Username':'Tên người dùng của người chơi',
          'Player Number':'Số người chơi',
          'Player No.':'Số người chơi',
          'Created Date':'ngày tạo ra',
          'Action':'Hoạt động',
          'Players List':'Danh sách người chơi',
          'Players List':'Danh sách người chơi',
          'Players Deposit':'Gửi tiền cho người chơi',
          'Player Deposits':'Tiền gửi của người chơi',
          'Player Deposit':'Tiền gửi của người chơi',
          'Disapproved':'Bị từ chối',
          'Transaction No.':'Giao dịch không.',
          'Players Withdrawals':'Người chơi Rút tiền',
          'Player Withdrawals':'Rút tiền của người chơi',
          'Active':'Tích cực',
          'Inactive':'Không hoạt động',
          'Hold':'Tổ chức',
          'Blocked':'Bị chặn',
          'Credit Limit':'Giới hạn tín dụng',
          'Commission Rate':'Tỷ lệ hoa hồng',
          'Agent Type':'Loại đại lý',
          'Parent Agent Code':'Mã đại lý chính',
          'Date Registered':'ngày đăng ký',
          'Add New Agent':'Thêm đại lý mới',
          'Super Agent':'Siêu đại lý',
          'Request Fund Transfer':'Yêu cầu chuyển tiền',
          'Fund Request List':'Danh sách Yêu cầu Quỹ',
          'Payee Code':'Mã người nhận thanh toán',
          'Payor Code':'Mã người thanh toán',
          'Type':'Kiểu',
          'Deposit':'Gửi tiền',
          'Withdraw':'Rút',
          'Payor Type':'Loại người thanh toán',
          'Fund Approval List':'Danh sách phê duyệt quỹ',
          'Wallet Ledger':'Sổ cái Ví',
          'Memo Type':'Loại bản ghi nhớ',
          'Debit':'Ghi nợ',
          'Credit':'Tín dụng',
          'New Balance':'Cân bằng mới',
          'Prev. Balance':'Trước đó. Thăng bằng',
          'Player Withdraw':'Người chơi rút tiền',
          'Player Withdrawal':'Rút tiền của người chơi',
          'Player Credit Memo':'Thư báo ghi có của người chơi',
          'Player Debit Memo':'Thư báo ghi nợ của người chơi',
          'Agent Deposit':'Tiền gửi đại lý',
          'Agent Withdraw':'Đại lý Rút tiền',
          'Agent Withdrawal':'Rút tiền đại lý',
          'Agent Credit Memo':'Thư báo ghi có đại lý',
          'Agent Debit Memo':'Thư báo ghi nợ đại lý',
          'Admin Credit Memo':'Thư báo ghi có của quản trị viên',
          'Admin Debit Memo':'Thư báo ghi nợ của quản trị viên',
          'Commission Income':'Thu nhập hoa hồng',
          'Commission Approval':'Phê duyệt hoa hồng',
          'Credit Memo':'Bản ghi nhớ tín dụng',
          'Debit Memo':'Giấy xác nhận nợ',
          'Commission Ledger List':'Danh sách sổ cái hoa hồng',
          'Address 1':'địa chỉ 1',
          'Address 2':'Địa chỉ 2',
          'Credit Limit':'Giới hạn tín dụng',
          'Commission Rate':'Tỷ lệ hoa hồng',
          'Cancel':'Hủy bỏ',
          'Save':'Cứu',
          'Remarks':'Nhận xét',
          'Add Payment Channel':'Thêm kênh thanh toán',
          'Bank Name or Payment Channel Name (e.g HSBC WeChat Pay etc)':'Tên ngân hàng hoặc tên kênh thanh toán (ví dụ HSBC WeChat Pay, v.v.)',
          'Account Name':'Tên tài khoản',
          'Account Number':'Số tài khoản',
          'Channel Name':'Tên kênh',
          'Date Created':'Ngày tạo',
          'Welcome Back':'Chào mừng trở lại',
          'System Information' : 'Điểm trên Wallet',      
          'Register Now':'Đăng ký ngay',  
          'Pending Withdraws': 'Rút tiền đang chờ xử lý',    
          'Please wait...': 'Vui lòng chờ...',
          'Approved': 'ĐÃ ĐƯỢC PHÊ DUYỆT',
          'APPROVED': 'ĐÃ ĐƯỢC PHÊ DUYỆT',
          'Account Information':'thông tin tài khoản',
          'Middle Name':'Tên đệm',
          'Email':'E-mail',
          'Currency':'Tiền tệ',
          'Account Expired':'Tài khoản đã hết hạn',
          'Credential Expired':'Thông tin xác thực đã hết hạn',
          'Enabled':'Đã bật',
          'Account Locked':'Tài khoản bị khóa',
          'First Name':'Tên đầu tiên',
          'Last Name':'Họ',
          'Mobile':'Di động',
          'Created By':'Được tạo bởi',
          'Modified By':'Được sửa đổi bởi',
          'Modified Date':'Ngày sửa đổi',
          'Lock':'Khoa',
          'Agent':'Đại lý',
          'Code':'Mã số',
          'Mobile':'Di động',
          'First Name':'Tên đầu tiên',
          'Last Name':'Họ',
          'Deactivate':'Hủy kích hoạt',
          'Activate':'Hoạt động',
          'Inactive':'Không hoạt động',
          'Active':'Tích cực',
          'Commission Ledger':'Sổ cái hoa hồng',
          'Commission':'hội đồng',
          'Commissions':'hội đồng',
          'Downlines':'Tuyến dưới',
          'Back to List':'Trở lại danh sách',
          'Reset Password':'Đặt lại mật khẩu',
          'Edit':'Chỉnh sửa',
          'Username':'tên tài khoản',
          'Reset Password':'Đặt lại mật khẩu',
          'Cancel':'Hủy bỏ',
          'Submit':'Nộp',
          'Payee Type':'Loại người nhận thanh toán',
          'Trx Type.':'Trx Loại.',
          'Trx No.':'Trx Không.',
          'Created':'Tạo',
          'Verified CS':'CS đã xác minh',
          'Locked':'Đã khóa',
          'Verifiedcs':'Đã xác minh tài liệu',
          'New Password':'mật khẩu mới',
          'out of':'ra khỏi',
          'to':'đến',
          'Change Paswword':'Thay đổi mật khẩu',
          'Logout':'Đăng xuất',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'Sử dụng tiện ích này khi đại lý của bạn quên mật khẩu của họ. Vui lòng lưu mật khẩu đã tạo.',
          'Delete Record':'Xóa hồ sơ',
          'Are you sure you want to delete this record?':'Bạn có chắc chắn muốn xóa bản ghi này không?',
          'Yes':'đúng',
          'No':'Không',
          'Select Country Code':'Chọn mã quốc gia',
          'this Agent?':'Đại lý này?',
          'Confirm':'Xác nhận',
          'Are you sure you want to':'Bạn có chắc là bạn muốn',
          'Approve':'Chấp thuận',
          'this Commission? This action cannot be undone.':'Ủy ban này? Hành động này không thể được hoàn tác.',
          'this Player? This action cannot be undone.':'Người chơi này? Hành động này không thể được hoàn tác.',
          'Verify':'Xác nhận',
          'this deposit? This action cannot be undone.':'tiền đặt cọc này? Hành động này không thể được hoàn tác.',
          'this withdrawal? This action cannot be undone.':'rút tiền này? Hành động này không thể được hoàn tác.',
          'Network':'Mạng',
          // 'English':'English',
          // 'Korean':'한국인',
          // 'Indonesian':'bahasa Indonesia',
          // 'Malaysian':'Malaysia',
          // 'Vietnamese':'Tiếng Việt',
          'Fill in the form below':'Điền vào mẫu dưới đây',
          'Convert': 'Đổi',
          'your commission? Once you submit this transaction it cannot be undone.' : 'hoa hồng của bạn? Sau khi bạn gửi giao dịch này, nó không thể được hoàn tác.',
          'Convert Commission' : 'Hoa hồng chuyển đổi',
          'Agent Information':'Thông tin đại lý',
          'Player Deposit Information':'Thông tin tiền gửi của người chơi',
          'Fund Transaction Information':'Thông tin Giao dịch Quỹ',
          'Transaction Number':'Số giao dịch',
          'Transaction Date':'Ngày Giao dịch',
          'Transaction Type':'Loại giao dịch',
          'Transaction Amount':'Số tiền giao dịch',
          'Reference Transaction Number':'Số Giao dịch Tham chiếu',
          'Name':'Tên',
          'Number':'Con số',
          'Agent':'Đại lý',
          'Currency':'Tiền tệ',
          'New Wallet Balance':'Số dư mới trên Wallet',
          'Previous balance':'Cân đối trước',
          'Memo Type':'Loại bản ghi nhớ',
          'Remarks':'Nhận xét',
          'Account Name':'Tên tài khoản',
          'Channel Name':'Tên kênh',
          'Account Number':'Số tài khoản',
          'Date Created':'Ngày tạo',
          'Add New Payment Channel':'Thêm Kênh Thanh toán Mới',
          'Commission Ledger List':'Danh sách Sổ cái Hoa hồng',
          'Credit':'Tín dụng',
          'Previous Balance':'Số dư Trước đó',
          'Delete':'Xóa',
          'Bank Name or Payment Channel Name':'Tên ngân hàng hoặc tên kênh thanh toán',
          'Memo':'Bản ghi nhớ',
          'Txn No.':'Số Txn',
          'Txn Type':'Loại Txn',
          'Txn Date':'Ngày Txn',
          'Credit':'Tín dụng',
          'CREDIT':'TÍN DỤNG',
          'DEBIT':'NỢ',
          'Admin':'Quản trị viên',
          'PENDING':'PENDING',
          'DEPOSIT':'TIỀN GỬI',
          'ADMIN':'QUẢN TRỊ',
          'Note':'Lưu ý',
          'Fund type':'Loại quỹ',
          'Fund Transaction':'Giao dịch Quỹ',
          'ACTIVE':'HOẠT ĐỘNG',
          'INACTIVE':'KHÔNG TÁC ĐỘNG',
          'Minimum Commission Conversion':'Chuyển đổi Hoa hồng Tối thiểu',
          'Payment Channel Account Name':'Tên tài khoản kênh thanh toán',
          'Player Information':'Thông tin người chơi',
          'Edit Agent':'Tác nhân chỉnh sửa',
          'Shareable Link':'Liên kết có thể chia sẻ',
          'Super Agen':'Siêu lão hóa',
          'Fund Type':'Loại quỹ',
          'DEBIT':'NỢ',
          'Wallet Point Ledger Information':'Thông tin Sổ cái Điểm Wallet',
          'Commission Point Ledger Information':'Thông tin Sổ cái Điểm hoa hồng',
          'Account Expired':'Tài khoản đã hết hạn',
          'Account Information':'thông tin tài khoản',
          'Account is Inactive, please contact the administrator at':'Tài khoản không hoạt động, vui lòng liên hệ với quản trị viên tại',
          'Account is locked due to multiple invalid attempts, please contact the administrator at':'Tài khoản bị khóa do nhiều lần thử không hợp lệ, vui lòng liên hệ với quản trị viên tại',
          'Account is locked, please contact the administrator at':'Tài khoản bị khóa, vui lòng liên hệ với quản trị viên tại',
          'Account Locked':'Tài khoản bị khóa',
          'Account Name':'Tên tài khoản',
          'Account Non Expired':'Tài khoản chưa hết hạn',
          'Account Non Locked':'Tài khoản không bị khóa',
          'Account Number':'Số tài khoản',
          'Action':'Hoạt động',
          'Activate':'Hoạt động',
          'ACTIVE':'TÍCH CỰC',
          'Active Agent':'Tác nhân đang hoạt động',
          'Active Players':'Người chơi đang hoạt động',
          'Add Agent':'Thêm đại lý',
          'Add New Agent':'Thêm đại lý mới',
          'Add New Operator':'Thêm nhà điều hành mới',
          'Add New Payment Channel':'Thêm kênh thanh toán mới',
          'Add New User':'Thêm người dùng mới',
          'Add Payment Channel':'Thêm kênh thanh toán',
          'Address':'Địa chỉ nhà',
          'Address 1':'địa chỉ 1',
          'Address 2':'Địa chỉ 2',
          'Adjusted':'Điều chỉnh',
          'Admin':'quản trị viên',
          'Admin Credit Memo':'Thư báo ghi có của quản trị viên',
          'Admin Debit Memo':'Thư báo ghi nợ của quản trị viên',
          'ADMIN_CM':'ADMIN_CM',
          'ADMIN_DM':'ADMIN_DM',
          'Agent':'Đại lý',
          'Agent Add':'Đại lý Thêm',
          'Agent addition success!':'Bổ sung đại lý thành công!',
          'Agent Code':'Mã đại lý',
          'Agent Code already taken':'Mã đại lý đã được sử dụng',
          'Agent Comm. Rate':'Đặc vụ Comm. Tỷ lệ',
          'Agent Credit Memo':'Thư báo ghi có đại lý',
          'Agent Debit Memo':'Thư báo ghi nợ đại lý',
          'Agent Deposit':'Tiền gửi đại lý',
          'Agent edit success!':'Đại lý chỉnh sửa thành công!',
          'Agent Information':'Thông tin đại lý',
          'Agent List':'Danh sách đại lý',
          'Agent Name':'Tên đại lý',
          'Agent Type':'Loại đại lý',
          'Agent View':'Chế độ xem đại lý',
          'Agent Withdraw':'Đại lý Rút tiền',
          'AGENT_CM':'AGENT_CM',
          'AGENT_DEPOSIT':'AGENT_DEPOSIT',
          'AGENT_DM':'AGENT_DM',
          'AGENT_WITHDRAW':'AGENT_WITHDRAW',
          'Agents Add':'Đại lý Thêm',
          'Agents Edit':'Đại lý Sửa đổi',
          'Agents List':'Danh sách đại lý',
          'Agents Tree':'Cây đại lý',
          'Agents View':'Đại lý Xem',
          'Already have an account?':'Bạn co săn san để tạo một tai khoản?',
          'Amount':'Số lượng',
          'API Key':'Mã API',
          'Application Error':'Lỗi ứng dụng',
          'Approve':'Chấp thuận',
          'Approved':'Tán thành',
          'Approved Amount':'Số tiền được chấp thuận',
          'Approved Commission Amount':'Số tiền hoa hồng được chấp thuận',
          'Are you sure you want to':'Bạn có chắc là bạn muốn',
          'Are you sure you want to <b>Approve</b> this Commission? This action cannot be undone.':'Bạn có chắc chắn muốn <b> Phê duyệt </b> Hoa hồng này không? Hành động này không thể được hoàn tác.',
          'Are you sure you want to delete this record?':'Bạn có chắc chắn muốn xóa bản ghi này không?',
          'Back to List':'Trở lại danh sách',
          'Bank Name or Payment Channel Name':'Tên ngân hàng hoặc tên kênh thanh toán',
          'Base API Url':'Url API cơ sở',
          'BECOME A':'TRỞ THÀNH MỘT',
          'Bet Amount':'Số tiền đặt cược',
          'Bet Corner':'Đặt cược phạt góc',
          'Bet Result':'Kết quả đặt cược',
          'BLOCKED':'BỊ CHẶN',
          'Blocked Agent':'Tác nhân bị chặn',
          'Blocked Players':'Người chơi bị chặn',
          'Cancel':'Hủy bỏ',
          'Cap View':'Cap View',
          'Change Password':'Đổi mật khẩu',
          'Change password success! Use your new password on your next login':'Đổi mật khẩu thành công! Sử dụng mật khẩu mới của bạn trong lần đăng nhập tiếp theo',
          'Channel Name':'Tên kênh',
          'Channel Name (eg. GCash, Paymaya, ect)':'Tên kênh (ví dụ: GCash, Paymaya, v.v.)',
          'Clear':'Sạch',
          'Client Key':'Khóa khách hàng',
          'CNY':'CNY',
          'Code':'Mã số',
          'Commission':'hội đồng',
          'Commission Approval':'Phê duyệt hoa hồng',
          'Commission Income':'Thu nhập hoa hồng',
          'Commission Information':'Thông tin hoa hồng',
          'Commission Ledger':'Sổ cái hoa hồng',
          'Commission Ledger List':'Danh sách sổ cái hoa hồng',
          'Commission Ledger View':'Chế độ xem sổ cái hoa hồng',
          'Commission List':'Danh sách hoa hồng',
          'Commission Point Ledger Information':'Thông tin sổ cái điểm hoa hồng',
          'Commission Points':'Điểm hoa hồng',
          'Commission Rate':'Tỷ lệ hoa hồng',
          'Commission Rate must not be zero':'Tỷ lệ hoa hồng không được bằng 0',
          'Commission Rate Setup':'Thiết lập tỷ lệ hoa hồng',
          'Commission Rate Setup edit success!':'Chỉnh sửa Cài đặt Tỷ lệ Hoa hồng thành công!',
          'Commission Rate View':'Xem tỷ lệ hoa hồng',
          'Commission Rates':'Tỷ lệ hoa hồng',
          'Commission View':'Chế độ xem hoa hồng',
          'COMMISSION_APPROVAL':'COMMISSION_APPROVAL',
          'COMMISSION_INCOME':'COMMISSION_INCOME',
          'Commissions':'Hoa hồng',
          'Commissions List':'Danh sách hoa hồng',
          'Commissions View':'Chế độ xem hoa hồng',
          'Confirm':'Xác nhận',
          'Convert':'Đổi',
          'Convert Commission':'Hoa hồng chuyển đổi',
          'Convert commission success!':'Chuyển đổi hoa hồng thành công!',
          'Create Agent':'Tạo đại lý',
          'Create an Account':'Tạo một tài khoản',
          'Create your account and become a master agent now.':'Tạo tài khoản của bạn và trở thành một đại lý chính ngay bây giờ.',
          'Created':'Tạo',
          'Created By':'Được tạo bởi',
          'Created Date':'ngày tạo ra',
          'Credential Expired':'Thông tin xác thực đã hết hạn',
          'Credential Non Expired':'Thông tin đăng nhập chưa hết hạn',
          'Credit':'Tín dụng',
          'Credit Limit must not be zero':'Hạn mức tín dụng không được bằng 0',
          'Credit Memo':'Bản ghi nhớ tín dụng',
          'Credit Memo List':'Danh sách thư báo ghi có',
          'CREDIT_MEMO':'BẢN GHI NHỚ TÍN DỤNG',
          'Currency':'Tiền tệ',
          'Currency*':'Tiền tệ*',
          'Dashboard':'bảng điều khiển',
          'Date':'Ngày',
          'Date Created':'Ngày tạo',
          'Date Modified':'Ngày sửa đổi',
          'Date Registered':'ngày đăng ký',
          'Deactivate':'Hủy kích hoạt',
          'Debit':'Ghi nợ',
          'Debit Memo':'Giấy xác nhận nợ',
          'Debit Memo List':'Danh sách thư báo ghi nợ',
          'DEBIT_MEMO':'GIẤY XÁC NHẬN NỢ',
          'Delete':'Xóa bỏ',
          'Deposit':'Gửi tiền',
          'Deposits':'Tiền gửi',
          'Disapprove':'Không tán thành',
          'Disapproved':'Bị từ chối',
          'Downlines':'Tuyến dưới',
          'download':'Tải xuống',
          'e.g HSBC, WeChat Pay, etc':'ví dụ: HSBC, WeChat Pay, v.v.',
          'Earn 3% of commission in every bets!':'Kiếm 3% hoa hồng trong mỗi lần đặt cược!',
          'Edit':'Chỉnh sửa',
          'Edit Agent':'Chỉnh sửa tác nhân',
          'Edit Payment Channel':'Chỉnh sửa kênh thanh toán',
          'Email':'E-mail',
          'Email already taken':'Email đã đưa',
          'Email cannot be empty':'Email không được để trống',
          'Email is invalid':'Email không hợp lệ',
          'Email not found':'Không tìm thấy email',
          'Email*':'E-mail*',
          'Enabled':'Đã bật',
          'End Date':'Ngày cuối',
          // 'English':'tiếng Anh',
          'Enter your credentials to access your account.':'Nhập thông tin đăng nhập của bạn để truy cập tài khoản của bạn.',
          'Expired':'Hết hạn',
          'export':'xuất khẩu',
          'Fill in the form below':'Điền vào mẫu dưới đây',
          'Filter':'Lọc',
          'First Name':'Tên đầu tiên',
          'First Name*':'Tên đầu tiên*',
          'Fund Approval List':'Danh sách phê duyệt quỹ',
          'Fund In':'Quỹ vào',
          'Fund List Approval':'Phê duyệt danh sách quỹ',
          'Fund List Request':'Yêu cầu danh sách quỹ',
          'Fund Request List':'Danh sách Yêu cầu Quỹ',
          'Fund Transaction':'Giao dịch quỹ',
          'Fund Transaction has been sent! please wait for the approval.':'Giao dịch Quỹ đã được gửi! vui lòng đợi sự chấp thuận.',
          'Fund Transaction Information':'Thông tin giao dịch quỹ',
          'Fund type':'Loại quỹ',
          'Fund View Approval':'Phê duyệt Chế độ xem Quỹ',
          'Fund View Request':'Yêu cầu xem quỹ',
          'Funds For Approval':'Kinh phí Phê duyệt',
          'Funds List':'Danh sách quỹ',
          'Funds Request':'Yêu cầu quỹ',
          'Funds View':'Xem quỹ',
          'Game URL':'URL trò chơi',
          'Go Back':'Quay lại',
          'HOLD':'TỔ CHỨC',
          'Home':'Nhà',
          'IDR':'IDR',
          'INACTIVE':'KHÔNG HOẠT ĐỘNG',
          'Indonesian':'Người Indonesia',
          'Insufficient Balance':'Thiếu cân bằng',
          'Insufficient Wallet Commission Balance':'Số dư hoa hồng trên Wallet không đủ',
          'Insufficient withdrawal amount':'Số tiền rút không đủ',
          'Invalid Amount':'Số tiền không hợp lệ',
          'Invalid credential':'Chứng chỉ không hợp lệ',
          'Invalid credential on fourth attempt, account will be locked on 5th try':'Thông tin đăng nhập không hợp lệ ở lần thử thứ tư, tài khoản sẽ bị khóa vào lần thử thứ 5',
          'Invalid credential on third attempt, account will be locked on 5th try':'Thông tin đăng nhập không hợp lệ ở lần thử thứ ba, tài khoản sẽ bị khóa vào lần thử thứ 5',
          'Invalid credential, please recheck your inputs':'Thông tin đăng nhập không hợp lệ, vui lòng kiểm tra lại thông tin đầu vào của bạn',
          'Invalid credentials, Your account has been locked, please contact the administrator at':'Thông tin đăng nhập không hợp lệ, Tài khoản của bạn đã bị khóa, vui lòng liên hệ với quản trị viên tại',
          'Invalid OTP':'OTP không hợp lệ',
          'Invalid OTP, please check your input or you may regenerate again':'OTP không hợp lệ, vui lòng kiểm tra thông tin đầu vào của bạn hoặc bạn có thể tạo lại',
          'Invalid Username / Password':'Tên người dùng / Mật khẩu không hợp lệ',
          'Invalid username or password, please check your input':'Tên người dùng hoặc mật khẩu không hợp lệ, vui lòng kiểm tra thông tin đầu vào của bạn',
          'Korean':'Hàn Quốc',
          'KRW':'KRW',
          'Last Name':'Họ',
          'Last Name*':'Họ*',
          'Ledgers':'Sổ cái',
          'Limit':'Giới hạn',
          'Lock':'Khoa',
          'Locked':'Đã khóa',
          'Login':'Đăng nhập',
          'Malaysian':'người Malaysia',
          'Manage':'Quản lý',
          'Match Code':'Mã đối sánh',
          'Memo':'Bản ghi nhớ',
          'Memo Type':'Loại bản ghi nhớ',
          'Middle Name':'Tên đệm',
          'Minimum Commission Conversion':'Chuyển đổi hoa hồng tối thiểu',
          'Mobile':'Di động',
          'Mobile number already taken':'Số điện thoại di động đã được sử dụng',
          'Mobile Number not found':'Số điện thoại di động không được tìm thấy',
          'Mobile*':'Di động *',
          'Modified By':'Được sửa đổi bởi',
          'Modified Date':'Ngày sửa đổi',
          'MYR':'MYR',
          'Name':'Tên',
          'New Balance':'Cân bằng mới',
          'New Password':'mật khẩu mới',
          'New Wallet Balance':'Số dư mới trên Wallet',
          'No':'Không',
          'Not Found':'Không tìm thấy',
          'Not yet registered?':'Vẫn chưa được đăng ký?',
          'Note':'Ghi chú',
          'Note must not be empty':'Ghi chú không được để trống',
          'Note*':'Ghi chú*',
          'Note: Each tier total should not be greater than total allowable commission rate (2%) as of December 4, 2020':'Lưu ý: Tổng mỗi cấp không được lớn hơn tổng tỷ lệ hoa hồng cho phép (2%) kể từ ngày 4 tháng 12 năm 2020',
          'Notes':'Ghi chú',
          'NTD':'NTD',
          'Old Password':'Mật khẩu cũ',
          'Open this link':'Mở liên kết này',
          'Operator':'Nhà điều hành',
          'Operator Add':'Nhà điều hành Thêm',
          'Operator addition success!':'Thêm toán tử thành công!',
          'Operator Code':'Mã nhà điều hành',
          'Operator Information':'Thông tin nhà điều hành',
          'Operator List':'Danh sách nhà điều hành',
          'Operator modification success!':'Sửa đổi nhà khai thác thành công!',
          'Operator Name':'Tên người vận hành',
          'Operator View':'Chế độ xem nhà điều hành',
          'Operators':'Các nhà khai thác',
          'out of':'ra khỏi',
          'Page not found':'Không tìm thấy trang',
          'Parent Agent Code':'Mã đại lý chính',
          'Password':'Mật khẩu',
          'Password*':'Mật khẩu*',
          'Payee Code':'Mã người nhận thanh toán',
          'Payee Type':'Loại người nhận thanh toán',
          'Payment Channel Account Name':'Tên tài khoản kênh thanh toán',
          'Payment Channel Account Number':'Số tài khoản kênh thanh toán',
          'Payment Channel Add':'Kênh thanh toán Thêm',
          'Payment Channel Edit':'Kênh thanh toán',
          'Payment Channel edit success!':'Chỉnh sửa Kênh Thanh toán thành công!',
          'Payment Channel Information':'Thông tin kênh thanh toán',
          'Payment Channel List':'Danh sách kênh thanh toán',
          'Payment Channel View':'Xem kênh thanh toán',
          'Payment Channels':'Các kênh thanh toán',
          'Payor Code':'Mã người thanh toán',
          'Payor Type':'Loại người thanh toán',
          'PENDING':'CHƯA GIẢI QUYẾT',
          'Pending Agent':'Đại lý đang chờ xử lý',
          'Pending Deposits':'Tiền gửi đang chờ xử lý',
          'Pending Players':'Người chơi đang chờ xử lý',
          'Pending Withdraws':'Rút tiền đang chờ xử lý',
          'Period':'Khoảng thời gian',
          'Plasada Amount':'Số tiền Plasada',
          'Player Bets':'Cược Người chơi',
          'Player Credit Memo':'Thư báo ghi có của người chơi',
          'Player Debit Memo':'Thư báo ghi nợ của người chơi',
          'Player Deposit':'Tiền gửi của người chơi',
          'Player Deposit Information':'Thông tin tiền gửi của người chơi',
          'Player Deposit List':'Danh sách tiền gửi của người chơi',
          'Player Deposit View':'Chế độ xem tiền gửi của người chơi',
          'Player Information':'Thông tin người chơi',
          'Player List':'Danh sách người chơi',
          'Player No.':'Số người chơi',
          'Player Number':'Số người chơi',
          'Player Username':'Tên người dùng của người chơi',
          'Player View':'Chế độ xem trình phát',
          'Player Withdraw':'Người chơi rút tiền',
          'Player Withdrawal Information':'Thông tin rút tiền của người chơi',
          'Player Withdrawal List':'Danh sách rút tiền của người chơi',
          'Player Withdrawal View':'Chế độ xem Rút tiền của Người chơi',
          'PLAYER_CM':'PLAYER_CM',
          'PLAYER_DEPOSIT':'PLAYER_DEPOSIT',
          'PLAYER_DM':'PLAYER_DM',
          'PLAYER_WITHDRAW':'PLAYER_WITHDRAW',
          'Players':'Người chơi',
          'Players Deposit':'Gửi tiền cho người chơi',
          'Players List':'Danh sách người chơi',
          'Players Withdrawals':'Người chơi Rút tiền',
          'Please wait…':'Vui lòng chờ…',
          'Prev. Balance':'Trước đó. Thăng bằng',
          'Previous Balance':'Cân đối trước',
          'Profile':'Hồ sơ',
          'records':'Hồ sơ',
          'Reference Transaction Number':'Số giao dịch tham chiếu',
          'Register':'Đăng ký',
          'Register Now':'Đăng ký ngay',
          'Registered successfully! We will contact you for your account activation.':'Đã đăng ký thành công! Chúng tôi sẽ liên hệ với bạn để kích hoạt tài khoản của bạn.',
          'Registration':'Đăng ký',
          'Remarks':'Nhận xét',
          'Request Fund Transfer':'Yêu cầu chuyển tiền',
          'Requested Amount Must Be Greater Than Commission Balance':'Số tiền yêu cầu phải lớn hơn số dư hoa hồng',
          'Requested amount must be greater than minimum transfer amount':'Số tiền yêu cầu phải lớn hơn số tiền chuyển tối thiểu',
          'Reset':'Cài lại',
          'Reset Password':'Đặt lại mật khẩu',
          'Reset Password success!':'Đặt lại mật khẩu thành công!',
          'Role':'Vai diễn',
          's password?':'mật khẩu của s?',
          'Sabong Network Login':'Đăng nhập mạng Sabong',
          'Save':'Cứu',
          'Secret Key':'Chìa khoá bí mật',
          'Select Country Code':'Chọn mã quốc gia',
          'Setup':'Cài đặt',
          'Share Code Link has been copied to your clipboard!':'Liên kết Mã Chia sẻ đã được sao chép vào khay nhớ tạm của bạn!',
          'Shareable Link':'Liên kết có thể chia sẻ',
          'Shop Name is already taken':'Tên cửa hàng đã được sử dụng',
          'Showing':'Hiển thị',
          'Sign Up':'Đăng ký',
          'Skype':'Ứng dụng trò chuyện',
          'Something went wrong, please contact the administrator at':'Đã xảy ra sự cố, vui lòng liên hệ với quản trị viên tại',
          'Start Date':'Ngày bắt đầu',
          'Status':'Trạng thái',
          'Sub Agent':'Đại lý phụ',
          'Sub Agent Comm. Rate':'Đại lý phụ Comm. Tỷ lệ',
          'SUB_AGENT':'SUB_AGENT',
          'Submit':'Nộp',
          'Super Admin':'Quản trị viên cấp cao',
          'Super Agent':'Siêu đại lý',
          'Super Agent Comm. Rate':'Siêu đặc vụ Comm. Tỷ lệ',
          'SUPER MASTER AGENT':'ĐẠI LÝ SIÊU CHÍNH',
          'SUPER_ADMIN':'QUẢN TRỊ VIÊN CẤP CAO',
          'SUPER_AGENT':'SUPER_AGENT',
          'System Information':'Thông tin hệ thống',
          'Telegram':'Telegram',
          'The :attribute is not valid format and you can only use numeric and (+) plus sign':'Thuộc tính: không phải là định dạng hợp lệ và bạn chỉ có thể sử dụng số và dấu cộng (+)',
          'The Commission Rate must be less than the rate assigned to you.':'Tỷ lệ Hoa hồng phải nhỏ hơn tỷ lệ được chỉ định cho bạn.',
          'this Agent?':'Đại lý này?',
          'this Commission? This action cannot be undone.':'Ủy ban này? Hành động này không thể được hoàn tác.',
          'this deposit? This action cannot be undone.':'tiền đặt cọc này? Hành động này không thể được hoàn tác.',
          'this Player? This action cannot be undone.':'Người chơi này? Hành động này không thể được hoàn tác.',
          'this withdrawal? This action cannot be undone.':'rút tiền này? Hành động này không thể được hoàn tác.',
          'Tier 1 (Single Layer commissioning)':'Bậc 1 (Chạy thử một lớp)',
          'Tier 2 (Double Layer commissioning)':'Bậc 2 (Vận hành hai lớp)',
          'Tier 3 (Tri Layer commissioning)':'Bậc 3 (Chạy thử Tri lớp)',
          'to':'đến',
          'toggle password visibility':'chuyển đổi chế độ hiển thị mật khẩu',
          'Total commission from player will be divided to Super Agent and Agent. Sum should equate to allowable commission of sabong.':'Tổng hoa hồng từ người chơi sẽ được chia cho Siêu đại lý và Đại lý. Tổng phải tương đương với hoa hồng cho phép của sabong.',
          'Total commission from player will be divided to Super Agent, Agent and Sub Agent. Sum should equate to allowable commission of sabong.':'Tổng hoa hồng từ người chơi sẽ được chia cho Đại lý siêu cấp, Đại lý phụ và Đại lý phụ. Tổng phải tương đương với hoa hồng cho phép của sabong.',
          'Total Commission from player will directly go to Super Agent alone.':'Tổng số tiền hoa hồng từ người chơi sẽ trực tiếp đến với một mình Siêu đại lý.',
          'Total Sum':'Tổng số tiền',
          'Transaction Amount':'số tiền giao dịch',
          'Transaction Date':'Ngày Giao dịch',
          'Transaction No.':'Giao dịch không.',
          'Transaction Number':'Số giao dịch',
          'Transaction Type':'Loại giao dịch',
          'Transfer From Commission':'Chuyển từ hoa hồng',
          'Transfer To Wallet':'Chuyển vào Ví',
          'TRANSFER_FROM_COMMISSION':'TRANSFER_FROM_COMMISSION',
          'TRANSFER_TO_WALLET':'TRANSFER_TO_WALLET',
          'Trx No.':'Trx Không.',
          'Trx Type.':'Trx Loại.',
          'Txn Date':'Ngày Txn',
          'Txn No.':'Txn Không.',
          'Txn Type':'Loại Txn',
          'Type':'Kiểu',
          'Unable to process your request':'Không thể xử lý yêu cầu của bạn',
          'Unlock':'Mở khóa',
          'Use this facility when your agent had forgotten his / her password. Please save the generated password.':'Sử dụng tiện ích này khi đại lý của bạn quên mật khẩu của họ. Vui lòng lưu mật khẩu đã tạo.',
          'User':'Người sử dụng',
          'User Add':'Người dùng Thêm',
          'User Agent':'Đại lý người dùng',
          'User Change Password':'Người dùng thay đổi mật khẩu',
          'User Information':'Thông tin người dùng',
          'User List':'Danh sách người dùng',
          'User Name':'Tên tài khoản',
          'User Name*':'Tên tài khoản*',
          'User View':'Người dùng xem',
          'Username':'tên tài khoản',
          'Username already taken':'Tên người dùng đã được sử dụng',
          'Username already used':'Tên người dùng đã được sử dụng',
          'Username cannot be empty':'Tên ngươi dung không được để trông',
          'Username is already taken':'Tên người dùng đã được sử dụng',
          'Username not found':'tên người sử dụng không được tìm thấy',
          'Users':'Người dùng',
          'Verified CS':'CS đã xác minh',
          'VerifiedCS':'Đã xác minhCS',
          'Verify':'Xác nhận',
          'Vietnamese':'Tiếng Việt',
          'VND':'VND',
          'Wallet Ledger':'Sổ cái Ví',
          'Wallet Ledger View':'Chế độ xem sổ cái ví',
          'Wallet Point Ledger Information':'Thông tin sổ cái Wallet Point',
          'Wallet Points':'Điểm trên Wallet',
          'Welcome Back':'Chào mừng trở lại',
          'Whatsapp':'Whatsapp',
          'Withdraw':'Rút',
          'Withdrawals':'Rút tiền',
          'Yes':'đúng',
          'You have insuficient fund, please request for top up first.':'Bạn có quỹ thâm hụt, vui lòng yêu cầu nạp tiền trước.',
          'your commission? Once you submit this transaction it cannot be undone.':'hoa hồng của bạn? Sau khi bạn gửi giao dịch này, nó không thể được hoàn tác.',
        },
      },
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;