import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ErrorBoundary from './components/ErrorBoundary';
import MomentUtils from '@date-io/moment';
import ('./scss/themes/'+process.env.REACT_APP_THEME+'style.scss');

const DevelopmentPage = React.lazy(() => import('./views/misc/DevelopmentPage'));
const Layout = React.lazy(() => import('./views/containers/Layout'));
const Login = React.lazy(() => import('./views/public/Login'));
const Register = React.lazy(() => import('./views/public/Register'));
const LoginAccess = React.lazy(() => import('./views/public/LoginAccess'));
const NotFound = React.lazy(() => import('./views/exceptions/NotFound'));
const AppError = React.lazy(() => import('./views/exceptions/AppError'));

function App() {
  return (
    <div className="app-root">
      <ErrorBoundary>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            <React.Suspense fallback={<Backdrop open={true}><CircularProgress color="inherit"/></Backdrop>}>
              <Switch>
                <Route exact path="/development-page" name="Not Found" render={props => <DevelopmentPage {...props}/>} />
                <Route exact path="/404" name="Not Found" render={props => <NotFound {...props}/>} />
                <Route exact path="/500" name="Application Error" render={props => <AppError {...props}/>}/>
                <Route exact path="/login" name="Sabong Express Login" render={props => <Login {...props}/>}/>
                <Route exact path="/register" name="Registration" render={props => <Register {...props}/>}/>
                <Route exact path="/LoginAccess" name="Login Access" render={props => <LoginAccess {...props}/>}/>
                <Route path="/" name="Home" render={props => <Layout {...props}/>} />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
