import React, { Component } from 'react';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import BrokenNinja from '../assets/image/misc/broken-app.png';
// import axios from 'axios';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.log(error, errorInfo);
  }

  render() {
    const refreshPage = () => {
      this.setState({ hasError: false });
      window.history.back();
    }

    if (this.state.hasError) {
      return (
        <Container disableGutters fixed>
          <div style={{height: 'calc(100vh)', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', padding: '0px 20px'}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <img alt="Ninja is sad" src={BrokenNinja}/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="center">
                  <b>We're sorry but even our ninja seems to be having trouble serving your request.</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div style={{width: 300, margin: '0 auto'}}>
                  <Button className="BrandButton" variant="contained" onClick={refreshPage}>
                    Go Back
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;